import React from "react"
import { Linking, Platform, StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";

import LogoFacebook from "../../../../assets/img/LogoFacebook";
import LogoInsta from "../../../../assets/img/LogoInsta";
import LogoTwitter from "../../../../assets/img/LogoTwitter";

export const HeaderNoSignIn = ({navigation}) => {
    return (
      <View style={styles.header__noSignIn}>  
          <View style={styles.log__link}> 
            <TouchableOpacity accessibilityRole="link" onPress={()=>{navigation.navigate("Login")}}>
              <Text style={styles.login__link}>Connexion</Text>
            </TouchableOpacity>

            <TouchableOpacity accessibilityRole="link" onPress={()=>{navigation.navigate("SignUp")}}>
              <Text style={styles.signup__link}>Inscription</Text>
            </TouchableOpacity>
          </View>

          <View style={styles.socialMedia}>
              <Text accessibilityRole="link" style={styles.logoSocialMedia} onPress={() => Linking.openURL("https://www.facebook.com/KidyWoo/")}><LogoFacebook width="20" height="20"/></Text>
              <Text accessibilityRole="link" style={styles.logoSocialMedia} onPress={() => Linking.openURL("https://www.instagram.com/kidywoo/")}><LogoInsta width="20" height="20" /></Text>
              <Text accessibilityRole="link" style={styles.logoSocialMedia} onPress={() => Linking.openURL("https://twitter.com/KidyWoo")}><LogoTwitter width="20" height="20" /></Text>
          </View>
      </View>
    )
}

const styles = StyleSheet.create({
  header__noSignIn:{
    width: "100%",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 5,
    paddingRight: 5,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: Platform.OS === "web" ? "fixed" : "absolute",
    top: 0,
    zIndex: 10
  },
  log__link: {
      flexDirection: "row",
      alignItems: 'center',
      flexWrap: 'wrap'
    },
  login__link: {
    backgroundColor: "#fae8c9",
    color: "#57B5B5",
    fontSize: 15,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: "#57B5B5",
    borderRadius: 15,
    overflow: "hidden", 
    paddingTop: 10 ,
    paddingBottom: 10 ,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    textAlign: "center"
  },
  signup__link:{
    backgroundColor: "#57B5B5",
    color: "#fae8c9",
    fontSize: 15,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: "#57B5B5",
    borderRadius: 15,
    overflow: "hidden",
    paddingTop: 10 ,
    paddingBottom: 10 ,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    textAlign: "center",
  },
  socialMedia: {
      flexDirection: "row"
  },
  logoSocialMedia: {
    marginLeft: 15,
  }
});