import React from "react"
import { Svg, Path, Circle } from "react-native-svg"

function GirlLogo() {
  return (
    <Svg 
      height={50} 
      width={50} 
      viewBox="0 0 511 511" 
    >
      <Path
        d="M202.361 446.901h21.698v32.828c0 17.271 14.001 31.272 31.272 31.272s31.272-14.001 31.272-31.272v-32.828h22.037c14.985 0 27.133-12.148 27.133-27.133s-12.148-27.133-27.133-27.133h-22.037v-98.818h-62.205l-.339 98.818h-21.698c-14.985 0-27.133 12.148-27.133 27.133s12.148 27.133 27.133 27.133z"
        fill="#ef7d92"
      />
      <Path
        d="M286.602 371.971v-74.042h-62.205l-.245 74.042c15.854 4.399 49.488 3.211 62.45 0z"
        fill="#eb6773"
      />
      <Path
        d="M255.5 0C160.364 0 83.241 77.123 83.241 172.259S160.364 344.518 255.5 344.518s172.259-77.123 172.259-172.259S350.636 0 255.5 0zm0 278.789c-58.835 0-106.53-47.695-106.53-106.53s47.695-106.53 106.53-106.53 106.53 47.695 106.53 106.53-47.695 106.53-106.53 106.53z"
        fill="#f6a7ae"
      />
      <Path
        d="M151.81 196.75a106.77 106.77 0 01-2.84-24.49c0-58.835 47.695-106.53 106.53-106.53 8.385 0 16.541.978 24.367 2.81 23.675-14.885 48.337-28.117 74.948-37.027C326.746 11.669 292.489 0 255.5 0 160.364 0 83.241 77.123 83.241 172.259c0 42.205 15.187 80.858 40.383 110.812.493-1.003 7.644-48.436 28.186-86.321z"
        fill="#f1919b"
      />
    </Svg>
  )
}

export default GirlLogo