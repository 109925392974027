import React from "react"
import { Svg, Path, G } from "react-native-svg"

function FlowerFour({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512.001 512.001">
      <Path
        d="M454.196 247.538l-47.547 134.717c-1.364 3.866.174 8.153 3.667 10.3 4.123 2.535 8.651 4.529 11.804 4.529 14.839 0 29.219-16.965 43.208-33.473 10.377-12.249 22.143-26.127 31.616-29.916 2.583-1.031 7.032-3.928 7.846-11.766 1.532-14.737-16.509-56.263-39.402-77.352-3.632-3.347-9.549-1.697-11.192 2.961z"
        fill="#ffafc3"
      />
      <G fill="#ffa5b9">
        <Path d="M115.623 328.322l-7.864-13.102c-15.439-25.731-44.644-39.621-74.329-35.427l-8.201 1.164a8.838 8.838 0 00-6.48 4.462c-11.012 19.876-6.127 37.323-1.816 52.716 3.259 11.641 6.079 21.695 2.216 31.935a8.946 8.946 0 00.78 7.834c3.005 4.824 42.346 32.982 83.503 36.931a8.828 8.828 0 007.903-3.492c18.256-24.343 19.938-56.929 4.288-83.021zM211.12 28.851C184.5 7.296 138.434 9.873 132.911 11.754c-2.872.983-5.127 3.424-5.824 6.376-1.961 8.303-8.118 17.711-14.068 26.808-10.093 15.43-20.527 31.383-16.633 48.688a8.83 8.83 0 009.006 6.885l22.021-.983c38.044-1.7 71.694-25.192 86.401-60.319 1.533-3.66.389-7.861-2.694-10.358zM496.693 194.789c-14.072-57.751-79.049-81.08-81.808-82.046-4.6-1.599-9.632.81-11.248 5.415-.699 1.992-17.008 49.374-7.808 95.363 9.106 45.528 41.152 89.845 42.511 91.712a8.836 8.836 0 008.934 3.441 8.837 8.837 0 006.821-6.722c2.255-10.106 16.789-42.674 27.403-66.456 16.238-36.403 16.161-36.727 15.195-40.707z" />
      </G>
      <G fill="#ff9baf">
        <Path d="M139.749 82.685a8.834 8.834 0 00-6.644-3.587c-37.788-2.069-104.106 60.213-111.544 67.313a8.809 8.809 0 00-2.25 3.519c-1.121 3.268-27.109 80.554.392 132.649 10.899 20.652 28.606 34.478 52.621 41.096a8.829 8.829 0 0010.899-6.328L141.19 90.098a8.829 8.829 0 00-1.441-7.413zM373.392 64.637c-40.191-23.178-55.729-57.829-55.871-58.156a8.842 8.842 0 00-6.64-5.204c-.88-.151-89-14.158-118.532 54.354a8.83 8.83 0 003.467 11.007l210.913 130.406a8.837 8.837 0 007.334.901 8.83 8.83 0 005.45-4.992c13.294-31.662 12.527-94.501-46.121-128.316zM399.696 397.244a8.829 8.829 0 00-8.282-8.303l-277.822-16.857c-3.562-.194-6.993 1.78-8.528 5.066-4.881 10.404-7.502 27.843-.22 45.58 9.313 22.691 31.848 40.772 66.986 53.738 17.879 6.597 30.516 12.86 41.665 18.383 13.853 6.864 25.813 12.792 41.527 15.866a69.987 69.987 0 0013.417 1.285c21.359 0 43.963-9.42 64.934-27.308 4.143-3.535 10.106-7.014 16.413-10.696 22.188-12.954 52.57-30.687 49.91-76.754z" />
      </G>
      <Path
        d="M442.697 267.967c-4.6-13.977-9.36-28.429-11.201-42.726-3.207-24.942-12.998-43.597-29.102-55.457-24.544-18.091-54.521-13.098-55.785-12.874a8.83 8.83 0 00-6.541 5.091L222.57 424.789a8.827 8.827 0 002.035 10.054c14.943 13.969 35.823 23.92 57.449 23.92 15.658 0 31.719-5.225 46.184-17.927 17.504-15.366 34.59-23.398 51.116-31.167 27.627-12.991 53.72-25.256 70.655-68.016 9.257-23.372 1.208-47.814-7.312-73.686z"
        fill="#ff91a5"
      />
      <Path
        d="M351.507 123.493c-8.83-17.659-17.659-26.489-50.585-36.979-19-6.053-41.454-9.549-64.364-23.031-43.373-25.528-77.928-8.071-94.666 4.6-28.459 21.54-46.076 60.127-39.277 86.012a8.833 8.833 0 008.269 6.584l228.684 7.002c.09.004.181.004.272.004a8.812 8.812 0 004.591-1.289c8.572-5.218 17.108-22.84 7.076-42.903z"
        fill="#ff879b"
      />
      <Path
        d="M259.171 400.469l-94.609-256.29a8.826 8.826 0 00-3.191-4.157c-13.219-9.334-32.473-7.601-52.832 4.747-36.543 22.165-73.462 65.253-66.072 119.999 3.424 25.347 14.827 38.467 25.856 51.151 7.33 8.429 18.702 25.139 24.734 38.234 22.147 48.089 58.984 80.77 96.136 85.292 2.983.366 5.937.543 8.864.543 22.669 0 43.622-10.779 59.73-30.951a8.826 8.826 0 001.384-8.568z"
        fill="#ff7d91"
      />
      <Path
        d="M347.717 157.333c-18.919-17.931-39.393-24.023-57.639-17.168a8.826 8.826 0 00-3.121 2.004l-144.85 144a8.903 8.903 0 00-1.638 2.242c-7.385 14.461-5.829 39.238 3.867 61.661 7.239 16.741 25.403 46.567 65.723 58.26 9.597 2.781 19.566 4.1 29.684 4.1 60.356 0 125.751-46.977 145.699-110.349 17.176-54.544-6.618-115.291-37.725-144.75z"
        fill="#ff7387"
      />
      <Path
        d="M256.07 121.928c-35.862-13.697-77.321-6.342-105.638 18.694-19.643 17.361-48.577 57.415-47.788 96.605.396 19.543 8.532 36.332 23.536 48.55 18.725 15.253 37.509 20.152 53.919 20.152 21.867-.004 39.523-8.705 47.201-13.202a8.775 8.775 0 002.548-1.816l82.55-84.434a8.834 8.834 0 002.458-7.213c-4.324-36.542-25.751-64.73-58.786-77.336z"
        fill="#ff6478"
      />
      <Path
        d="M313.028 194.85c-16.422-20.902-40.772-34.206-66.805-36.504-21.04-1.866-40.863 4.001-54.38 16.06-24.506 21.876-33.478 48.783-24.614 73.829 4.535 12.826 13.559 23.799 24.368 30.732-14.587 1.979-22.939 17.892-16.049 30.901 9.755 18.419 25.927 34.661 46.406 38.521a81.9 81.9 0 0015.185 1.427c26.467 0 59.437-7.984 79.049-32.068 22.98-28.22 36.716-72.157-3.16-122.898z"
        fill="#ff5064"
      />
      <Path
        d="M267.556 261.817c-5.239-.377-18.004 11.504-32.7-4.001-7.581-7.999-8.956-20.564-4.149-30.482a27.114 27.114 0 014.244-6.34c3.161-3.512.621-8.972-4.075-8.451-12.464 1.383-24.289 8.45-31.238 20.861-7.961 14.219-6.491 32.475 3.771 45.134 17.015 20.989 47.721 20.159 63.808.407a36.523 36.523 0 004.228-6.53c2.046-4.024 2.465-10.14-3.889-10.598z"
        fill="#d24150"
      />
    </Svg>
  )
}

export default FlowerFour
