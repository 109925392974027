import { StyleSheet } from "react-native";  

export default StyleSheet.create({
    container:{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1000
    },
    logo:{
        width: "100%",
        maxWidth: 768,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    text:{
        fontFamily: "Roboto",
        padding: 10,
        textAlign: 'center',
        color: "#337373",
        fontSize: 20
    }
})