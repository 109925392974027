import React from "react"
import { Svg, Path } from "react-native-svg"

function BoyLogo() {
  return (
    <Svg 
      height={50} 
      width={50} 
      viewBox="0 0 511 511" 
    >
      <Path
        d="M465.029.009h-70.218c-21.163 0-38.477 17.315-38.477 38.477 0 15.974 9.868 29.748 23.808 35.544 2.544 1.058 3.228 4.333 1.279 6.281L237.885 223.846l49.261 49.26L430.682 129.57c1.948-1.948 5.223-1.265 6.281 1.279 5.796 13.94 19.57 23.808 35.544 23.808 21.163 0 38.478-17.315 38.477-38.477V45.962C511.656 19.126 492.178.009 465.029.009z"
        fill="#89b0e0"
      />
      <Path
        d="M301.028 160.703l-63.143 63.143 49.261 49.26 62.107-62.108c-9.062-17.63-34.358-44.312-48.225-50.295z"
        fill="#6197d5"
      />
      <Path
        d="M172.157 166.679C77.077 166.679 0 243.756 0 338.835s77.077 172.157 172.157 172.157 172.157-77.077 172.157-172.157-77.078-172.156-172.157-172.156zm0 278.623c-58.8 0-106.466-47.667-106.466-106.466S113.358 232.37 172.157 232.37c58.8 0 106.466 47.667 106.466 106.466s-47.667 106.466-106.466 106.466z"
        fill="#99ddfc"
      />
      <Path
        d="M166.067 510.146c-10.713-22.493-19.376-45.941-26.316-69.873-42.953-13.71-74.061-53.938-74.061-101.438 0-45.748 28.857-84.752 69.359-99.816a509.642 509.642 0 016.242-22.279c3.69-12.108 7.948-24.071 13.413-35.503 2.329-4.871 4.904-9.746 7.926-14.293C71.983 171.889 0 246.953 0 338.835c0 93.162 74.002 169.028 166.428 172.052-.118-.248-.242-.493-.361-.741z"
        fill="#66cdf7"
      />
    </Svg>
  )
}

export default BoyLogo