import React from "react"
import { Svg, Path } from "react-native-svg"

function FlowerFourteen({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 511.999 511.999">
      <Path
        d="M479.429 287.091c-6.374 7.305-14.514 11.314-37.43 11.314-35.778 0-111.964-9.773-158.745-26.604 37.906 32.271 84.426 93.644 102.315 124.76 11.458 19.931 12.071 29.015 8.958 38.211-7.536 22.259-30.661 35.667-53.624 31.092-9.486-1.89-17.014-6.966-28.472-26.896-17.889-31.117-47.554-102.262-56.43-151.364-8.876 49.103-38.541 120.248-56.43 151.364-11.458 19.931-18.986 25.006-28.472 26.896-22.963 4.576-46.089-8.833-53.624-31.092-3.113-9.196-2.5-18.28 8.958-38.211 17.889-31.117 64.409-92.489 102.315-124.76-46.782 16.832-122.967 26.604-158.745 26.604-22.916 0-31.057-4.009-37.43-11.314-15.428-17.684-15.428-44.5 0-62.184 6.373-7.305 14.514-11.314 37.43-11.314 35.778 0 111.963 9.773 158.745 26.604-37.906-32.271-84.426-93.643-102.315-124.76-11.458-19.931-12.071-29.015-8.958-38.211 7.536-22.259 30.661-35.667 53.624-31.092 9.487 1.89 17.014 6.966 28.472 26.896 17.889 31.117 47.554 102.262 56.43 151.364 8.876-49.103 38.541-120.248 56.43-151.364 11.458-19.931 18.986-25.006 28.472-26.896 22.963-4.576 46.089 8.833 53.624 31.092 3.113 9.196 2.5 18.28-8.958 38.211-17.889 31.117-64.409 92.489-102.315 124.76 46.782-16.832 122.967-26.604 158.745-26.604 22.916 0 31.057 4.009 37.43 11.314 15.428 17.684 15.428 44.5 0 62.184z"
        fill="#6bf1ff"
      />
      <Path
        d="M67.754 224.908c-15.428 17.684-15.428 44.5 0 62.184 4.755 5.45 10.496 9.064 22.619 10.549-7.659.506-14.552.765-20.373.765-22.916 0-31.057-4.009-37.43-11.314-15.428-17.684-15.428-44.5 0-62.184 6.373-7.305 14.514-11.314 37.43-11.314 5.82 0 12.714.26 20.373.765-12.122 1.484-17.864 5.098-22.619 10.549zm190.499 31.103c.669-6.556.286-14.475-.931-23.339-37.268-32.92-81.651-91.679-99.026-121.902-11.458-19.931-12.071-29.015-8.958-38.211 3.932-11.616 12.115-20.813 22.3-26.311-.18-.039-.359-.078-.54-.114-22.963-4.576-46.089 8.833-53.624 31.092-3.113 9.196-2.5 18.28 8.958 38.211 21.605 37.581 84.968 119.285 124.485 140.562-39.516 21.277-102.88 102.981-124.485 140.562-11.458 19.931-12.071 29.015-8.958 38.211 7.536 22.259 30.661 35.667 53.624 31.092 3.172-.632 6.125-1.625 9.079-3.405-13.765-4.473-25.271-15.203-30.175-29.687-3.113-9.196-2.5-18.28 8.958-38.211 17.393-30.254 61.85-89.103 99.141-122.003.565-6.146.641-11.714.152-16.547z"
        fill="#55e7f0"
      />
      <Path
        d="M482.685 348.49c4.942 25.015-9.54 50.207-33.584 58.416-9.933 3.391-19.746 2.724-41.274-9.759-33.611-19.488-99.902-70.165-134.76-111.458 18.181 50.962 28.737 133.955 28.737 172.931 0 24.964-4.33 33.832-12.221 40.775-19.101 16.806-48.066 16.806-67.167 0-7.891-6.943-12.221-15.811-12.221-40.775 0-38.976 10.556-121.968 28.737-172.931-34.858 41.293-101.149 91.97-134.76 111.458-21.528 12.482-31.341 13.15-41.273 9.759-24.043-8.209-38.526-33.401-33.584-58.416 2.042-10.334 7.524-18.534 29.052-31.017C91.978 297.985 168.825 265.669 221.863 256c-53.038-9.669-129.885-41.985-163.496-61.473-21.528-12.482-27.01-20.682-29.052-31.017-4.942-25.015 9.54-50.207 33.584-58.416 9.933-3.391 19.745-2.724 41.273 9.759 33.611 19.488 99.902 70.165 134.76 111.458-18.181-50.963-28.737-133.955-28.737-172.931 0-24.964 4.33-33.832 12.221-40.775 19.101-16.806 48.066-16.806 67.167 0 7.891 6.943 12.221 15.811 12.221 40.775 0 38.976-10.556 121.969-28.737 172.931 34.858-41.293 101.149-91.97 134.76-111.458 21.528-12.482 31.341-13.15 41.274-9.759 24.043 8.209 38.526 33.401 33.584 58.416-2.042 10.334-7.524 18.534-29.052 31.017-33.611 19.488-110.458 51.804-163.496 61.473 53.038 9.669 129.885 41.985 163.496 61.473 21.528 12.481 27.01 20.681 29.052 31.017z"
        fill="#a8feff"
      />
      <Path
        d="M253.727 237.499c-15.97 2.912-34.099 7.878-52.556 13.927-50.732-12.911-113.359-39.828-142.804-56.9-21.528-12.482-27.01-20.682-29.052-31.017-4.942-25.015 9.54-50.207 33.584-58.416 2.706-.924 5.405-1.543 8.332-1.708-8.954 11.444-13.027 26.563-10.052 41.624 2.042 10.334 7.524 18.534 29.052 31.017 33.611 19.488 110.458 51.804 163.496 61.473zm.554 243.395c-7.891-6.943-12.221-15.811-12.221-40.775 0-38.976 10.556-121.968 28.737-172.931-10.483 12.419-23.818 25.684-38.243 38.697-14.247 50.587-22.358 118.57-22.358 152.734 0 24.964 4.33 33.832 12.221 40.775 19.101 16.806 48.066 16.806 67.167 0 2.163-1.903 4.056-3.954 5.668-6.438-14.361 2.083-29.458-1.93-40.971-12.062zM94.763 388.406c-24.043-8.209-38.526-33.401-33.584-58.416 1.537-7.781 5.028-14.352 15.845-22.451-7.104 3.534-13.408 6.891-18.657 9.935-21.528 12.482-27.01 20.682-29.052 31.017-4.943 25.015 9.54 50.207 33.584 58.416 9.933 3.391 19.745 2.724 41.273-9.759 5.302-3.074 11.419-6.927 18.096-11.394-12.525 5.454-19.983 5.22-27.505 2.652z"
        fill="#91f8ff"
      />
      <Path
        d="M388.371 332.75a7.46 7.46 0 01-6.474 3.751 7.408 7.408 0 01-3.727-1.006L263.468 268.99V402c0 4.143-3.343 7.5-7.468 7.5s-7.468-3.357-7.468-7.5V268.99L133.83 335.495a7.408 7.408 0 01-3.727 1.006 7.457 7.457 0 01-6.474-3.751c-2.063-3.587-.838-8.174 2.733-10.245L241.064 256l-114.701-66.505c-3.572-2.071-4.796-6.658-2.733-10.245a7.45 7.45 0 0110.202-2.745l114.701 66.505V110c0-4.143 3.343-7.5 7.468-7.5s7.468 3.357 7.468 7.5v133.01l114.701-66.505c3.57-2.07 8.139-.843 10.202 2.745 2.063 3.587.838 8.174-2.733 10.245L270.936 256l114.701 66.505c3.573 2.071 4.797 6.657 2.734 10.245z"
        fill="#6bf1ff"
      />
      <Path
        d="M329.491 258.307a201.027 201.027 0 01-56.864 54.88l-12.511 8.012a7.629 7.629 0 01-8.233 0l-12.511-8.012a201.027 201.027 0 01-56.864-54.88c-11.731-17.671-9.411-41.201 5.543-56.219 16.591-16.662 43.49-16.662 60.081 0l2.453 2.464a7.632 7.632 0 0010.827 0l2.453-2.464c16.591-16.662 43.49-16.662 60.081 0 14.956 15.018 17.276 38.548 5.545 56.219z"
        fill="#ff4040"
      />
      <Path
        d="M258.217 322.07a7.623 7.623 0 01-6.333-.87l-12.511-8.012a201.027 201.027 0 01-56.864-54.88c-11.731-17.671-9.411-41.201 5.543-56.219 13.334-13.391 33.322-16.01 49.271-7.876a47.147 47.147 0 00-23.358 12.835c-16.699 16.77-19.29 43.046-6.19 62.78a224.534 224.534 0 0050.442 52.242z"
        fill="#ed3c3c"
      />
    </Svg>
  )
}

export default FlowerFourteen
