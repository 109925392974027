import React, { useContext, useEffect, useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { AuthContext } from '../../modules/AuthProvider';
import { getLocalStorage, setLocalStorage } from '../../../tools/tools';

import BaseStyle from '../../modules/BaseStyle';
import { HeaderNoSignIn } from '../../modules/HeaderNoSignIn';
import LogoLong from '../../../../../assets/img/LogoLong';
import { H1 } from '../../modules/Title';
import Input from '../../modules/Input';
import Btn from '../../modules/Btn';

import styles from "./Login.style";
import Form from '../../modules/Form';

const Login = ({navigation}) => {
    const { login } = useContext(AuthContext)
    
    const [display,setDisplay] = useState(false)
    const [inputValue, setInputValue] = useState({login: '', password: ''});
    const [connexionTry, setConnexionTry] = useState(0);
    const [loading, setLoading] = useState(false);
    const NB_TRY = 5;

    // redirection si on est log
    useEffect(()=>{
        getLocalStorage()
            .then(user_param => {
                if (user_param) {
                    login()
                } else {
                    setDisplay(true)
                }
            })
    }, [])
    
    
    const handleInputChange = (value, name) => {
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const fetchData = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/login.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {            
            response.json()
            .then(response => {
                if (response.connexion) {
                    setLocalStorage(response)
                        .then(login())
                } else {
                    setLoading(false)
                    setConnexionTry(connexionTry + 1)
                    setInputValue({
                        login: '',
                        password: ''
                    })
                }
            })
            .catch(error => console.error(error))
        }
    }

    const onSubmit = async function() {
        if (inputValue.password !== '' && inputValue.login !== '') {
            setLoading(true);
            fetchData();
        }
        else{
            setConnexionTry(connexionTry + 1)
        }
    }

    if (connexionTry > 0) {
        var tryCount = <Text className ='danger'>Email ou mot de passe invalide. {'\r\n'}
        Attention, plus que {(NB_TRY - connexionTry)} essais</Text>
    }


    if(display) {
        if (connexionTry < NB_TRY) {
            return (
                <BaseStyle>
                    <HeaderNoSignIn navigation={navigation}/>
        
                    <KeyboardAwareScrollView>
                        <View style={styles.container}>
        
                            <LogoLong style={styles.logo}/>

                            {loading ? (
                                <View style={styles.loader}>
                                    <H1>Connexion en cours</H1>
                                    <ActivityIndicator size="large" color="#57B5B5"/>
                                </View>
                            ) : (
                                <Form>
                                    <H1>Connexion</H1>
                                    {tryCount}
                                    <Input placeholder='Email' onInputChange={(value) => {handleInputChange(value,'login')}} />
                                    <Input 
                                        placeholder='Mot de passe'
                                        onInputChange={(value) => {handleInputChange(value,'password')}}
                                        secure
                                    />
                                    <Btn text='OK' color='green' action={onSubmit}/>

                                    <Text onPress={()=>{navigation.navigate('Forget')}} style={styles.basicLink}>Mot de passe oublié</Text>
                                </Form>
                            )}
                            
                            {loading ? <></> : <Text onPress={()=>{navigation.navigate('LandingPage')}} style={styles.basicLink}>Accueil</Text>}
                        </View>
                    </KeyboardAwareScrollView>
                </BaseStyle>
            );
        } else {
            return <Text>Trop d'essais</Text>
        }
    } else {
        return (
            <BaseStyle>
                <LogoLong />
                <ActivityIndicator size="large" color="#57B5B5"/>
            </BaseStyle>
        )
    }
};

export default Login;