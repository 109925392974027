import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function Zebra({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Cat"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M393.014 152.591c118.82 0 87.668-151.555 87.668-151.555C408.696-10.887 359.525 84.03 359.525 84.03L256 142.011 152.475 84.03S103.305-10.887 31.319 1.036c0 0-31.152 151.555 87.668 151.555"
        fill="#dfdde2"
      />
      <Path
        d="M382.802 104.576s27.89-43.087 64.299-43.087c0 0 19.457 65.246-68.979 79.311M129.198 104.576s-27.89-43.087-64.299-43.087c0 0-19.457 65.246 68.979 79.311"
        fill="#b4acb7"
      />
      <Path
        d="M192.96 475.367C205.442 497.25 228.995 512 256 512c26.995 0 50.548-14.75 63.041-36.633l-63.694-33.262z"
        fill="#454045"
      />
      <Path
        d="M409.051 285.277c-1.121 14.636-4.588 28.007-8.917 40.308-10.443 29.678-25.931 53.211-25.931 73.65H137.797c0-20.44-15.488-43.972-25.931-73.65-4.329-12.301-7.796-25.671-8.917-40.308-.934-12.166-.27-25.194 2.855-39.239 5.014-22.557 7.775-46.941 10.37-69.788 1.464-12.83 2.865-25.183 4.599-36.436 2.47-16.069 5.585-29.917 10.422-39.799 4.931-10.049 11.647-15.986 21.28-15.986 27.623 0 52.152 9.446 76.775 14.439 8.855 1.796 17.719 3.021 26.751 3.021 9.031 0 17.896-1.225 26.751-3.021 24.623-4.993 49.152-14.439 76.775-14.439 9.726 0 16.474 6.052 21.415 16.277 4.734 9.799 7.806 23.439 10.246 39.239 4.837 31.328 7.152 71.169 15.01 106.494 3.123 14.044 3.787 27.072 2.853 39.238z"
        fill="#dfdde2"
      />
      <Path
        d="M391.186 139.544c-12.27.592-24.509 1.495-36.768 2.377-8.906.643-17.792 1.785-26.72 2.221-3.187.145-6.166.083-8.855-1.899a10.308 10.308 0 01-4.152-7.879c-.104-3.457 1.609-6.799 4.443-8.772 7.391-5.076 15.259-9.197 23.377-12.976 8.159-3.81 16.827-6.477 25.422-9.073 4.267-1.287 8.616-2.346 13.007-3.239 4.734 9.801 7.807 23.441 10.246 39.24z"
        fill="#665e66"
      />
      <Path
        d="M195.616 139.606c-1.931 2.927-5.211 4.609-8.699 4.619-4.08.01-8.18-.311-12.239-.685-4.557-.426-9.156-.727-13.723-1.08-10.401-.799-20.823-1.599-31.256-2.18-2.979-.166-5.948-.322-8.927-.467 2.47-16.069 5.585-29.917 10.422-39.799 2.823.571 5.626 1.214 8.419 1.91 9.913 2.491 19.391 5.948 28.816 9.81 7.225 2.958 14.201 6.934 20.709 11.211 3.093 2.024 6.322 3.945 7.599 7.619 1.049 3.001.634 6.395-1.121 9.042zM283.093 118.316c0 69.083-12.135 125.075-27.093 125.075s-27.093-55.993-27.093-125.075c0-6.758.114-13.381.343-19.848 8.855 1.796 17.719 3.021 26.751 3.021 9.031 0 17.896-1.225 26.751-3.021.227 6.467.341 13.09.341 19.848z"
        fill="#454045"
      />
      <Path
        d="M221.308 324.328l-75.519 74.906h-7.993c0-35.958-47.927-81.487-31.993-153.196 5.014-22.557 7.775-46.941 10.37-69.788.001 0 105.135-44.533 105.135 148.078z"
        fill="#b4acb7"
      />
      <Path
        d="M290.692 324.328l75.519 74.906h7.993c0-35.958 47.927-81.487 31.993-153.196-5.014-22.557-7.775-46.941-10.37-69.788-.001 0-105.135-44.533-105.135 148.078z"
        fill="#f9f6f9"
      />
      <Path
        d="M409.051 285.277c-1.121 14.636-4.588 28.007-8.917 40.308-13.785-4.111-27.695-7.713-41.626-11.346-8.647-2.263-17.429-4.048-26.014-6.509-3.073-.882-5.865-1.899-7.775-4.64a10.278 10.278 0 01-1.391-8.803c1.007-3.301 3.706-5.917 7.017-6.872 8.637-2.418 17.408-3.789 26.315-4.764 8.948-.976 18.01-.716 26.989-.405 8.492.28 17.046 1.474 25.402 3.031z"
        fill="#665e66"
      />
      <Path
        d="M187.281 303.09c-1.91 2.741-4.702 3.758-7.775 4.64-8.585 2.46-17.367 4.246-26.014 6.509-13.931 3.633-27.841 7.235-41.626 11.346-4.329-12.301-7.796-25.671-8.917-40.308 8.356-1.557 16.91-2.751 25.401-3.031 8.979-.311 18.041-.571 26.989.405 8.906.976 17.678 2.346 26.315 4.764 3.311.955 6.01 3.571 7.017 6.872a10.274 10.274 0 01-1.39 8.803z"
        fill="#454045"
      />
      <G fill="#282528">
        <Path d="M133.879 197.635c-13.006 0-23.588 15.814-23.588 35.253 0 19.438 10.581 35.252 23.588 35.252 13.006 0 23.587-15.814 23.587-35.252 0-19.439-10.581-35.253-23.587-35.253zM378.121 197.635c-13.006 0-23.588 15.814-23.588 35.253 0 19.438 10.582 35.252 23.588 35.252s23.587-15.814 23.587-35.252c.001-19.439-10.581-35.253-23.587-35.253z" />
      </G>
      <Path
        d="M307.296 321.923c-12.676 0-24.525 4.076-34.626 11.154a28.977 28.977 0 01-33.341 0c-10.1-7.078-21.95-11.154-34.625-11.154-36.953 0-66.909 34.616-66.909 77.316s29.956 77.316 66.909 77.316c2.207 0 4.39-.124 6.542-.365a400.799 400.799 0 0189.508 0c2.153.242 4.335.365 6.543.365 36.953 0 66.908-34.616 66.908-77.316s-29.956-77.316-66.909-77.316z"
        fill="#665e66"
      />
      <G fill="#454045">
        <Path d="M224.255 474.945a392.21 392.21 0 00-13.017 1.246 56.777 56.777 0 01-6.54.374c-36.955 0-66.903-34.619-66.903-77.325 0-42.695 29.948-77.314 66.903-77.314 5.73 0 11.294.83 16.609 2.408-28.92 8.533-50.294 38.844-50.294 74.906 0 37.286 22.848 68.417 53.242 75.705z" />
        <Circle cx={198.023} cy={399.239} r={20.95} />
        <Circle cx={313.977} cy={399.239} r={20.95} />
      </G>
    </Svg>
  )
}

export default Zebra
