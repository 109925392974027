import React from "react";
import { Modal, View, Text } from "react-native";
import Btn from "./Btn";

const ModalNewContent = ({action}) => {

    return (
        <Modal
            animationType="slide"
            transparent={true}
        >
            <View style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
            }}>
                <View style={{
                    width: '90%',
                    maxWidth: 500,
                    backgroundColor: "white",
                    borderRadius: 20,
                    padding: 35,
                    alignItems: "center",
                    shadowColor: "#000",
                    shadowOffset: {
                    width: 0,
                    height: 2
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    backgroundColor: "#fae8c9"
                }}>
                    <Text style={{
                        fontSize: 20,
                        color: "#337373",
                        textAlign: 'center',
                        marginBottom: 10
                    }}>
                        Votre bibliothèque de pépites a été mise à jour avec succès !</Text>
                    <Btn color='green' text='OK' action={action} />
                </View>
            </View>
        </Modal>
    )
}

export default ModalNewContent