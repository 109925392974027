import React from "react"
import { Svg, Path, Ellipse, G, Circle } from "react-native-svg"

function FoxOne({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Fox"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M439.893 4.169c11.115-4.246 23.06 4.141 22.543 16.028-4.168 95.801-37.145 178.387-37.145 178.387l-124.559-87.398C350.393 51.372 410.485 15.404 439.893 4.169z"
        fill="#d26437"
      />
      <Path
        d="M342.252 140.319c32.368-52.714 81.19-103.046 96.647-112.026 6.266-3.64 11.768 5.266 10.365 17.005-7.465 62.48-21.005 118.599-29.343 149.519l-77.669-54.498z"
        fill="#b44632"
      />
      <Path
        d="M72.107 4.169C60.992-.077 49.047 8.31 49.564 20.197c4.168 95.801 37.145 178.387 37.145 178.387l124.559-87.398C161.607 51.372 101.515 15.404 72.107 4.169z"
        fill="#d26437"
      />
      <Path
        d="M169.748 140.319C137.38 87.605 88.558 37.273 73.101 28.293c-6.266-3.64-11.768 5.266-10.365 17.005 7.465 62.48 21.005 118.599 29.343 149.519l77.669-54.498z"
        fill="#b44632"
      />
      <G fill="#ebc9a0">
        <Ellipse cx={256} cy={475.92} rx={49.548} ry={33.03} />
        <Path d="M198.194 467.659c-57.806-33.032-153.573-68.995-153.573-68.995l9.19-27.571c-3.874-1.84-30.413-2.187-53.81 5.727l24.774-41.29h173.419v132.129zM313.806 467.659c57.806-33.032 153.573-68.995 153.573-68.995l-9.19-27.571c3.874-1.84 30.413-2.187 53.81 5.727l-24.774-41.29H313.806v132.129z" />
      </G>
      <Path
        d="M256 96.047c120.377 0 223.716 120.61 255.628 278.91.125.621.249 1.242.372 1.864 0 0-60.903-62.968-126.968-29.419-24.499 12.441-36.129 39.775-36.129 69.644 0 49.524-39.699 76.926-86.73 57.891a16.348 16.348 0 00-12.347 0c-47.031 19.036-86.73-8.366-86.73-57.891 0-29.869-11.631-57.203-36.129-69.644C60.903 313.853 0 376.821 0 376.821c.123-.622.247-1.243.372-1.864C32.284 216.656 135.623 96.047 256 96.047z"
        fill="#eb874b"
      />
      <Path
        d="M169.29 327.272c-15.897 0-28.903-13.006-28.903-28.903v-16.516c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c.001 15.897-13.006 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M169.29 252.95c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516v-12.387c.001-15.897-13.006-28.903-28.903-28.903z"
        fill="#5d5360"
      />
      <Circle cx={169.29} cy={273.6} r={12.387} fill="#fff" />
      <Path
        d="M342.71 327.272c-15.897 0-28.903-13.006-28.903-28.903v-16.516c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c0 15.897-13.007 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M342.71 252.95c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516v-12.387c0-15.897-13.007-28.903-28.903-28.903z"
        fill="#5d5360"
      />
      <Circle cx={342.71} cy={273.6} r={12.387} fill="#fff" />
      <Path
        d="M247.742 475.699c.715-.27 1.431-.546 2.149-.832a16.526 16.526 0 0112.219 0c.718.286 1.434.562 2.149.832v-32.814h-16.516l-.001 32.814z"
        fill="#d26437"
      />
      <Path
        d="M255.523 393.337c18.507 0 33.51 12.262 33.51 27.389 0 14.588-14.083 22.269-24.247 27.949-5.892 3.292-12.633 3.292-18.525 0-10.164-5.68-24.247-13.361-24.247-27.949-.001-15.127 15.002-27.389 33.509-27.389z"
        fill="#5d5360"
      />
      <Path
        d="M263.731 447.968c-10.164-7.302-24.247-17.178-24.247-35.934 0-6.147 1.632-11.838 4.266-16.868-12.676 3.905-21.737 13.832-21.737 25.559 0 14.588 14.083 22.269 24.247 27.949 5.892 3.292 12.634 3.292 18.525 0l.142-.08c-.394-.241-.809-.347-1.196-.626z"
        fill="#4b3f4e"
      />
      <Path
        d="M256 96.047l-16.059.803C126.521 107.015 30.854 223.749.372 374.956A558.31 558.31 0 000 376.82s49.151-50.767 107.036-36.784C114.274 134.366 242.558 96.047 256 96.047z"
        fill="#d26437"
      />
    </Svg>
  )
}

export default FoxOne