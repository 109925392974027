import { StyleSheet, Dimensions, Platform } from "react-native";  

// colle la scrollbar sur PC à gauche
const windowWidth = Dimensions.get('window').width
let width
if (Platform.OS === 'web' && windowWidth > 700) {
    width = windowWidth - 310 
} else {
    width = windowWidth
}

export default StyleSheet.create({
    container:{
        paddingTop: 50,
        width: width,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    avatarBtn:{
        alignItems: 'center',
        justifyContent: 'center',
        height: 120,
        width: 120,
        backgroundColor:'#57B5B5',
        borderRadius: 100,
        marginBottom: 30
    },
    modal:{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
})