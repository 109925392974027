import React, { useEffect } from 'react';
import { Alert, Linking, Platform } from 'react-native';
import { AuthProvider } from './AuthProvider';
import VersionCheck from 'react-native-version-check-expo';
import { Routes } from './Routes';


export const Providers = () => {
    
    // Update App
    useEffect(() => {
        if(Platform.OS !== "web") {
            checkVersion();
        }
    }, []);

    const checkVersion = async () => {
        VersionCheck.needUpdate()
            .then(async res => {
                console.log(res);
                if (res.isNeeded) {
                    Alert.alert(
                        "Mise à jour",
                        `KidyWoo s'améliore pour vous ! Une nouvelle version est disponible sur le store. \n\n
                        Version installée : ${res.currentVersion} \n
                        Nouvelle version : ${res.latestVersion}`,
                        [   
                            {text: "Plus tard",style: "cancel"},
                            {text: "Mettre à jour",
                            onPress: () => {
                                    Linking.openURL(res.storeUrl);
                                },
                            },
                        ],
                        {cancelable: false},
                    );
                }
            });
    }

    return (
        <AuthProvider>
            <Routes/>
        </AuthProvider>
    );
};