import React from "react"

import Bear from "../../../../assets/img/avatar/Bear"
import Beaver from "../../../../assets/img/avatar/Beaver"
import Cat from "../../../../assets/img/avatar/Cat"
import Cheetah from "../../../../assets/img/avatar/Cheetah"
import Chicken from "../../../../assets/img/avatar/Chicken"
import CowOne from "../../../../assets/img/avatar/CowOne"
import CowTwo from "../../../../assets/img/avatar/CowTwo"
import Deer from "../../../../assets/img/avatar/Deer"
import Dog from "../../../../assets/img/avatar/Dog"
import Duck from "../../../../assets/img/avatar/Duck"
import FoxOne from "../../../../assets/img/avatar/FoxOne"
import FoxTwo from "../../../../assets/img/avatar/FoxTwo"
import Frog from "../../../../assets/img/avatar/Frog"
import Giraffe from "../../../../assets/img/avatar/Giraffe"
import HamsterOne from "../../../../assets/img/avatar/HamsterOne"
import HamsterTwo from "../../../../assets/img/avatar/HamsterTwo"
import Hedgehog from "../../../../assets/img/avatar/Hedgehog"
import Hippopotamus from "../../../../assets/img/avatar/Hippopotamus"
import Koala from "../../../../assets/img/avatar/Koala"
import LadyBug from "../../../../assets/img/avatar/LadyBug"
import Monkey from "../../../../assets/img/avatar/Monkey"
import Mouse from "../../../../assets/img/avatar/Mouse"
import Owl from "../../../../assets/img/avatar/Owl"
import Panda from "../../../../assets/img/avatar/Panda"
import Penguin from "../../../../assets/img/avatar/Penguin"
import PolarBear from "../../../../assets/img/avatar/PolarBear"
import Rabbit from "../../../../assets/img/avatar/Rabbit"
import RaccoonOne from "../../../../assets/img/avatar/RaccoonOne"
import RaccoonTwo from "../../../../assets/img/avatar/RaccoonTwo"
import Tiger from "../../../../assets/img/avatar/Tiger"
import Unicorn from "../../../../assets/img/avatar/Unicorn"
import Walrus from "../../../../assets/img/avatar/Walrus"
import Wolf from "../../../../assets/img/avatar/Wolf"
import Zebra from "../../../../assets/img/avatar/Zebra"
import FlowerOne from "../../../../assets/img/avatar/FlowerOne";
import FlowerTwo from "../../../../assets/img/avatar/FlowerTwo";
import FlowerThree from "../../../../assets/img/avatar/FlowerThree";
import FlowerFour from "../../../../assets/img/avatar/FlowerFour";
import FlowerFive from "../../../../assets/img/avatar/FlowerFive";
import FlowerSix from "../../../../assets/img/avatar/FlowerSix";
import FlowerSeven from "../../../../assets/img/avatar/FlowerSeven";
import FlowerHeight from "../../../../assets/img/avatar/FlowerHeight";
import FlowerNine from "../../../../assets/img/avatar/FlowerNine";
import FlowerTen from "../../../../assets/img/avatar/FlowerTen";
import FlowerEleven from "../../../../assets/img/avatar/FlowerEleven";
import FlowerTwelve from "../../../../assets/img/avatar/FlowerTwelve";
import FlowerThirteen from "../../../../assets/img/avatar/FlowerThirteen";
import FlowerFourteen from "../../../../assets/img/avatar/FlowerFourteen";
import FlowerFifteen from "../../../../assets/img/avatar/FlowerFifteen";

// SUR UNE PAGE
const DynamicAvatarComponent = (data) => {
    const components = {
        Bear: Bear,
        Beaver: Beaver,
        Cat: Cat,
        Cheetah: Cheetah,
        Chicken: Chicken,
        CowOne: CowOne,
        CowTwo: CowTwo,
        Deer: Deer,
        Dog: Dog,
        Duck: Duck,
        FoxOne: FoxOne,
        FoxTwo: FoxTwo,
        Frog: Frog,
        Giraffe: Giraffe,
        HamsterOne: HamsterOne,
        HamsterTwo: HamsterTwo,
        Hedgehog: Hedgehog,
        Hippopotamus: Hippopotamus,
        Koala: Koala,
        LadyBug: LadyBug,
        Monkey: Monkey,
        Mouse: Mouse,
        Owl: Owl,
        Panda: Panda,
        Penguin: Penguin,
        PolarBear: PolarBear,
        Rabbit: Rabbit,
        RaccoonOne: RaccoonOne,
        RaccoonTwo: RaccoonTwo,
        Tiger: Tiger,
        Unicorn: Unicorn,
        Walrus: Walrus,
        Wolf: Wolf,
        Zebra: Zebra,
        FlowerOne: FlowerOne,
        FlowerTwo: FlowerTwo,
        FlowerThree: FlowerThree,
        FlowerFour: FlowerFour,
        FlowerFive: FlowerFive,
        FlowerSix: FlowerSix,
        FlowerSeven: FlowerSeven,
        FlowerHeight: FlowerHeight,
        FlowerNine: FlowerNine,
        FlowerTen: FlowerTen,
        FlowerEleven: FlowerEleven,
        FlowerTwelve: FlowerTwelve,
        FlowerThirteen: FlowerThirteen,
        FlowerFourteen: FlowerFourteen,
        FlowerFifteen: FlowerFifteen
    }    
    const TagName = components[data.tag || 'Bear'];

    return <TagName size={data.size} />
}
export  default DynamicAvatarComponent


// DANS LE MENU
export const DynamicAvatarComponentMenu = (data) => {
    const components = {
        Bear: Bear,
        Beaver: Beaver,
        Cat: Cat,
        Cheetah: Cheetah,
        Chicken: Chicken,
        CowOne: CowOne,
        CowTwo: CowTwo,
        Deer: Deer,
        Dog: Dog,
        Duck: Duck,
        FoxOne: FoxOne,
        FoxTwo: FoxTwo,
        Frog: Frog,
        Giraffe: Giraffe,
        HamsterOne: HamsterOne,
        HamsterTwo: HamsterTwo,
        Hedgehog: Hedgehog,
        Hippopotamus: Hippopotamus,
        Koala: Koala,
        LadyBug: LadyBug,
        Monkey: Monkey,
        Mouse: Mouse,
        Owl: Owl,
        Panda: Panda,
        Penguin: Penguin,
        PolarBear: PolarBear,
        Rabbit: Rabbit,
        RaccoonOne: RaccoonOne,
        RaccoonTwo: RaccoonTwo,
        Tiger: Tiger,
        Unicorn: Unicorn,
        Walrus: Walrus,
        Wolf: Wolf,
        Zebra: Zebra,
        FlowerOne: FlowerOne,
        FlowerTwo: FlowerTwo,
        FlowerThree: FlowerThree,
        FlowerFour: FlowerFour,
        FlowerFive: FlowerFive,
        FlowerSix: FlowerSix,
        FlowerSeven: FlowerSeven,
        FlowerHeight: FlowerHeight,
        FlowerNine: FlowerNine,
        FlowerTen: FlowerTen,
        FlowerEleven: FlowerEleven,
        FlowerTwelve: FlowerTwelve,
        FlowerThirteen: FlowerThirteen,
        FlowerFourteen: FlowerFourteen,
        FlowerFifteen: FlowerFifteen
    }    
    const TagName = components[data.tag || 'Bear'];

    return <TagName size={data.size} />
}