import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function Monkey({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Monkey"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Circle cx={66.06} cy={222.97} r={66.06} fill="#965a50" />
      <Circle cx={66.06} cy={222.97} r={41.29} fill="#ebc9a0" />
      <Circle cx={445.94} cy={222.97} r={66.06} fill="#965a50" />
      <Circle cx={445.94} cy={222.97} r={41.29} fill="#ebc9a0" />
      <Path
        d="M442.589 262.049c-8.366-14.436-13.169-30.655-13.169-47.34v-.001c0-72.373-44.364-134.33-107.355-160.318V24.774l-41.29 16.516-8.258-33.032c-21.781 7.261-40.361 22.498-54.356 37.298-77.557 17.283-135.58 86.39-135.58 169.154 0 16.685-4.803 32.904-13.169 47.34-12.72 21.948-19.863 46.482-19.863 72.402 0 93.496 92.431 169.29 206.452 169.29s206.452-75.794 206.452-169.29c-.001-25.92-7.145-50.455-19.864-72.403z"
        fill="#af6e5a"
      />
      <Path
        d="M140.387 364.043c0-30.24 7.143-58.864 19.863-84.469 8.367-16.841 13.169-35.764 13.169-55.23 0-84.035 43.969-155.956 106.493-186.502l-7.396-29.584c-21.781 7.261-40.361 22.498-54.357 37.298-77.555 17.283-135.578 86.39-135.578 169.154 0 16.685-4.802 32.904-13.169 47.34-12.72 21.948-19.863 46.482-19.863 72.402 0 75.465 60.232 139.37 143.415 161.223-32.682-34.941-52.577-81.056-52.577-131.632z"
        fill="#965a50"
      />
      <Path
        d="M256 470.71c68.412 0 123.871-44.367 123.871-99.097 0-11.354-2.414-22.245-6.835-32.386-6.41-14.707-4.228-31.587 6.07-43.889 13.134-15.691 19.908-36.877 16.333-59.635-4.91-31.259-30.182-56.486-61.448-61.353-23.892-3.719-46.037 3.968-61.903 18.439-4.51 4.113-10.3 6.17-16.087 6.17-5.79 0-11.581-2.056-16.091-6.17-15.866-14.471-38.011-22.158-61.903-18.439-31.266 4.866-56.537 30.094-61.448 61.353-3.575 22.757 3.199 43.943 16.333 59.635 10.298 12.303 12.48 29.182 6.07 43.889-4.42 10.142-6.835 21.033-6.835 32.386.002 54.729 55.461 99.097 123.873 99.097z"
        fill="#faebc8"
      />
      <Path
        d="M132.129 371.612c0 18.522 6.468 35.795 17.524 50.625-5.938-18.411-9.266-37.916-9.266-58.195 0-30.24 7.143-58.864 19.863-84.469 8.367-16.841 13.169-35.764 13.169-55.23 0-17.307 1.96-34.056 5.468-50.08-.295.042-.583.04-.879.086-31.266 4.866-56.536 30.094-61.448 61.352-3.575 22.758 3.2 43.944 16.333 59.635 10.298 12.302 12.481 29.181 6.071 43.889-4.421 10.143-6.835 21.034-6.835 32.387z"
        fill="#f5dcb4"
      />
      <G fill="#5d5360">
        <Path d="M239.476 330.323c-1.242 0-2.5-.278-3.685-.871l-16.516-8.258a8.258 8.258 0 01-3.694-11.081c2.048-4.081 7-5.734 11.081-3.694l16.516 8.258a8.258 8.258 0 01-3.702 15.646zM272.524 330.323a8.258 8.258 0 01-3.701-15.646l16.516-8.258c4.073-2.04 9.032-.387 11.081 3.694a8.258 8.258 0 01-3.694 11.081l-16.516 8.258a8.227 8.227 0 01-3.686.871z" />
      </G>
      <Path
        d="M182.319 363.355c-5.001 0-8.941 4.431-8.248 9.384 5.126 36.617 39.853 64.938 81.929 64.938 42.077 0 76.803-28.321 81.929-64.938.693-4.953-3.247-9.384-8.248-9.384H182.319z"
        fill="#4b3f4e"
      />
      <Path
        d="M208.417 424.038c13.457 8.563 29.849 13.639 47.583 13.639s34.126-5.076 47.583-13.639c-5.966-20.666-25.063-35.909-47.583-35.909s-41.617 15.242-47.583 35.909z"
        fill="#e6646e"
      />
      <Path
        d="M181.677 272.516c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c.001 13.682-11.091 24.774-24.774 24.774z"
        fill="#4b3f4e"
      />
      <Path
        d="M181.677 214.71v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c.001-13.683-11.091-24.774-24.774-24.774z"
        fill="#5d5360"
      />
      <Circle cx={181.68} cy={231.23} r={8.258} fill="#fff" />
      <Path
        d="M330.323 272.516c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c0 13.682-11.092 24.774-24.774 24.774z"
        fill="#4b3f4e"
      />
      <Path
        d="M330.323 214.71v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c0-13.683-11.092-24.774-24.774-24.774z"
        fill="#5d5360"
      />
      <Circle cx={330.32} cy={231.23} r={8.258} fill="#fff" />
      <Path
        d="M256 437.677c2.792 0 5.538-.169 8.258-.415v-16.101c0-4.56-3.694-8.258-8.258-8.258s-8.258 3.698-8.258 8.258v16.101c2.72.246 5.466.415 8.258.415z"
        fill="#ff8087"
      />
    </Svg>
  )
}

export default Monkey