import React from "react"
import { Svg, Path, Ellipse, G, Circle } from "react-native-svg"

function HamsterOne({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Hamster"
      x={0}
      y={0}
      viewBox="0 0 512.001 512.001">
      <Ellipse
        transform="rotate(-116.481 108.28 101.773)"
        cx={108.281}
        cy={101.775}
        rx={88.811}
        ry={72.091}
        fill="#ebaf4b"
      />
      <Ellipse
        transform="rotate(-116.481 96.1 108.102)"
        cx={96.101}
        cy={108.104}
        rx={69.988}
        ry={47.011}
        fill="#ff8087"
      />
      <Ellipse
        transform="rotate(116.481 404.265 101.783)"
        cx={404.268}
        cy={101.782}
        rx={88.811}
        ry={72.091}
        fill="#ebaf4b"
      />
      <Ellipse
        transform="rotate(116.481 416.445 108.116)"
        cx={416.448}
        cy={108.115}
        rx={69.988}
        ry={47.011}
        fill="#ff8087"
      />
      <Path
        d="M256.003 492.633c18.525 0 37.049.864 55.483 2.592 9.921.93 20.084 1.032 30.422.231 86.812-6.726 156.824-78.193 161.797-165.124 2.531-44.23-11.297-85.149-35.979-117.311-19.267-25.105-37.083-51.474-49.254-80.686-26.507-63.616-89.249-108.34-162.47-108.34s-135.964 44.724-162.47 108.34c-12.172 29.212-29.988 55.581-49.254 80.686-24.682 32.161-38.51 73.081-35.979 117.311 4.974 86.93 74.985 158.398 161.797 165.124 10.338.801 20.501.699 30.422-.231a594.59 594.59 0 0155.485-2.592z"
        fill="#ffc850"
      />
      <Path
        d="M81.308 352.805c-7.419-46.715 2.321-91.638 24.161-128.4 17.048-28.697 32.469-58.584 41.785-90.896 13.029-45.193 42.311-83.6 81.474-107.399-61.262 9.536-112.049 50.671-135.196 106.225-12.171 29.212-29.988 55.581-49.254 80.687-24.682 32.161-38.51 73.08-35.979 117.311 4.974 86.93 74.985 158.397 161.797 165.124 6.86.531 13.606.525 20.292.283-55.924-24.966-98.779-78.072-109.08-142.935z"
        fill="#ebaf4b"
      />
      <Path
        d="M368.001 333.995c-57.837 15.571-89.682-21.1-85.667-56.667 4.667-41.333-41-45.666-49.333-11.667-12.795 52.203-32.921 71.931-107.5 65.833-68.42-5.594-97.889 63.976-60.807 117.266 28.273 26.254 64.957 43.561 105.401 46.694 10.338.801 20.501.699 30.422-.231a594.628 594.628 0 0155.486-2.592c18.524 0 37.049.864 55.482 2.592 9.921.93 20.084 1.032 30.422.231 47.767-3.701 90.369-27.073 119.927-61.767 34.693-61.705-19.56-119.688-93.833-99.692z"
        fill="#faebc8"
      />
      <Path
        d="M256 351.995c26.51 0 48.001 14.327 48.001 32 0 17.044-20.173 26.018-34.733 32.654a31.95 31.95 0 01-26.536 0c-14.559-6.636-34.733-15.61-34.733-32.654 0-17.673 21.491-32 48.001-32z"
        fill="#ff96a0"
      />
      <Path
        d="M287.013 463.995c-8.984 0-17.75-2.348-25.355-6.785a11.224 11.224 0 00-11.312-.004c-7.605 4.441-16.371 6.789-25.359 6.789-15.773 0-30.508-7.191-39.418-19.238-2.625-3.555-1.875-8.563 1.676-11.191a8 8 0 0111.191 1.676c5.902 7.988 15.832 12.754 26.551 12.754 6.152 0 12.129-1.59 17.285-4.602a27.233 27.233 0 0127.461 0c5.152 3.008 11.133 4.602 17.281 4.602 10.723 0 20.652-4.77 26.555-12.754 2.637-3.551 7.641-4.305 11.188-1.68a8 8 0 011.68 11.188c-8.912 12.05-23.647 19.245-39.424 19.245z"
        fill="#ebc9a0"
      />
      <G fill="#dcb996">
        <Path d="M437.497 424.999a7.944 7.944 0 01-3.133-.645c-32.344-13.793-74.402-16.348-74.824-16.371-4.41-.254-7.781-4.035-7.527-8.449s3.953-7.719 8.449-7.527c1.832.105 45.293 2.754 80.176 17.629 4.066 1.734 5.957 6.434 4.223 10.496a8.003 8.003 0 01-7.364 4.867zM504.001 391.999a7.954 7.954 0 01-2.824-.52c-54.688-20.633-151.664-14.359-152.637-14.297-4.43.387-8.223-3.031-8.52-7.441a7.995 7.995 0 017.441-8.52c4.102-.277 101.406-6.578 159.363 15.289a8 8 0 014.66 10.309 8.001 8.001 0 01-7.483 5.18z" />
      </G>
      <Path
        d="M172.001 303.995c-15.4 0-28-12.6-28-28v-16c0-15.4 12.6-28 28-28s28 12.6 28 28v16c0 15.4-12.6 28-28 28z"
        fill="#4b3f4e"
      />
      <Path
        d="M172.001 231.995c-1.369 0-2.682.214-4 .406v39.594c0 8.836 7.164 16 16 16 8.837 0 16-7.164 16-16v-12c0-15.4-12.6-28-28-28z"
        fill="#5d5360"
      />
      <Circle cx={172} cy={251.994} r={12} fill="#fff" />
      <Path
        d="M340.001 303.995c-15.4 0-28-12.6-28-28v-16c0-15.4 12.6-28 28-28s28 12.6 28 28v16c0 15.4-12.6 28-28 28z"
        fill="#4b3f4e"
      />
      <Path
        d="M340.001 231.995c-1.369 0-2.682.214-4 .406v39.594c0 8.836 7.164 16 16 16 8.837 0 16-7.164 16-16v-12c0-15.4-12.6-28-28-28z"
        fill="#5d5360"
      />
      <Circle cx={340} cy={251.994} r={12} fill="#fff" />
      <Path
        d="M266.734 412.578c-14.56-7.86-34.733-18.49-34.733-38.679 0-7.283 2.647-14.063 7.156-19.838C220.968 358.612 208 370.279 208 383.995c0 17.043 20.173 26.018 34.732 32.654a31.957 31.957 0 0026.536 0c1.17-.533 2.384-1.088 3.614-1.655-2.093-.567-4.159-1.342-6.148-2.416z"
        fill="#ff8087"
      />
      <Path
        d="M81.308 352.805c-.615-3.875-.937-7.713-1.319-11.559-33.745 20.427-42.568 68.322-15.295 107.515 28.273 26.254 64.957 43.561 105.401 46.694 6.86.531 13.606.525 20.292.283-55.923-24.964-98.778-78.07-109.079-142.933z"
        fill="#f5dcb4"
      />
      <G fill="#dcb996">
        <Path d="M74.505 424.999a8.002 8.002 0 01-7.363-4.867 7.998 7.998 0 014.223-10.496c34.883-14.875 78.344-17.523 80.176-17.629 4.395-.164 8.191 3.121 8.445 7.527.258 4.41-3.113 8.191-7.523 8.449-.422.023-42.555 2.609-74.824 16.371a7.953 7.953 0 01-3.134.645zM8.001 391.999a8.004 8.004 0 01-7.484-5.18 8 8 0 014.66-10.309c57.953-21.863 155.25-15.582 159.363-15.289a7.995 7.995 0 017.441 8.52c-.301 4.414-4.129 7.836-8.52 7.441-.977-.066-97.949-6.328-152.637 14.297a7.956 7.956 0 01-2.823.52z" />
      </G>
    </Svg>
  )
}

export default HamsterOne