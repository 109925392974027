import { StyleSheet } from "react-native";  


export default StyleSheet.create({
    container:{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1000,
        height: '100%',
        paddingTop: 50
    },
    loader:{
        flex: 2,
        flexDirection: 'column',
        alignItems: 'center'
    },
    basicLink:{
        marginTop: 20,
        color: '#e1a0a2',
    }
})