import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function Deer({size}) {
  return (
    <Svg
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Deer"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <G fill="#8b534f">
        <Path d="M238.229 206.893c-1.265-1.52-24.558-28.916-80.305-59.51l-7.013-6.603c-7.242-9.633-13.655-18.196-18.684-26.526 7.956-4.698 21.924-14.851 34.531-33.764a7.991 7.991 0 00-2.218-11.09c-3.694-2.46-8.654-1.457-11.09 2.218-10.529 15.797-21.91 24.292-28.401 28.269-4.749-12.378-5.647-25.287-.629-41.457a7.994 7.994 0 00-5.264-10.009 7.976 7.976 0 00-10.013 5.268c-9.906 31.928-1.01 53.843 14.354 76.549-10.105-4.6-20.843-9.183-32.497-13.665-10.532-4.05-19.56-8.65-27.355-13.576C79.899 76.44 80.127 14.734 80.127 12.072a7.996 7.996 0 00-7.998-7.998 8 8 0 00-7.998 7.998c-.007 16.547-2.497 62.184-13.482 81.51-37.851-31.522-34.752-70.519-34.543-72.7a8 8 0 00-7.123-8.767C4.641 11.682.657 14.837.197 19.234c-.086.801-1.953 19.889 7.795 43.292 8.872 21.326 29.484 50.602 77.259 68.98 100.4 38.61 140.31 85.174 140.685 85.627a7.991 7.991 0 006.154 2.878 8 8 0 006.139-13.118zM273.771 206.893c1.265-1.52 24.558-28.916 80.305-59.51l7.013-6.603c7.242-9.633 13.655-18.196 18.684-26.526-7.956-4.698-21.924-14.851-34.531-33.764a7.991 7.991 0 012.218-11.09c3.694-2.46 8.654-1.457 11.09 2.218 10.529 15.797 21.91 24.292 28.401 28.269 4.749-12.378 5.647-25.287.629-41.457a7.994 7.994 0 015.264-10.009 7.976 7.976 0 0110.013 5.268c9.906 31.928 1.01 53.843-14.354 76.549 10.105-4.6 20.843-9.183 32.497-13.665 10.532-4.05 19.56-8.65 27.355-13.576-16.255-26.557-16.483-88.263-16.483-90.925a7.996 7.996 0 017.998-7.998 8 8 0 017.998 7.998c.007 16.547 2.497 62.184 13.482 81.51 37.851-31.522 34.752-70.519 34.543-72.7a8 8 0 017.123-8.767c4.342-.433 8.326 2.722 8.786 7.119.086.801 1.953 19.889-7.795 43.292-8.872 21.326-29.484 50.602-77.259 68.98-100.4 38.61-140.31 85.174-140.685 85.627a7.991 7.991 0 01-6.154 2.878 7.979 7.979 0 01-5.116-1.855 8.002 8.002 0 01-1.022-11.263z" />
      </G>
      <Path
        d="M57.701 181.854c4.79 22.291 23.579 74.997 94.405 78.145l39.988-39.988s-65.828-38.3-126.033-46.851c-5.084-.723-9.439 3.673-8.36 8.694z"
        fill="#af6e5a"
      />
      <Path
        d="M66.061 173.159a7.52 7.52 0 00-4.952 1.007c15.596 6.963 65.041 31.047 104.575 72.255l26.41-26.41s-65.828-38.301-126.033-46.852z"
        fill="#c0826c"
      />
      <Path
        d="M454.451 181.854c-4.79 22.291-23.579 74.997-94.405 78.145l-39.988-39.988s65.828-38.3 126.033-46.851c5.083-.723 9.438 3.673 8.36 8.694z"
        fill="#af6e5a"
      />
      <Path
        d="M446.089 173.159a7.52 7.52 0 014.952 1.007c-15.596 6.963-65.041 31.047-104.575 72.255l-26.41-26.41s65.828-38.301 126.033-46.852z"
        fill="#c0826c"
      />
      <Path
        d="M208.089 459.94c0 26.502 11.546 47.986 47.986 47.986s47.986-21.484 47.986-47.986h-95.972z"
        fill="#8b534f"
      />
      <Path
        d="M256.076 196.018c-92.756 0-119.965 53.71-119.965 119.965 0 55.984 15.995 55.984 55.984 111.967h127.962c39.988-55.984 55.984-55.984 55.984-111.967-.001-66.256-27.209-119.965-119.965-119.965z"
        fill="#c0826c"
      />
      <Path
        d="M220.086 316.482c0-52.651 9.597-98.143 38.063-120.387-.715-.006-1.351-.077-2.074-.077-92.756 0-119.965 53.71-119.965 119.965 0 55.984 15.995 55.984 55.984 111.967h60.512c-23.084-48.795-32.52-53.785-32.52-111.468z"
        fill="#af6e5a"
      />
      <G fill="#4b3f4e">
        <Path d="M184.096 339.975c8.834 0 15.995-7.161 15.995-15.995v-7.998c0-8.834-7.161-15.995-15.995-15.995s-15.995 7.161-15.995 15.995v7.998c0 8.834 7.161 15.995 15.995 15.995zM328.054 339.975c-8.834 0-15.995-7.161-15.995-15.995v-7.998c0-8.834 7.161-15.995 15.995-15.995s15.995 7.161 15.995 15.995v7.998c0 8.834-7.161 15.995-15.995 15.995z" />
      </G>
      <Path
        d="M256.076 371.966c36.992 0 66.98 23.274 66.98 51.985s-16.116 51.985-66.98 51.985-66.98-23.274-66.98-51.985 29.987-51.985 66.98-51.985z"
        fill="#d29b78"
      />
      <G fill="#fff">
        <Circle cx={184.1} cy={315.98} r={7.998} />
        <Circle cx={328.05} cy={315.98} r={7.998} />
      </G>
      <Path
        d="M283.067 467.938c-50.864 0-66.98-26.855-66.98-59.982 0-10.987 3.354-21.252 9.113-30.108-21.441 8.668-36.105 26.043-36.105 46.104 0 28.71 16.116 51.985 66.98 51.985 19.379 0 33.686-3.395 44.008-9.165-5.188.754-10.835 1.166-17.016 1.166z"
        fill="#c0826c"
      />
      <Path
        d="M288.066 429.092c0 9.465-14.323 22.85-31.991 22.85s-31.991-13.385-31.991-22.85 14.323-17.138 31.991-17.138 31.991 7.673 31.991 17.138z"
        fill="#5d5360"
      />
      <Path
        d="M266.538 450.248c-15.243-5.3-26.458-22.241-26.458-34.867 0-.374.125-.722.144-1.092-9.601 2.958-16.14 8.444-16.14 14.803 0 7.977 10.219 18.644 23.993 21.832V475.7c2.578.137 5.214.235 7.998.235 2.783 0 5.419-.099 7.998-.235v-24.858c.822-.201 1.667-.35 2.465-.594z"
        fill="#4b3f4e"
      />
    </Svg>
  )
}

export default Deer