import { StyleSheet, Dimensions } from "react-native";  

const windowWidth = Dimensions.get('window').width;

export default StyleSheet.create({
    container:{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1000,
        height: '100%',
        paddingTop: 50
    },
    containerSuccess: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
    },
    loader:{
        flex: 2,
        flexDirection: 'column',
        alignItems: 'center',
        width:(windowWidth*90)/100
    },
    h2:{
        fontFamily: 'RobotoBold',
        textAlign: 'center',
        color: '#57B5B5',
        fontSize: 25,
	    margin: 15,
    },
    h3:{
        fontFamily: 'Roboto',
        textAlign: 'center',
        color: '#57B5B5',
        fontSize: 20,
        fontStyle: 'italic',
	    margin: 15,
    },
    form:{
        justifyContent: 'center',
        alignItems: 'center',
        width: (windowWidth*90)/100,
        maxWidth: 500,
        marginBottom: 20,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 15,
        backgroundColor: "#FCF1DF"
    },
    btn_green:{
        height: 70,
        width: 250,
        lineHeight: 70,
        fontSize: 20,
        textAlign: 'center',
        borderRadius: 15,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: "#57B5B5",
        overflow: "hidden",
        color: '#fff',
        backgroundColor: '#57B5B5'
    },
    basicLink:{
        marginTop: 20,
        color: '#e1a0a2'
    },
    danger:{
        color: "#f68f6a",
        margin: 20
    }
})