import React from "react"
import { Svg, Path, G } from "react-native-svg"

function FlowerSix({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <G fill="#ffe9c3">
        <Path d="M314.185 262.465l-51.72 51.72c21.818 41.702 59.68 111.399 71.115 122.834 28.565 28.565 74.875 28.565 103.44 0 28.563-28.563 28.563-74.875 0-103.439-11.402-11.402-81.281-49.364-122.835-71.115zM74.981 333.579c-28.563 28.563-28.563 74.875 0 103.439 28.565 28.565 74.875 28.565 103.44 0 11.401-11.401 49.363-81.28 71.115-122.834l-51.72-51.72c-41.702 21.819-111.4 59.68-122.835 71.115zM74.981 74.981c-28.563 28.565-28.563 74.875 0 103.44 11.401 11.401 81.28 49.362 122.834 71.115l51.72-51.72c-21.818-41.702-59.68-111.4-71.115-122.834-28.564-28.566-74.875-28.566-103.439-.001zM437.019 178.421c28.563-28.565 28.563-74.875 0-103.44-28.565-28.565-74.875-28.565-103.44 0-11.401 11.401-49.363 81.28-71.115 122.834l51.72 51.72c41.702-21.818 111.4-59.679 122.835-71.114z" />
      </G>
      <G fill="#ffd7a5">
        <Path d="M152.56 171.955l-12.93-12.93c-5.355-5.355-5.355-14.039 0-19.395 5.355-5.355 14.039-5.355 19.395 0l12.93 12.93c5.355 5.355 5.355 14.039 0 19.395s-14.038 5.356-19.395 0zM352.975 372.369l-12.93-12.93c-5.355-5.355-5.355-14.039 0-19.395 5.355-5.355 14.039-5.355 19.395 0l12.93 12.93c5.355 5.355 5.355 14.039 0 19.395-5.356 5.357-14.04 5.357-19.395 0zM340.045 152.56l12.93-12.93c5.355-5.355 14.039-5.355 19.395 0 5.355 5.355 5.355 14.039 0 19.395l-12.93 12.93c-5.355 5.355-14.039 5.355-19.395 0s-5.356-14.038 0-19.395zM139.631 352.975l12.93-12.93c5.355-5.355 14.039-5.355 19.395 0 5.355 5.355 5.355 14.039 0 19.395l-12.93 12.93c-5.355 5.355-14.039 5.355-19.395 0-5.357-5.356-5.357-14.04 0-19.395z" />
      </G>
      <Path
        d="M438.857 182.857c-16.123 0-92.378 22.569-137.143 36.571h-9.143v-9.143c14.059-44.915 36.571-120.971 36.571-137.143C329.143 32.747 296.395 0 256 0s-73.143 32.747-73.143 73.143c0 16.123 22.569 92.378 36.571 137.143v9.143h-9.143C165.37 205.37 89.314 182.858 73.142 182.858 32.747 182.857 0 215.605 0 256s32.747 73.143 73.143 73.143c16.123 0 92.378-22.569 137.143-36.571h9.143v9.143c-14.059 44.915-36.571 120.971-36.571 137.143C182.857 479.253 215.605 512 256 512s73.143-32.747 73.143-73.143c0-16.123-22.569-92.378-36.571-137.143v-9.143h9.143c44.915 14.059 120.971 36.571 137.143 36.571C479.253 329.143 512 296.395 512 256s-32.747-73.143-73.143-73.143z"
        fill="#fff5dc"
      />
      <G fill="#ffe9c3">
        <Path d="M292.571 219.429v-9.143a2982.191 2982.191 0 0015.426-50.903c-15.477-8.346-33.181-13.097-51.998-13.097-18.815 0-36.514 4.75-51.99 13.095 5.33 18.2 10.832 36.242 15.418 50.905v9.143h-9.143a2982.191 2982.191 0 00-50.903-15.426c-8.346 15.477-13.097 33.181-13.097 51.998 0 18.815 4.75 36.514 13.095 51.99 18.2-5.33 36.242-10.832 50.905-15.418h9.143v9.143a2984.65 2984.65 0 00-15.426 50.902c15.477 8.347 33.181 13.098 51.998 13.098 18.815 0 36.515-4.75 51.99-13.095a3002.926 3002.926 0 00-15.418-50.905v-9.143h9.143a2982.191 2982.191 0 0050.903 15.426c8.346-15.477 13.097-33.181 13.097-51.998 0-18.815-4.75-36.514-13.095-51.99a3002.926 3002.926 0 00-50.905 15.418h-9.143zM242.286 123.429V68.571c0-7.574 6.141-13.714 13.714-13.714 7.574 0 13.714 6.141 13.714 13.714v54.857c0 7.574-6.141 13.714-13.714 13.714-7.574.001-13.714-6.14-13.714-13.713zM242.286 443.429v-54.857c0-7.574 6.141-13.714 13.714-13.714 7.574 0 13.714 6.141 13.714 13.714v54.857c0 7.574-6.141 13.714-13.714 13.714-7.574 0-13.714-6.141-13.714-13.714zM123.429 269.714H68.571c-7.574 0-13.714-6.141-13.714-13.714 0-7.574 6.141-13.714 13.714-13.714h54.857c7.574 0 13.714 6.141 13.714 13.714.001 7.574-6.14 13.714-13.713 13.714zM443.429 269.714h-54.857c-7.574 0-13.714-6.141-13.714-13.714 0-7.574 6.141-13.714 13.714-13.714h54.857c7.574 0 13.714 6.141 13.714 13.714 0 7.574-6.141 13.714-13.714 13.714z" />
      </G>
      <Path
        d="M256 164.571c-50.495 0-91.429 40.934-91.429 91.429s40.934 91.429 91.429 91.429 91.429-40.934 91.429-91.429-40.934-91.429-91.429-91.429z"
        fill="#ffdc64"
      />
      <G fill="#fff082">
        <Path d="M329.143 256a72.573 72.573 0 01-8.451 34.039c-1.469 2.776-5.136 3.443-7.358 1.223l-48.191-48.19v-54.185c0-2.825 2.562-5.087 5.331-4.53 33.455 6.718 58.669 36.21 58.669 71.643zM241.496 327.638c-11.775-2.373-22.51-7.574-31.495-14.867-2.072-1.682-2.123-4.863-.237-6.75l37.093-37.091v54.159c0 2.832-2.583 5.109-5.361 4.549zM265.143 268.929l37.093 37.093c1.887 1.887 1.835 5.067-.237 6.75-8.985 7.294-19.72 12.494-31.495 14.867-2.778.56-5.361-1.715-5.361-4.55v-54.16zM246.857 188.887v54.185l-48.191 48.191c-2.221 2.221-5.889 1.553-7.358-1.223a72.58 72.58 0 01-8.451-34.04c0-35.433 25.214-64.925 58.669-71.643 2.769-.557 5.331 1.705 5.331 4.53z" />
      </G>
    </Svg>
  )
}

export default FlowerSix
