import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function Chicken({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Chicken"
      x={0}
      y={0}
      viewBox="0 0 512.001 512.001">
      <G fill="#ebaf4b">
        <Path d="M190.985 495.747h-14.251l14.057-63.254c.976-4.381-1.79-8.722-6.171-9.694-4.373-.96-8.726 1.79-9.694 6.171l-14.839 66.778H125.97a8.128 8.128 0 00-.001 16.253h65.016a8.128 8.128 0 000-16.254zM386.033 495.747H351.92l-14.839-66.778c-.976-4.385-5.333-7.115-9.698-6.171-4.381.972-7.143 5.313-6.175 9.694l14.057 63.254h-14.247c-4.492 0-8.127 3.639-8.127 8.127s3.635 8.127 8.127 8.127h65.016c4.492 0 8.127-3.639 8.127-8.127s-3.636-8.126-8.128-8.126zM14.685 317.716c13.72 9.676 40.019 22.697 74.712 14.136l37.249-154.413s-47.679 66.06-112.841 123.049c-5.342 4.675-4.92 13.137.88 17.228zM497.317 317.716c-13.72 9.676-40.019 22.697-74.712 14.136l-37.249-154.413s47.68 66.06 112.841 123.049c5.342 4.675 4.92 13.137-.88 17.228z" />
      </G>
      <Path
        d="M385.439 142.332c.94-9.708.79-19.736-.631-29.995C376.14 49.75 322.01 1.378 258.841.031c-73.113-1.559-132.872 57.243-132.872 130.001 0 3.341.131 6.65.386 9.923 1.557 19.967-3.479 39.805-15.232 56.021-24.41 33.681-37.372 76.19-33.116 121.857 7.777 83.457 74.361 151.352 157.675 160.536 107.844 11.888 199.112-72.233 199.112-177.671 0-38.916-12.454-74.906-33.574-104.248-11.333-15.745-17.65-34.808-15.781-54.118z"
        fill="#ffdc64"
      />
      <Path
        d="M143.023 317.834c-4.255-45.667 8.706-88.177 33.116-121.858 11.753-16.216 16.79-36.054 15.232-56.021a127.747 127.747 0 01-.386-9.923c0-60.506 41.377-111.229 97.344-125.744C278.818 1.79 268.967.246 258.841.03c-73.113-1.559-132.872 57.243-132.872 130.001 0 3.341.131 6.649.386 9.923 1.558 19.967-3.479 39.805-15.232 56.021-24.41 33.681-37.372 76.19-33.116 121.858 7.777 83.457 74.362 151.352 157.675 160.536a181.53 181.53 0 0052.854-1.893c-77.539-14.136-138.119-79.3-145.513-158.642z"
        fill="#ffc850"
      />
      <Path
        d="M296.636 170.668c0 17.954-27.17 39.01-40.635 39.01-13.465 0-40.635-21.056-40.635-39.01s27.17-39.01 40.635-39.01c13.465 0 40.635 21.056 40.635 39.01z"
        fill="#eb914b"
      />
      <G fill="#4b3f4e">
        <Path d="M182.858 138.16c8.977 0 16.254-7.277 16.254-16.254v-8.127c0-8.977-7.277-16.254-16.254-16.254s-16.254 7.277-16.254 16.254v8.127c0 8.977 7.277 16.254 16.254 16.254zM329.144 138.16c8.977 0 16.254-7.277 16.254-16.254v-8.127c0-8.977-7.277-16.254-16.254-16.254s-16.254 7.277-16.254 16.254v8.127c0 8.977 7.277 16.254 16.254 16.254z" />
      </G>
      <G fill="#fff">
        <Circle cx={182.861} cy={113.781} r={8.127} />
        <Circle cx={329.141} cy={113.781} r={8.127} />
      </G>
      <Path
        d="M296.636 170.668c0-1.608-.285-3.243-.691-4.882-8.804 8.018-28.436 15.147-39.572 15.147-11.396 0-31.696-7.463-40.17-15.709-.5 1.829-.836 3.653-.836 5.444 0 17.953 27.17 39.009 40.635 39.009 13.465 0 40.634-21.056 40.634-39.009z"
        fill="#d2783c"
      />
    </Svg>
  )
}

export default Chicken