import React from 'react';
import { View, StatusBar, Platform, ImageBackground } from 'react-native';
import Constants from "expo-constants";

const BaseStyle = ({children}) => {

    return (
        <View style={{
                flex:1,
                flexDirection: 'column',
            }}
        >
            <StatusBar barStyle="light" backgroundColor="#57B5B5" />
            {Platform.OS === "ios" && <View style={{
                                                paddingTop: Constants.statusBarHeight,
                                                backgroundColor: "#57B5B5",
                                        }}></View>}

            <ImageBackground
                source={require("../../../../assets/img/background.png")}
                source={require("../../../../assets/img/background.png")}
                imageStyle={{resizeMode: 'repeat'}}
                style={{
                    flex: 1,
                    width: '100%',
                    backgroundColor: "#fae8c9"
                }}>

                <View style={{
                    flex:1,
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {children}
                </View>
            </ImageBackground>
        </View>
    );
}

export default BaseStyle;