import React from "react"
import { Svg, Path } from "react-native-svg"

function FlowerFifteen({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M456.892 287.039l-163.284-31.041 163.318-31.049c12.201-3.258 23.733-9.674 33.304-19.245 29.024-29.024 29.024-76.082 0-105.106-15.717-15.718-36.721-22.848-57.287-21.542 1.305-20.566-5.825-41.57-21.543-57.288-29.024-29.024-76.082-29.024-105.106 0-9.58 9.581-15.998 21.126-19.254 33.338L256 218.393 224.951 55.075c-3.258-12.201-9.674-23.733-19.245-33.304-29.024-29.024-76.082-29.024-105.106 0-15.716 15.717-22.848 36.72-21.541 57.287-20.566-1.306-41.569 5.824-57.287 21.542-29.024 29.024-29.024 76.082 0 105.106 9.58 9.58 21.126 15.998 33.338 19.254l163.284 31.041-163.319 31.048c-12.201 3.258-23.733 9.674-33.304 19.245-29.024 29.024-29.024 76.082 0 105.106 15.718 15.717 36.721 22.848 57.287 21.542-1.306 20.566 5.824 41.57 21.542 57.288 29.024 29.024 76.082 29.024 105.106 0 9.58-9.581 15.998-21.126 19.254-33.339L256 293.607l31.049 163.318c3.258 12.201 9.674 23.733 19.245 33.304 29.024 29.024 76.082 29.024 105.106 0 15.718-15.717 22.848-36.721 21.543-57.287 20.566 1.305 41.569-5.825 57.287-21.543 29.024-29.024 29.024-76.082 0-105.106-9.58-9.58-21.125-15.997-33.338-19.254z"
        fill="#a5eb78"
      />
      <Path
        d="M497.379 108.851c-16.1-21.457-40.563-31.309-64.437-29.793L292.717 219.283a20.524 20.524 0 01-22.666 4.335 35.078 35.078 0 00-1.918-.761c-7.223-2.625-12.872-8.346-14.307-15.896L224.951 55.075c-4.188-15.677-13.587-30.252-28.196-40.979-25.888-19.008-62.214-18.751-87.903.525-21.457 16.1-31.309 40.563-29.793 64.437l140.225 140.225a20.527 20.527 0 014.337 22.666c-.271.63-.526 1.269-.761 1.918-2.625 7.223-8.346 12.872-15.896 14.307L55.075 287.049c-15.672 4.185-30.241 13.58-40.969 28.182-19.014 25.88-18.766 62.202.499 87.895 16.099 21.472 40.57 31.331 64.454 29.815l140.225-140.225a20.526 20.526 0 0122.665-4.337c.63.271 1.269.526 1.918.761 7.223 2.625 12.872 8.346 14.307 15.896l28.876 151.886c4.185 15.672 13.58 30.241 28.182 40.969 25.88 19.013 62.202 18.766 87.895-.499 21.472-16.099 31.331-40.57 29.815-64.454L292.717 292.717a20.524 20.524 0 01-4.335-22.666c.271-.63.526-1.269.761-1.918 2.625-7.223 8.346-12.872 15.896-14.307l151.886-28.876c15.677-4.188 30.252-13.587 40.979-28.196 19.009-25.888 18.751-62.214-.525-87.903z"
        fill="#8cd76e"
      />
    </Svg>
  )
}

export default FlowerFifteen
