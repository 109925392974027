import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function LadyBug({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Ladybug"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M215.373 96.612a8.101 8.101 0 01-6.952-3.913l-48.762-80.357a8.114 8.114 0 012.73-11.159c3.841-2.333 8.841-1.111 11.159 2.73L222.31 84.27a8.114 8.114 0 01-2.73 11.159 8.066 8.066 0 01-4.207 1.183zM296.627 96.612a8.058 8.058 0 01-4.206-1.183 8.113 8.113 0 01-2.73-11.159l48.762-80.357a8.113 8.113 0 0111.159-2.73 8.113 8.113 0 012.73 11.159L303.579 92.7a8.104 8.104 0 01-6.952 3.912z"
        fill="#ada7ae"
      />
      <Path
        d="M329.143 138.16c0 40.396-32.747 73.143-73.143 73.143s-50-32.747-50-73.143c0-28.604-6.724-53.372 17.204-65.396 9.864-4.957 21.004-7.747 32.796-7.747 40.395 0 73.143 32.747 73.143 73.143z"
        fill="#5d5360"
      />
      <Path
        d="M288.508 178.795c-40.396 0-73.143-32.747-73.143-73.143 0-11.809 2.869-23.013 7.839-32.887-23.904 12.031-40.347 36.808-40.347 65.395 0 40.395 32.747 73.143 73.143 73.143 28.587 0 53.277-16.437 65.308-40.342-9.874 4.969-20.991 7.834-32.8 7.834z"
        fill="#4b3f4e"
      />
      <Path
        d="M373.5 333.207c0 68.818 22.413 128.554-34.578 158.443C314.137 504.647 285.927 512 256 512c-29.923 0-58.13-7.347-82.912-20.341-56.998-29.886-47.421-89.63-47.421-158.452 0-98.745 31.588-178.793 130.333-178.793s117.5 80.048 117.5 178.793z"
        fill="#5d5360"
      />
      <Path
        d="M434.793 333.207c0-102.526-86.297-184.897-190.217-178.438-91.029 5.658-164.246 81.208-167.268 172.362-2.366 71.366 37.119 133.762 95.781 164.527 35.035-70.234 58.139-131.83 73.166-179.978 2.981-9.552 16.51-9.552 19.491 0 15.027 48.148 38.141 109.734 73.176 179.969 56.99-29.888 95.871-89.625 95.871-158.442z"
        fill="#4b3f4e"
      />
      <Path
        d="M243.545 138.547c-30.281 1.881-58.804 10.859-83.905 25.27-55.415 31.814-88.99 90.103-93.14 156.954-4.415 71.12 30.388 127.122 83.75 164.604l13.71-11.919c55.678-114.235 78.857-203.81 87.498-245.213 1.032-4.945 8.052-4.945 9.084 0 9.048 43.351 34.034 139.513 95.575 261.499 2.199 4.359 7.841 5.943 11.836 3.137 50.235-35.281 83.094-93.622 83.094-159.672 0-111.844-94.138-201.702-207.502-194.66z"
        fill="#e5646e"
      />
      <Circle cx={190.984} cy={243.81} fill="#5d5360" r={16.254} />
      <Circle cx={142.222} cy={406.349} fill="#5d5360" r={16.254} />
      <Path
        d="M85.334 316.953c0-62.117 29.064-117.416 74.306-153.135a197.323 197.323 0 00-30.481 21.583l-41.495 49.636c-15.028 25.542-24.399 54.717-26.324 85.735-4.415 71.12 29.338 134.621 82.7 172.102 3.998 2.808 9.647 1.223 11.847-3.139a1306.445 1306.445 0 008.066-16.281c-47.697-35.559-78.619-92.435-78.619-156.501zM362.125 494.04c1.975.341 4.057.082 5.828-1.162 2.604-1.829 5.132-3.757 7.64-5.708a194.013 194.013 0 01-13.468 6.87z"
        fill="#db4655"
      />
      <Path
        d="M93.25 233.375c3.52 3.256 6.879 8.967 6.879 8.967a31.858 31.858 0 009.586 1.468c17.954 0 32.508-14.555 32.508-32.508 0-8.675-3.449-16.494-9.005-22.3-1.229-1.285-4.967-.752-4.967-.752-16.418 14.128-23.972 26.375-35.001 45.125z"
        fill="#5d5360"
      />
      <Path
        d="M362.125 494.04c1.975.341 4.057.082 5.828-1.162 2.604-1.829 5.132-3.757 7.64-5.708a194.013 194.013 0 01-13.468 6.87z"
        fill="#db4655"
      />
      <Path
        d="M129.159 185.4a197.884 197.884 0 00-41.495 49.636c3.532 3.266 7.769 5.835 12.465 7.305a196.39 196.39 0 0133.088-53.339 33.552 33.552 0 00-4.058-3.602z"
        fill="#4b3f4e"
      />
      <G fill="#5d5360">
        <Circle cx={142.222} cy={316.953} r={32.508} />
        <Circle cx={321.016} cy={243.81} r={16.254} />
        <Circle cx={369.777} cy={316.953} r={32.508} />
        <Circle cx={369.777} cy={406.349} r={16.254} />
        <Path d="M424.472 234.949a195.933 195.933 0 00-41.427-49.724c-8.008 5.921-13.267 15.353-13.267 26.077 0 17.953 14.554 32.508 32.508 32.508 8.603 0 16.371-3.404 22.186-8.861z" />
      </G>
    </Svg>
  )
}

export default LadyBug
