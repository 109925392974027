import React, { useContext, useState } from "react";
import { View, StyleSheet, ScrollView, Dimensions, Platform, ActivityIndicator, Alert } from "react-native";
import { AuthContext } from "./AuthProvider";
import DatePicker from './DatePicker/DatePicker';
import Btn from "./Btn";
import Form from "./Form";
import Input from "./Input";
import { H1, H2, Warning } from "./Title";
import { dateToFrench } from "../../tools/tools";

const ModalPepiteKid = ({data, kidName, closeModal}) => {
    const {user, updateUser} = useContext(AuthContext)

    const [warningMessage, setWarningMessage] = useState(null);
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState({
        user_param: user,
        name: data.name,
        kidId: data.kids_id,
        sentenceId: data.id,
        date: data.created_at.slice(0, 10),  // format MySQL yyyy-mm-dd
        sentence: data.content,
    })

    const handleContent = (newContent) => {
        setInputValue({
            ...inputValue,
            sentence: newContent
        });
    }
    const handleDate = (date) => {
        const formatDate = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() // format MySQL yyyy-mm-dd
        setInputValue({
            ...inputValue,
            date: formatDate
        });
    }

    const fetchDataModify = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/modifySentence.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                    if (response.success) {
                        updateUser(response.user_param)
                        closeModal();
                    } else {
                        if (response.codeError === 10) {
                            setWarningMessage("Ce prénom est déjà utilisé")
                            setTimeout(() => {setWarningMessage(false)}, 5000);
                        } else{
                            setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                            setTimeout(() => {setWarningMessage(false)}, 5000);
                        }
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error);
                    setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                    setTimeout(() => {setWarningMessage(false)}, 5000);
                    setLoading(false)
                })
            }
        else{
            setError(true)
            setLoading(false)
        }
    }

    const onSubmit = () => {
        setWarningMessage(false)
        if (inputValue.name !== '') {
            setLoading(true);
            fetchDataModify()
        } else {
            setWarningMessage("La pépite de l'enfant ne peut pas être vide")
            setTimeout(() => {setWarningMessage(false)}, 5000);
        }
    }

    const fetchDataDelete = async () => {
        setLoading(true)
        const response = await fetch('https://api.kidywoo.com/POST_request/deleteSentence.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                if (response.success) {
                    updateUser(response.user_param)
                    closeModal();
                } else {
                    setWarningMessage("Une erreur s'est produite, veuillez réessayer")
                    setTimeout(() => {setWarningMessage(false)}, 5000);
                }
                setLoading(false)
            })
        } 
        else {
            setError(true)
            setLoading(false)
        }
    }

    const createButtonAlert = () => {
        if (Platform.OS === 'web') {
            let confirm = window.confirm("Suppression de la pépite : \n\n" + 
                                        kidName+' : \n\n« '+
                                        inputValue.sentence+' » \n\n' + 
                                        dateToFrench(inputValue.date))
            if (confirm) {
                fetchDataDelete()
            }
        } else {
            Alert.alert(
                "Suppression de la pépite :",
                kidName+' : \n\n« '+
                inputValue.sentence+' » \n\n' + 
                dateToFrench(inputValue.date),
                [
                    {text: "Annuler",style: "cancel"},
                    {text: "Confirmer", onPress: () => fetchDataDelete()}
                ]
            )
        }
    }

    return (
        <View style={styles.modalView}>
            <ScrollView style={{flexGrow: 1, width: '100%', height: '100%'}} contentContainerStyle={styles.container} >
                <Form styles={{position: 'relative'}}>
                    {!loading ? (
                        <>                        
                        <H1>Modifier</H1>
    
                        {warningMessage && <Warning>{warningMessage}</Warning>}
    
                        <H2>Pépite</H2>
                        <Input value={inputValue.sentence} onInputChange={value=>{handleContent(value)}} height={120} multiline />

                        <H2>Date</H2>
                        <DatePicker onDateChange={date=>{handleDate(date)}} initDate={inputValue.date.slice(0, 10)}/>
    
                        <Btn action={onSubmit} text='MODIFIER' color='green' />
                        <Btn action={()=>{closeModal()}} text='ANNULER' color='red' />
                        <Btn action={()=>{createButtonAlert()}} text='Supprimer' color='delete' size='small' />
                        </>
                    ):(
                        <View style={{paddingTop:200}}>
                            <H1>Modifications en cours</H1>
                            <ActivityIndicator size="large" color="#57B5B5"/>
                        </View>
                    )}
                </Form>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    modalView:{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: Platform.OS === "ios" ? 30 : 10,
        marginLeft: Platform.OS === "web" ? 'auto' : 10,
        marginRight: Platform.OS === "web" ? 'auto' : 10,
        width: Platform.OS === "web" ? "50%" : 'auto',
        height: Platform.OS === "ios" ? Dimensions.get('window').height - 40 : Dimensions.get('window').height - 20,
        backgroundColor: "#FCF1DF",
        borderRadius: 20,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    container: {
        borderRadius: 20,
        alignItems: "center",
    }
})

export default ModalPepiteKid