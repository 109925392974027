import React, { useState } from 'react';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../tools/tools';

export const AuthContext = React.createContext({
    user: null,
    login: ()=>{},
    logout: ()=>{},
    updateUser: ()=>{}
});

export const AuthProvider = ({children}) => {
    // const [user, setUser] = useState({"connexion":true,"message":"Connecté","parent_id":"60","login":"Bastien","email":"bastien.duseaux@gmail.com","JWT":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiNjAiLCJsb2dpbiI6IkJhc3RpZW4ifQ.A63S2AvTbX-idb6UQbfbu_GyT-6zOIle42zuD_U5pPI","kids":[{"name":"claire","kid_id":"131","avatar":"Giraffe","gender":"boy","birthday":"2020-11-10","data":[{"content":"fghfgh","created_at":"2020-11-10 12:17:36","id":"367","kids_id":"131"},{"content":"fghfgh","created_at":"2020-11-10 12:17:32","id":"366","kids_id":"131"},{"content":"fghfgh","created_at":"2020-11-10 12:17:29","id":"365","kids_id":"131"}]}]})
    const [user, setUser] = useState(null)

    // vérification du JWT
    const verifyLocalJWT = async (localUser) =>{
        const JWT = JSON.parse(localUser).JWT
        const response = await fetch('https://api.kidywoo.com/POST_request/verifyJWT.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(JWT)
        })
        response.json()
            .then(response => {    
                if (response.Authenticated) {
                    setUser(JSON.parse(localUser))
                } else {
                    removeLocalStorage()
                        .then(setUser(null))
                }
            })
            .catch(error => {
                console.log(error)
                removeLocalStorage()
                    .then(setUser(null))
            })
    };

    return (
        <AuthContext.Provider value={{
            user,
            login: async () =>{
                const localUser = await getLocalStorage()
                    if (localUser) {
                        verifyLocalJWT(localUser)
                    } else {
                        setUser(null)
                    }
            },
            logout: () =>{
                removeLocalStorage()
                    .then(setUser(null))
            },
            updateUser: (newUser) => {
                setLocalStorage(newUser)
                    .then(setUser(newUser))
            }
        }}>
            {children}
        </AuthContext.Provider>
    );
};