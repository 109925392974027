import React from "react"
import { Svg, Path } from "react-native-svg"

function Burger() {
  return (
    <Svg 
      fill="#fff"
      viewBox="0 0 512 512"
      height='50px'
      width='50px'
    >
      <Path d="M256 0C114.618 0 0 114.618 0 256s114.618 256 256 256 256-114.618 256-256S397.382 0 256 0zm0 469.333c-117.818 0-213.333-95.515-213.333-213.333S138.182 42.667 256 42.667 469.333 138.182 469.333 256 373.818 469.333 256 469.333z" />
      <Path d="M362.667 149.333H149.333c-11.782 0-21.333 9.551-21.333 21.333 0 11.782 9.551 21.333 21.333 21.333h213.333c11.782 0 21.333-9.551 21.333-21.333.001-11.781-9.55-21.333-21.332-21.333zM362.667 234.667H149.333C137.551 234.667 128 244.218 128 256s9.551 21.333 21.333 21.333h213.333c11.782 0 21.333-9.551 21.333-21.333s-9.55-21.333-21.332-21.333zM362.667 320H149.333C137.551 320 128 329.551 128 341.333c0 11.782 9.551 21.333 21.333 21.333h213.333c11.782 0 21.333-9.551 21.333-21.333C384 329.551 374.449 320 362.667 320z" />
    </Svg>
  )
}

export default Burger
