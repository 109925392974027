import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function FlowerTwelve({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M258.391 17.884c-6.935-5-15.75-6.784-24.156-4.896a30.929 30.929 0 00-20.285 15.255c-77.447 140.655 37.864 255.038 39.036 256.175a8.84 8.84 0 006.495 2.483 8.82 8.82 0 006.28-2.992l55.782-63.437c.38-.427.715-.897 1-1.388 28.963-49.795 41.338-125.119-64.152-201.2z"
        fill="#f8ebc8"
      />
      <Path
        d="M258.893 197.9c-19.564 0-37.506 6.916-51.538 18.423 19.134 41.804 45.077 67.557 45.63 68.094a8.84 8.84 0 006.495 2.483 8.82 8.82 0 006.28-2.992l52.697-59.929c-14.853-16.003-36.005-26.079-59.564-26.079z"
        fill="#ffeb6e"
      />
      <Path
        d="M258.963 221.054c-15.803 0-30.115 6.317-40.608 16.536 16.786 29.088 34.179 46.39 34.631 46.828a8.84 8.84 0 006.495 2.483 8.82 8.82 0 006.28-2.992l37.376-42.506c-10.68-12.44-26.491-20.349-44.174-20.349z"
        fill="#ffc32d"
      />
      <Path
        d="M267.699 278.533c-1.173-1.138-116.482-115.52-39.036-256.175a30.956 30.956 0 018.391-9.696c-.941.119-1.885.117-2.819.327a30.929 30.929 0 00-20.285 15.255c-77.447 140.655 37.864 255.038 39.036 256.175a8.84 8.84 0 006.495 2.483 8.82 8.82 0 006.28-2.992l3.712-4.221c-.611-.357-1.257-.656-1.774-1.156z"
        fill="#f5e1b9"
      />
      <Path
        d="M508.511 185.303a30.906 30.906 0 00-20.009-15.604c-155.953-38.104-236.351 103.02-237.149 104.447a8.836 8.836 0 003.98 12.281l84.054 39.329c.612.289 1.259.5 1.918.642l1.465.306c9.427 1.94 19.471 3.155 29.88 3.155 47.156 0 101.542-24.932 137.435-119.956 3.021-8.005 2.447-16.97-1.574-24.6z"
        fill="#fff5dc"
      />
      <Path
        d="M251.352 274.147a8.836 8.836 0 003.98 12.281l73.406 34.346c7.253-12.168 11.495-26.339 11.495-41.534 0-27.719-13.891-52.166-35.065-66.852-35.348 29.356-53.44 61.085-53.816 61.759z"
        fill="#fff073"
      />
      <Path
        d="M251.352 274.147a8.836 8.836 0 003.98 12.281l52.464 24.548c5.931-9.124 9.409-19.987 9.409-31.68 0-21.788-11.985-40.752-29.702-50.739-23.684 23.766-35.844 45.04-36.151 45.59z"
        fill="#ffcd32"
      />
      <G fill="#f8ebc8">
        <Path d="M262.387 289.595c.798-1.427 81.196-142.552 237.149-104.447 4.03.984 7.733 2.818 10.998 5.229-.543-1.73-1.164-3.444-2.024-5.075a30.906 30.906 0 00-20.009-15.604c-155.953-38.104-236.351 103.02-237.149 104.447a8.836 8.836 0 003.98 12.281l7.02 3.285c.018-.036.015-.08.035-.116z" />
        <Path d="M261.309 269.448a8.969 8.969 0 00-6.914 1.401 8.83 8.83 0 00-3.677 6.017l-12.996 93.438a8.637 8.637 0 00-.047 2.039c4.957 52.954 36.82 117.783 160.47 125.896.647.039 1.298.061 1.944.061 7.853 0 15.466-3.121 21.126-8.729a30.928 30.928 0 009.104-23.695c-8.988-160.311-167.41-196.084-169.01-196.428z" />
      </G>
      <Path
        d="M336.609 303.295c-38.587-25.539-74.542-33.684-75.3-33.847a8.969 8.969 0 00-6.914 1.401 8.83 8.83 0 00-3.677 6.017l-11.303 81.266c6.252 1.539 12.752 2.446 19.479 2.446 36.547 0 67.457-24.106 77.715-57.283z"
        fill="#ffeb6e"
      />
      <Path
        d="M316.019 291.042c-29.897-15.959-54.09-21.461-54.711-21.594a8.969 8.969 0 00-6.914 1.401 8.83 8.83 0 00-3.677 6.017l-8.109 58.3c5.193 1.517 10.67 2.37 16.354 2.37 28.143.001 51.623-19.959 57.057-46.494z"
        fill="#ffc32d"
      />
      <Path
        d="M261.309 269.448a8.969 8.969 0 00-6.914 1.401 8.83 8.83 0 00-3.677 6.017l-1.423 10.233c.575.007 1.147-.114 1.715.004 1.599.345 160.023 36.117 169.009 196.429.142 2.536-.113 5.032-.578 7.482.583-.495 1.226-.9 1.773-1.443a30.928 30.928 0 009.104-23.695c-8.987-160.311-167.409-196.084-169.009-196.428z"
        fill="#f5e1b9"
      />
      <Path
        d="M268.068 277.267a8.847 8.847 0 00-3.552-6.263 8.85 8.85 0 00-7.03-1.535L151.728 291.43a8.843 8.843 0 00-2.406.875c-41.648 22.513-85.515 72.804-58.001 184.532 2.044 8.306 7.595 15.371 15.234 19.376a30.849 30.849 0 0014.354 3.534c3.711 0 7.435-.668 10.983-2.013 150.103-57.009 136.331-218.842 136.176-220.467z"
        fill="#f8ebc8"
      />
      <Path
        d="M257.486 269.469l-79.59 16.528c3.423 41.516 37.971 74.176 80.283 74.545 13.107-45.066 9.963-82.498 9.889-83.275a8.847 8.847 0 00-3.552-6.263 8.85 8.85 0 00-7.03-1.535z"
        fill="#ffeb6e"
      />
      <Path
        d="M264.517 271.004a8.85 8.85 0 00-7.03-1.535l-56.667 11.768c1.033 31.259 26.634 56.301 58.144 56.301 1.62 0 3.212-.113 4.798-.243 6.488-34.003 4.368-59.389 4.307-60.027a8.847 8.847 0 00-3.552-6.264z"
        fill="#ffc32d"
      />
      <Path
        d="M268.068 277.267a8.847 8.847 0 00-3.552-6.263 8.85 8.85 0 00-7.03-1.535l-10.149 2.108c.397.876.775 1.77.868 2.747.156 1.625 13.927 163.458-136.176 220.468a30.953 30.953 0 01-5.32 1.48 30.85 30.85 0 0014.2 3.476c3.711 0 7.435-.668 10.983-2.013 150.103-57.01 136.331-218.843 136.176-220.468z"
        fill="#f5e1b9"
      />
      <Path
        d="M259.481 286.901c-.116.004-.233.009-.349.009a8.837 8.837 0 01-6.146-2.492c-.731-.71-45.762-45.396-60.878-113.001-38.317-25.387-95.992-36.182-178.953 19.994C6.073 196.209 1.37 203.864.259 212.416a30.918 30.918 0 007.354 24.29c49.187 56.454 107.391 71.58 155.677 71.58 55.26 0 97.525-19.833 98.309-20.211 1.695-.817 2.908-2.244 3.783-3.877-1.57 1.599-3.648 2.614-5.901 2.703z"
        fill="#fff5dc"
      />
      <Path
        d="M259.133 286.909a8.837 8.837 0 01-6.146-2.492c-.553-.536-26.477-26.307-45.608-68.11-18.201 14.917-29.823 37.563-29.823 62.932 0 9.943 1.873 19.427 5.138 28.235 45.454-3.71 78.215-19.067 78.904-19.401 1.695-.817 2.908-2.244 3.783-3.877-1.569 1.6-3.648 2.615-5.899 2.704a8.13 8.13 0 01-.349.009z"
        fill="#fff073"
      />
      <Path
        d="M265.381 284.197c-1.569 1.6-3.648 2.615-5.899 2.704-.116.004-.233.009-.349.009a8.837 8.837 0 01-6.146-2.492c-.451-.438-17.817-17.703-34.588-46.756-10.87 10.564-17.677 25.272-17.677 41.633 0 8.991 2.097 17.473 5.736 25.076 28.464-5.05 48.736-13.457 53.861-15.719l.216-.095c.582-.259 1.002-.454 1.065-.483.889-.428 1.608-1.088 2.294-1.768a10.645 10.645 0 001.487-2.109z"
        fill="#ffcd32"
      />
      <Path
        d="M262.432 268.672c-.299.192-.511.487-.834.642-.785.38-43.049 20.211-98.309 20.211-48.286 0-106.49-15.125-155.677-71.58-2.786-3.198-4.805-6.914-6.077-10.865-.557 1.732-1.038 3.499-1.277 5.334a30.918 30.918 0 007.354 24.29c49.187 56.454 107.391 71.58 155.677 71.58 55.26 0 97.525-19.833 98.309-20.211a8.83 8.83 0 004.617-5.402 8.829 8.829 0 00-.862-7.052c-.001.002-1.064-2.652-2.921-6.947z"
        fill="#f8ebc8"
      />
      <Circle cx={258.899} cy={279.236} r={19.929} fill="#ffaa28" />
    </Svg>
  )
}

export default FlowerTwelve
