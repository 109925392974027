import React from "react"
import { Svg, Path, Ellipse, G, Circle } from "react-native-svg"

function RaccoonTwo({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Racoon"
      x={0}
      y={0}
      viewBox="0 0 512.001 512.001">
      <G fill="#8c7873">
        <Path d="M16.516 62.256s-8.258 90.839 33.032 140.387l107.355-74.323c.001.001-49.548-74.322-140.387-66.064zM494.81 62.897s8.258 90.839-33.032 140.387l-107.355-74.323c0 .001 49.549-74.322 140.387-66.064z" />
      </G>
      <Path
        d="M256.001 87.03C154.895 87.03 24.206 165.052 0 268.708c24.205 103.655 154.894 181.678 256.001 181.678s231.794-78.022 256.001-181.678C487.795 165.052 357.106 87.03 256.001 87.03z"
        fill="#aa968c"
      />
      <G fill="#fff">
        <Path d="M107.355 326.514l-41.29-66.065s74.323-90.839 148.645-33.032l-107.355 99.097zM404.646 326.514l41.29-66.065s-74.323-90.839-148.645-33.032l107.355 99.097z" />
      </G>
      <G fill="#464655">
        <Path d="M256.001 433.87c-24.774 0-140.387-24.774-181.678-123.871 0 0 30.034-84.095 110.593-93.472 24.78-2.884 46.31 17.233 46.31 42.18a111.27 111.27 0 01-49.548 92.582l74.323 82.581zM256.001 433.87c24.774 0 140.387-24.774 181.678-123.871 0 0-30.034-84.095-110.593-93.472-24.78-2.884-46.31 17.233-46.31 42.18a111.27 111.27 0 0049.548 92.582l-74.323 82.581z" />
      </G>
      <G fill="#fff">
        <Ellipse cx={256.001} cy={371.934} rx={82.581} ry={61.936} />
        <Circle cx={193.064} cy={257.322} r={19.644} />
        <Circle cx={318.937} cy={257.322} r={19.644} />
      </G>
      <Ellipse
        cx={256.001}
        cy={363.676}
        rx={41.29}
        ry={28.903}
        fill="#464655"
      />
    </Svg>
  )
}

export default RaccoonTwo
