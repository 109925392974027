import React from "react"
import { Svg, Path, G } from "react-native-svg"

function Duck({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Duck"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M466.481 329.28c0 100.915-94.235 182.72-210.476 182.72-116.252 0-210.486-81.805-210.486-182.72 0-25.153 5.854-49.114 16.445-70.922 16.81-34.604 25.707-72.448 25.393-110.909v-1.045c0-80.477 74.8-145.795 167.352-146.391.429-.01.857-.01 1.296-.01 93.137 0 168.638 65.548 168.638 146.402v1.045c-.314 38.461 8.583 76.306 25.393 110.909 10.591 21.807 16.445 45.768 16.445 70.921z"
        fill="#80b4fb"
      />
      <Path
        d="M256.008 511.998c-116.256 0-210.486-81.807-210.486-182.723 0-25.147 5.851-49.108 16.442-70.917 16.813-34.604 25.713-72.452 25.394-110.909v-1.051C87.358 65.921 162.159.607 254.71.01c.432-.01.855-.01 1.298-.01-.216 0-.423 0-.618.01-44.256.597-80.024 65.911-80.024 146.388v1.051c.154 38.457-4.1 76.305-12.136 110.909-5.069 21.809-7.871 45.771-7.871 70.917 0 100.917 45.06 182.723 100.649 182.723z"
        fill="#5986cb"
      />
      <G fill="#282528">
        <Path d="M166.338 177.348c-12.908 0-23.409 15.694-23.409 34.985s10.501 34.985 23.409 34.985c12.907 0 23.408-15.694 23.408-34.985s-10.501-34.985-23.408-34.985zM342.583 177.348c-12.908 0-23.409 15.694-23.409 34.985s10.501 34.985 23.409 34.985c12.907 0 23.408-15.694 23.408-34.985s-10.501-34.985-23.408-34.985z" />
      </G>
      <Path
        d="M362.562 335.893c-.488 6.735-2.1 12.82-4.147 16.215-18.145 30.081-47.319-4.247-102.411-4.247-12.75 0-24.113 1.837-34.274 4.294-33.739 8.146-54.201 23.074-68.145-.047-2.046-3.395-3.658-9.479-4.147-16.215l22.082-42.614 97.45-32.53 81.329 37.173z"
        fill="#ecc32e"
      />
      <Path
        d="M430.97 333.684c-31.003 31.011-80.841-24.361-174.966-24.361-30.158 0-55.775 5.681-77.678 12.386-46.458 14.23-76.229 33.049-97.296 11.975-12.734-12.735-15.509-62.014 47.621-47.621 62.481 14.255 83.73-57.201 126.047-58.654.428-.021.862-.034 1.306-.034 43.187 0 64.231 73.09 127.353 58.689 63.123-14.394 60.356 34.886 47.613 47.62z"
        fill="#f6e738"
      />
      <Path
        d="M254.699 227.409c-23.704 1.162-24.225 34.092-57.267 57.221-33.623 23.531-28.376 27.81-19.106 37.08-46.458 14.23-76.229 33.049-97.296 11.975-12.734-12.735-15.509-62.014 47.621-47.621 62.482 14.254 83.73-57.202 126.048-58.655z"
        fill="#ecc32e"
      />
      <Path
        d="M358.416 352.108c-18.145 30.081-47.319-4.247-102.411-4.247-12.75 0-24.113 1.837-34.274 4.294-3.209-45.296 111.541-13.038 136.685-.047z"
        fill="#f6e738"
      />
      <G fill="#dd6938">
        <Path d="M216.163 292.624a7.683 7.683 0 01-4.377-1.365 7.725 7.725 0 01-1.977-10.746l11.119-16.134a7.724 7.724 0 0110.746-1.977 7.725 7.725 0 011.977 10.746l-11.119 16.134a7.716 7.716 0 01-6.369 3.342zM295.84 292.624a7.718 7.718 0 01-6.369-3.343l-11.119-16.134a7.726 7.726 0 011.977-10.746 7.726 7.726 0 0110.746 1.977l11.119 16.134a7.726 7.726 0 01-1.977 10.746 7.682 7.682 0 01-4.377 1.366z" />
      </G>
      <Path
        d="M256.005 240.722c-.592 0-1.176.026-1.761.046a36.248 36.248 0 011.761-.046z"
        fill="#58b741"
      />
    </Svg>
  )
}

export default Duck
