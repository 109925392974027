import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import BoyLogo from '../../../../assets/img/BoyLogo';
import GirlLogo from '../../../../assets/img/GirlLogo';

const GenderChoice = ({onPress, gender}) =>{

    const [selected, setSelected] = useState(false)

    useEffect(()=>{
        if (gender) {
            setSelected(gender)
        }
    })

    const select = (gender) => {
         setSelected(gender)
         onPress(gender)
    }

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={()=>{select('boy')}} 
                style={ selected == 'boy' ? [styles.default, styles.selected] : styles.default }
            >
                <BoyLogo />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{select('girl')}} 
                style={ selected == 'girl' ? [styles.default, styles.selected] : styles.default }
            >
                <GirlLogo />
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
    },
    default:{
        height: 80,
        width: 80,
        borderRadius: 60,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#fff',
        borderWidth: 3
    },
    selected:{
        backgroundColor: 'rgba(255,255,255,1)',
    }
})

export default GenderChoice