import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function MouseTwo({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Mouse"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Circle cx={425.29} cy={87.211} fill="#dad8db" r={86.71} />
      <Circle cx={86.71} cy={87.211} fill="#c8c5c9" r={86.71} />
      <Circle cx={86.71} cy={87.211} fill="#ff9a9f" r={53.677} />
      <Circle cx={425.29} cy={87.211} fill="#ffb9b4" r={53.677} />
      <Path
        d="M256 83.082c-37.804 0-73.326 7.14-104.179 19.675-65.924 26.784-98.154 78.199-98.154 137.228 0 116.989 112.753 222.968 195.333 256h23.516c82.581-33.032 198.194-139.011 198.194-256 0-86.655-96.129-156.903-214.71-156.903z"
        fill="#dad8db"
      />
      <Path
        d="M74.323 223.469c0-48.534 30.154-91.943 77.519-120.724C85.911 129.527 41.29 180.953 41.29 239.985c0 116.989 115.613 222.968 198.194 256H256l16.516-16.516c-82.581-33.033-198.193-139.011-198.193-256z"
        fill="#c8c5c9"
      />
      <Path
        d="M219.047 486.071l17.987 17.987c9.922 9.922 26.008 9.922 35.929 0l17.987-17.987c5.484-5.484 1.6-14.86-6.155-14.86h-59.592c-7.756 0-11.64 9.376-6.156 14.86z"
        fill="#5d5360"
      />
      <Circle cx={177.548} cy={318.436} fill="#4b3f4e" r={20.645} />
      <Circle cx={334.452} cy={318.436} fill="#4b3f4e" r={20.645} />
      <G fill="#eceaec">
        <Path d="M165.161 405.146H57.806c-4.565 0-8.258-3.694-8.258-8.258s3.694-8.258 8.258-8.258h107.355c4.565 0 8.258 3.694 8.258 8.258s-3.693 8.258-8.258 8.258zM107.363 471.211a8.258 8.258 0 01-3.701-15.646l82.581-41.29c4.073-2.024 9.032-.387 11.081 3.694a8.258 8.258 0 01-3.694 11.081l-82.581 41.29a8.28 8.28 0 01-3.686.871zM454.194 405.146H346.839c-4.565 0-8.258-3.694-8.258-8.258s3.694-8.258 8.258-8.258h107.355c4.565 0 8.258 3.694 8.258 8.258s-3.694 8.258-8.258 8.258zM404.637 471.211a8.26 8.26 0 01-3.685-.871l-82.581-41.29a8.258 8.258 0 01-3.694-11.081c2.048-4.081 7-5.718 11.081-3.694l82.581 41.29a8.258 8.258 0 01-3.702 15.646z" />
      </G>
    </Svg>
  )
}

export default MouseTwo
