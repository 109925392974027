import React from "react"
import { Svg, Path, Circle } from "react-native-svg"

function FlowerOne({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 497 497">
      <Path
        d="M319.243 28.791a37.518 37.518 0 01-7.913-.821l-44.192 177.37h91.878V106.7c23.564-32.435.319-77.909-39.773-77.909z"
        fill="#db2116"
      />
      <Path
        d="M311.331 27.97c-6.077-1.293-11.761-4.113-16.322-8.428a67.638 67.638 0 00-93.005 0c-6.502 6.153-15.284 9.277-24.235 9.25-40.093 0-63.34 45.473-39.777 77.911v83.637l110.505 68.487 82.881-154.643c15.652-28.728 3.765-67.685-20.047-76.214z"
        fill="#ff3a2f"
      />
      <Path
        d="M310.085 154.036c0-34.014-27.574-61.588-61.587-61.587-34.014 0-61.587 27.574-61.588 61.587 0 13.576 4.392 26.126 11.833 36.306v34.242l49.754 34.242 49.758-68.482c7.44-10.179 11.83-22.733 11.83-36.308z"
        fill="#a41910"
      />
      <Path
        d="M51.582 120.458a37.503 37.503 0 01-3.226 7.272l140.766 92.205 9.615-29.592-60.769-83.635c-23.566-32.435-73.997-24.379-86.386 13.75z"
        fill="#db2116"
      />
      <Path
        d="M48.356 127.73c-3.107 5.38-7.546 9.915-13.06 12.919a67.634 67.634 0 00-28.74 88.453c3.842 8.085 4.1 17.402 1.308 25.907-12.39 38.13 23.674 74.292 61.806 61.906l79.544 25.845 99.283-83.933-121.462-126.612c-22.486-23.763-63.209-24.496-78.679-4.485z"
        fill="#ff3a2f"
      />
      <Path
        d="M167.867 167.871c-32.349-10.511-67.094 7.193-77.605 39.542s7.193 67.094 39.541 77.605c12.912 4.195 26.204 3.897 38.186-.035l32.566 10.581 47.941-36.738-49.754-68.484c-7.381-10.222-17.964-18.275-30.875-22.471z"
        fill="#a41910"
      />
      <Path
        d="M56.051 403.346a37.489 37.489 0 015.919 5.315l131.191-105.383-25.173-18.289-98.321 31.95c-38.128 12.389-46.051 62.842-13.616 86.407z"
        fill="#db2116"
      />
      <Path
        d="M61.97 408.661c4.156 4.618 7.098 10.24 8.251 16.413a67.636 67.636 0 0075.242 54.667c8.876-1.156 17.818 1.478 25.043 6.762 32.435 23.566 77.972.442 77.975-39.651l49.161-67.664-49.145-120.361-157.949 76.392C61 349.261 47.719 387.764 61.97 408.661z"
        fill="#ff3a2f"
      />
      <Path
        d="M137.078 307.404c-19.993 27.518-13.893 66.033 13.625 86.025 27.518 19.993 66.033 13.893 86.025-13.625 7.98-10.984 11.804-23.717 11.767-36.328l20.127-27.702-20.125-56.947-80.507 26.156c-12.003 3.862-22.933 11.438-30.912 22.421z"
        fill="#a41910"
      />
      <Path
        d="M326.474 486.513a37.528 37.528 0 016.884-3.987l-59.685-157.335-25.173 18.288.003 103.382c.002 40.092 45.536 63.217 77.971 39.652z"
        fill="#db2116"
      />
      <Path
        d="M333.358 482.526c5.676-2.526 11.932-3.586 18.159-2.775a67.635 67.635 0 0075.242-54.667c1.644-8.799 6.912-16.489 14.17-21.728 32.436-23.566 24.515-74.019-13.615-86.412l-49.161-67.664-129.657 9.546 23.845 173.825c4.224 32.442 36.739 56.971 61.017 49.875z"
        fill="#ff3a2f"
      />
      <Path
        d="M260.266 379.804c19.993 27.518 58.508 33.618 86.025 13.625 27.518-19.993 33.618-58.508 13.625-86.025-7.98-10.984-18.909-18.555-30.914-22.417l-20.127-27.702-60.379 1.542-.002 84.65c-.035 12.609 3.793 25.345 11.772 36.327z"
        fill="#a41910"
      />
      <Path
        d="M489.136 255.025a37.41 37.41 0 01-1.664-7.779l-168.079 8.145 9.615 29.592 98.323 31.943c38.129 12.388 74.194-23.771 61.805-61.901z"
        fill="#db2116"
      />
      <Path
        d="M487.471 247.246c-.648-6.179.277-12.456 2.972-18.128a67.638 67.638 0 00-28.74-88.453c-7.861-4.282-13.546-11.669-16.286-20.191-12.389-38.13-62.821-46.188-86.389-13.754L248.497 258.827l172.686 31.037c32.159 6.008 65.535-17.336 66.288-42.618z"
        fill="#ff3a2f"
      />
      <Path
        d="M367.19 285.018c32.349-10.511 50.052-45.256 39.541-77.605s-45.256-50.052-77.605-39.542c-12.912 4.195-23.49 12.25-30.873 22.474l-49.757 68.482 80.506 26.16c11.982 3.931 25.278 4.226 38.188.031z"
        fill="#a41910"
      />
      <Path
        d="M316.032 236.883c5.066-7.931 5.075-18.475-.791-26.549s-15.897-11.323-25.005-8.956c-.563-9.394-6.754-17.93-16.245-21.014s-19.517.183-25.494 7.452c-5.977-7.269-16.003-10.536-25.494-7.452s-15.682 11.62-16.245 21.014c-9.108-2.367-19.139.882-25.005 8.956s-5.857 18.618-.791 26.549c-8.76 3.439-14.965 11.964-14.965 21.943 0 9.98 6.205 18.505 14.965 21.943-5.066 7.931-5.075 18.475.791 26.549s15.897 11.323 25.005 8.956c.563 9.394 6.753 17.93 16.245 21.014 9.491 3.084 19.517-.183 25.494-7.452 5.977 7.269 16.003 10.536 25.494 7.452s15.682-11.62 16.245-21.014c9.108 2.367 19.139-.882 25.005-8.956s5.857-18.618.791-26.549c8.76-3.439 14.965-11.964 14.965-21.943s-6.205-18.504-14.965-21.943z"
        fill="#ffd185"
      />
      <Circle cx={248.497} cy={258.827} fill="#ffba48" r={45} />
      <Circle cx={248.497} cy={258.827} fill="#ff7a3c" r={15} />
    </Svg>
  )
}

export default FlowerOne
