import React from "react"
import { Svg, Path, G } from "react-native-svg"

function Hedgehog({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Hedgehog"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <G fill="#f1b986">
        <Path d="M92.911 421.127v55.106H76.098c-9.285 0-16.812 7.527-16.812 16.812s7.527 16.813 16.812 16.813H122.385c13.916 0 25.197-11.281 25.197-25.197v-63.533H92.911zM419.089 421.127v55.106h16.812c9.285 0 16.812 7.527 16.812 16.812s-7.527 16.813-16.812 16.813H389.614c-13.916 0-25.197-11.281-25.197-25.197v-63.533h54.672z" />
      </G>
      <Path
        d="M512 253.847c0 141.388-114.612 256.01-256 256.01-24.746 0-48.678-3.513-71.312-10.065C77.998 468.906 0 370.489 0 253.847c0-53.468 16.391-103.105 44.434-144.169V195S85.272 60.711 154.472 18.781l-8.324 67.758c.587-1.968 19.729-65.09 88.434-84.396L256 76.092l21.418-73.949c68.706 19.306 87.847 82.428 88.434 84.396l-8.324-67.758C426.728 60.711 467.566 195 467.566 195v-85.323C495.609 150.742 512 200.378 512 253.847z"
        fill="#c17d4f"
      />
      <Path
        d="M144.715 288.483c13.187 52.5 28.887 143.057 39.973 211.309C77.998 468.906 0 370.489 0 253.847c0-53.468 16.391-103.105 44.434-144.169V195S85.272 60.711 154.472 18.781l-8.324 67.758c.587-1.968 19.729-65.09 88.434-84.396-44.124 44.125-73.465 131.683-66.707 173.294 0 0-22.768-18.781-14.742-53.664-.701 1.679-31.299 75.566-8.418 166.71z"
        fill="#985e33"
      />
      <Path
        d="M411.66 287.513v109.364c0 62.398-50.592 112.98-112.98 112.98h-85.36c-62.388 0-112.98-50.582-112.98-112.98V287.513s25.837-.535 36.932 35.727l5.007-23.344 2.421-11.321.021-.093 15.144-70.65s55.012 24.137 53.858 70.351c1.504-14.021 8.623-51.798 42.279-85.453 33.656 33.656 40.775 71.433 42.279 85.453-1.154-46.214 53.858-70.351 53.858-70.351l22.592 105.408c13.369-35.479 36.929-35.727 36.929-35.727z"
        fill="#f1d0a5"
      />
      <Path
        d="M245.43 509.857h-32.112c-62.39 0-112.974-50.584-112.974-112.984V287.514s25.838-.536 36.923 35.728l5.007-23.345c-7.727 41.044-24.437 180.237 97.129 208.497 2.071.485 4.08.969 6.027 1.463z"
        fill="#f1b986"
      />
      <G fill="#282528">
        <Path d="M167.874 345.688c-12.908 0-23.41 15.695-23.41 34.986s10.502 34.986 23.41 34.986 23.409-15.695 23.409-34.986-10.502-34.986-23.409-34.986zM344.126 345.688c-12.908 0-23.41 15.695-23.41 34.986s10.502 34.986 23.41 34.986 23.409-15.695 23.409-34.986-10.501-34.986-23.409-34.986z" />
      </G>
      <Path
        d="M263.726 430.523v79.334h-15.453v-79.334a7.729 7.729 0 017.726-7.726 7.729 7.729 0 017.727 7.726z"
        fill="#f1b986"
      />
      <Path
        d="M267.445 405.016h-22.89c-14.088 0-25.508 11.421-25.508 25.508 0 14.088 11.421 25.508 25.508 25.508h22.89c14.088 0 25.508-11.421 25.508-25.508s-11.42-25.508-25.508-25.508z"
        fill="#e4936b"
      />
      <Path
        d="M252.28 448.563c4.306 4.296 10.127 7.067 16.607 7.428-.474.031-.958.041-1.442.041h-22.891c-7.036 0-13.423-2.854-18.038-7.469s-7.469-10.992-7.469-18.038c0-14.082 11.425-25.507 25.507-25.507h22.891c.484 0 .968.01 1.442.041-13.413.742-24.075 11.868-24.075 25.466a25.427 25.427 0 007.468 18.038z"
        fill="#dd6938"
      />
    </Svg>
  )
}

export default Hedgehog
