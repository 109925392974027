import * as React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"


function Unicorn({size}) {
  return (
    <Svg
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Cat"
      x={0}
      y={0}
      viewBox="0 0 512 512"
    >
      <Path
        d="M478.939 174.89l16.235-7.793c4.97-2.386 6.288-8.819 2.619-12.934-12.74-14.289-42.434-43.693-84.976-61.926l12.219-8.146c5.567-3.712 4.699-12.161-1.543-14.571-38.065-14.696-145.34-42.933-225.573 64.044l250.89 198.071c25.585 20.199 63.19 1.975 63.19-30.622v-30.085c0-62.976-33.061-96.038-33.061-96.038z"
        fill="#e6646e"
      />
      <Path
        d="M487.204 268.502c-13.616-32.143-19.023-41.704-32.176-62.5-20.636-32.624-75.45-119.286-155.814-119.286-10.396 0-20.942 1.47-31.345 4.37-13.873 3.867-27.122 11.155-39.381 21.662l-26.653 23.896c-.004.003-.003.007 0 .01l236.428 170.481c32.411 24.796 61.597-8.755 48.941-38.633z"
        fill="#dc4655"
      />
      <Path
        d="M6.439 10.182l174.95 197.769 57.857-66.122L10.896 4.82c-3.459-2.076-7.13 2.341-4.457 5.362z"
        fill="#e6646e"
      />
      <Path
        d="M508.74 367.249c-9.777-29.807-43.728-114.538-67.683-152.41-26.762-42.312-86.097-130.867-168.75-107.83-11.54 3.216-22.63 9.348-33.061 18.289L4.462 335.794c-3.308 2.966-5.089 7.459-4.26 11.823 1.914 10.084 7.814 14.771 14.066 16.817 2.724.891 4.967 2.77 6.239 5.337l8.851 17.87c4.14 8.439 14.439 11.783 22.688 7.277 3.046-1.663 6.381-3.701 9.805-5.88l28.852 17.58c4.944 2.995 11.038 3.215 16.161.539 24.73-12.909 100.81-50.758 157.178-58.696 11.754-1.656 22.677-4.792 32.789-8.956l62.06 162.885c2.079 5.455 9.005 7.095 13.308 3.15l131.99-120.991c4.788-4.388 6.575-11.128 4.551-17.3z"
        fill="#edebed"
      />
      <G fill="#dbd9dc">
        <Path d="M266.624 34.47v82.431c0 9.001 7.206 16.682 17.037 18.162l19.236 2.895c13.163 1.981 24.966-7.86 23.693-19.855-5.785-54.492-31.155-79.217-46.985-89.518-5.43-3.533-12.981-.159-12.981 5.885zM349.596 335.341c-3.21-5.733-2.642-12.767 1.286-18.034 4.397-5.895 8.154-12.198 11.156-18.873 12.148-27.007 11.954-57.344-.557-87.734-1.735-4.221-6.546-6.231-10.792-4.496-4.221 1.739-6.231 6.57-4.496 10.792 10.743 26.079 11.01 51.896.767 74.658-9.325 20.733-27.092 37.58-50.159 47.775l62.088 162.962c2.079 5.456 9.005 7.095 13.309 3.15l48.045-44.041-70.647-126.159z" />
      </G>
      <Path
        d="M37.435 350.7c-2.488-5.058-10.656-5.447-16.186 2.238-2.7 3.752-5.069 7.289-12.551 8.717a21.498 21.498 0 005.472 2.74c2.058.682 3.69 2.08 4.991 3.765 15.863-1.274 21.13-11.654 18.274-17.46z"
        fill="#9c969e"
      />
      <Path
        d="M78.17 399.072c14.112-8.872 29.894-20.636 42.783-33.645 3.215-3.241 3.189-8.475-.048-11.688a8.261 8.261 0 00-11.692.052c-14.293 14.42-34.067 26.698-47.531 35.353l16.488 9.928z"
        fill="#c0bcc1"
      />
      <Path
        d="M239.246 224.583c-11.817 0-22.859-3.814-30.301-10.469a8.27 8.27 0 01-.662-11.671c3.051-3.402 8.281-3.689 11.671-.654 4.391 3.923 11.599 6.264 19.291 6.264s14.9-2.341 19.291-6.264c3.39-3.035 8.62-2.748 11.671.654 3.035 3.406 2.744 8.629-.662 11.671-7.441 6.655-18.483 10.469-30.299 10.469z"
        fill="#4b3f4e"
      />
      <Path
        fill="#00c3ff"
        d="M123.532 142.548l41.326 46.717V97.197l-41.326-24.796z"
      />
      <Path
        fill="#d4f582"
        d="M82.205 95.831l41.327 46.717V72.401L82.205 47.605z"
      />
      <Path
        fill="#ffc850"
        d="M40.879 49.114l41.326 46.717V47.605L40.879 22.809z"
      />
      <Path
        d="M40.879 22.809L10.896 4.82c-3.459-2.075-7.13 2.341-4.457 5.363l34.439 38.932V22.809h.001z"
        fill="#e6646e"
      />
    </Svg>
  )
}

export default Unicorn
