import React, { useContext } from 'react';
import { ScrollView, Text, View, TouchableOpacity } from 'react-native';
import { AuthContext } from '../../modules/AuthProvider';

import BaseStyle from '../../modules/BaseStyle';
import { H1 } from '../../modules/Title';
import { DynamicAvatarComponentMenu } from '../../modules/DynamicAvatarComponent';
import MenuBurger from '../../modules/MenuBurger';
import LogoLong from '../../../../../assets/img/LogoLong';
import Btn from '../../modules/Btn';

import styles from "./Home.style"

const Home = ({navigation}) => {
    const {user} = useContext(AuthContext)
    
    return (
        <BaseStyle>
                <MenuBurger navigation={navigation} />

                <View style={styles.container}>
                    {user.kids ?
                        <ScrollView contentContainerStyle={{paddingTop: 50}}>
                            <H1>Bonjour {user.login}</H1>
                            <View style={styles.kidList}>
                                {user.kids.map((kid, i) => (
                                    <TouchableOpacity key={i} onPress={()=>{navigation.navigate('Kid', {name: kid.name})}}> 
                                        <View style={styles.kidCard}>
                                            <DynamicAvatarComponentMenu size={{width: 120, height: 120}} tag={kid.avatar} />
                                            <H1>{kid.name}</H1>
                                            <Text style={styles.basicLink}>{kid.data.length > 0 ? `${kid.data.length} pépites` : '0 pépite'}</Text>
                                        </View>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView> 
                    : 
                        <View style={styles.welcomeTextContainer}>
                            <LogoLong />
                            <Text style={styles.welcomeText}>
                                Bienvenue {user.login} ! {"\n"}{"\n"}
                                Nous sommes heureux de vous compter parmi nous et honorés de votre confiance. {"\n"}{"\n"}
                                Pour commencer, cliquez sur le bouton ci-dessous pour ajouter un premier enfant à votre tribu :)
                            </Text>

                            <Btn text='AJOUTER UN ENFANT' color='green' action={()=>{navigation.navigate('AddKid')}} />
                        </View>
                    }
                </View>
        </BaseStyle>
    )
}

export default Home