import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function FlowerSeven({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 497 497">
      <Path
        d="M153.403 18.916l35.531 189.314 73.21-30.324-53.265-128.594a40.62 40.62 0 00-2.463-8.437c-8.575-20.703-32.31-30.535-53.013-21.959z"
        fill="#eb1d10"
      />
      <Path
        d="M178.7 52.355c-8.575-20.703-19.901-35.674-25.297-33.439-20.703 8.575-30.534 32.31-21.959 53.013a40.606 40.606 0 004.225 7.707l53.265 128.593 39.479-16.353-46.599-131.353a153.082 153.082 0 00-3.114-8.168z"
        fill="#ff372b"
      />
      <Path
        d="M18.916 153.403l158.989 108.741 30.324-73.21-128.592-53.265a40.724 40.724 0 00-7.707-4.225c-20.704-8.575-44.439 1.256-53.014 21.959z"
        fill="#eb1d10"
      />
      <Path
        d="M60.449 159.161c-20.703-8.575-39.298-11.153-41.533-5.758-8.575 20.703 1.256 44.438 21.959 53.013a40.777 40.777 0 008.437 2.463l128.593 53.265 16.353-39.479-125.832-59.931a153.008 153.008 0 00-7.977-3.573z"
        fill="#ff372b"
      />
      <Path
        d="M18.916 343.597l189.314-35.531-30.324-73.21-128.594 53.265a40.62 40.62 0 00-8.437 2.463c-20.703 8.575-30.535 32.31-21.959 53.013z"
        fill="#eb1d10"
      />
      <Path
        d="M52.355 318.3c-20.703 8.575-35.674 19.901-33.439 25.297 8.575 20.703 32.31 30.534 53.013 21.959a40.606 40.606 0 007.707-4.225l128.593-53.265-16.353-39.479-131.353 46.599a153.947 153.947 0 00-8.168 3.114z"
        fill="#ff372b"
      />
      <Path
        d="M153.403 478.084l108.741-158.989-73.21-30.324-53.265 128.593a40.724 40.724 0 00-4.225 7.707c-8.575 20.703 1.256 44.438 21.959 53.013z"
        fill="#eb1d10"
      />
      <Path
        d="M159.161 436.551c-8.575 20.703-11.153 39.298-5.758 41.533 20.703 8.575 44.438-1.256 53.013-21.959a40.777 40.777 0 002.463-8.437l53.265-128.593-39.479-16.353-59.931 125.831a153.425 153.425 0 00-3.573 7.978z"
        fill="#ff372b"
      />
      <Path
        d="M343.597 478.084L308.066 288.77l-73.21 30.324 53.265 128.593a40.62 40.62 0 002.463 8.437c8.575 20.704 32.31 30.536 53.013 21.96z"
        fill="#eb1d10"
      />
      <Path
        d="M318.3 444.645c8.575 20.703 19.901 35.674 25.297 33.439 20.703-8.575 30.534-32.31 21.959-53.013a40.606 40.606 0 00-4.225-7.707l-53.265-128.593-39.479 16.353 46.599 131.354a154.252 154.252 0 003.114 8.167z"
        fill="#ff372b"
      />
      <Path
        d="M478.084 343.597L319.095 234.856l-30.324 73.21 128.593 53.265a40.724 40.724 0 007.707 4.225c20.703 8.575 44.438-1.256 53.013-21.959z"
        fill="#eb1d10"
      />
      <Path
        d="M436.551 337.839c20.703 8.575 39.298 11.153 41.533 5.758 8.575-20.703-1.256-44.438-21.959-53.013a40.777 40.777 0 00-8.437-2.463l-128.593-53.265-16.353 39.479 125.831 59.931a153.425 153.425 0 007.978 3.573z"
        fill="#ff372b"
      />
      <Path
        d="M478.084 153.403L288.77 188.934l30.324 73.21 128.593-53.265a40.62 40.62 0 008.437-2.463c20.704-8.575 30.536-32.31 21.96-53.013z"
        fill="#eb1d10"
      />
      <Path
        d="M444.645 178.7c20.703-8.575 35.674-19.901 33.439-25.297-8.575-20.703-32.31-30.534-53.013-21.959a40.606 40.606 0 00-7.707 4.225l-128.593 53.265 16.353 39.479 131.354-46.599a153.384 153.384 0 008.167-3.114z"
        fill="#ff372b"
      />
      <Path
        d="M343.597 18.916L234.856 177.905l73.21 30.324 53.265-128.593a40.724 40.724 0 004.225-7.707c8.575-20.703-1.256-44.438-21.959-53.013z"
        fill="#eb1d10"
      />
      <Path
        d="M337.839 60.449c8.575-20.703 11.153-39.298 5.758-41.533-20.703-8.575-44.438 1.256-53.013 21.959a40.777 40.777 0 00-2.463 8.437l-53.265 128.593 39.479 16.353 59.931-125.831a153.425 153.425 0 003.573-7.978z"
        fill="#ff372b"
      />
      <Path
        d="M248.5 0v248.5l39.621-199.188c.618-2.815.954-5.737.954-8.738C289.075 18.166 270.909 0 248.5 0z"
        fill="#ff5a35"
      />
      <Path
        d="M259.075 40.575C259.075 18.166 254.34 0 248.5 0c-22.409 0-40.575 18.166-40.575 40.575 0 3.001.336 5.922.954 8.738L248.5 248.5l10.326-199.188c.161-2.815.249-5.736.249-8.737z"
        fill="#ff7a3c"
      />
      <Path
        d="M72.784 72.784L248.5 248.5 135.669 79.637a40.704 40.704 0 00-5.504-6.853c-15.845-15.845-41.536-15.845-57.381 0z"
        fill="#ff5a35"
      />
      <Path
        d="M108.952 93.997C93.107 78.152 76.914 68.654 72.784 72.784c-15.845 15.845-15.845 41.536 0 57.381a40.704 40.704 0 006.853 5.504L248.5 248.5 114.955 100.351a153.677 153.677 0 00-6.003-6.354z"
        fill="#ff7a3c"
      />
      <Path
        d="M0 248.5h248.5L49.312 208.879a40.702 40.702 0 00-8.738-.954C18.166 207.925 0 226.091 0 248.5z"
        fill="#ff5a35"
      />
      <Path
        d="M40.575 237.925C18.166 237.925 0 242.66 0 248.5c0 22.409 18.166 40.575 40.575 40.575a40.71 40.71 0 008.738-.954L248.5 248.5 49.312 238.174a153.187 153.187 0 00-8.737-.249z"
        fill="#ff7a3c"
      />
      <Path
        d="M72.784 424.216L248.5 248.5 79.637 361.331a40.704 40.704 0 00-6.853 5.504c-15.845 15.845-15.845 41.536 0 57.381z"
        fill="#ff5a35"
      />
      <Path
        d="M93.997 388.048c-15.845 15.845-25.343 32.038-21.213 36.168 15.845 15.845 41.536 15.845 57.381 0a40.704 40.704 0 005.504-6.853L248.5 248.5 100.351 382.045a153.677 153.677 0 00-6.354 6.003z"
        fill="#ff7a3c"
      />
      <Path
        d="M248.5 497V248.5l-39.621 199.188a40.702 40.702 0 00-.954 8.738c0 22.408 18.166 40.574 40.575 40.574z"
        fill="#ff5a35"
      />
      <Path
        d="M237.925 456.425c0 22.409 4.734 40.575 10.575 40.575 22.409 0 40.575-18.166 40.575-40.575a40.71 40.71 0 00-.954-8.738L248.5 248.5l-10.326 199.188a153.187 153.187 0 00-.249 8.737z"
        fill="#ff7a3c"
      />
      <Path
        d="M424.216 424.216L248.5 248.5l112.831 168.863a40.704 40.704 0 005.504 6.853c15.845 15.845 41.536 15.845 57.381 0z"
        fill="#ff5a35"
      />
      <Path
        d="M388.048 403.003c15.845 15.845 32.038 25.343 36.168 21.213 15.845-15.845 15.845-41.536 0-57.381a40.704 40.704 0 00-6.853-5.504L248.5 248.5l133.545 148.149a153.677 153.677 0 006.003 6.354z"
        fill="#ff7a3c"
      />
      <Path
        d="M497 248.5H248.5l199.188 39.621c2.815.618 5.737.954 8.738.954 22.408 0 40.574-18.166 40.574-40.575z"
        fill="#ff5a35"
      />
      <Path
        d="M456.425 259.075c22.409 0 40.575-4.734 40.575-10.575 0-22.409-18.166-40.575-40.575-40.575a40.71 40.71 0 00-8.738.954L248.5 248.5l199.188 10.326c2.815.161 5.736.249 8.737.249z"
        fill="#ff7a3c"
      />
      <Path
        d="M424.216 72.784L248.5 248.5l168.863-112.831a40.704 40.704 0 006.853-5.504c15.845-15.845 15.845-41.536 0-57.381z"
        fill="#ff5a35"
      />
      <Path
        d="M403.003 108.952c15.845-15.845 25.343-32.038 21.213-36.168-15.845-15.845-41.536-15.845-57.381 0a40.704 40.704 0 00-5.504 6.853L248.5 248.5l148.149-133.545a153.677 153.677 0 006.354-6.003z"
        fill="#ff7a3c"
      />
      <Path
        d="M368.57 225.07c-1.25 0-2.47.1-3.67.29l-17.73-42.805a23.372 23.372 0 002.8-2.39c9.15-9.15 9.15-23.985 0-33.135s-23.985-9.15-33.135 0a23.372 23.372 0 00-2.39 2.8L271.64 132.1c.19-1.2.29-2.42.29-3.67 0-12.94-10.49-23.43-23.43-23.43s-23.43 10.49-23.43 23.43c0 1.25.1 2.47.29 3.67l-42.805 17.73a23.294 23.294 0 00-2.39-2.8c-9.15-9.15-23.985-9.15-33.135 0s-9.15 23.985 0 33.135a23.372 23.372 0 002.8 2.39L132.1 225.36a23.4 23.4 0 00-3.67-.29c-12.94 0-23.43 10.49-23.43 23.43s10.49 23.43 23.43 23.43c1.25 0 2.47-.1 3.67-.29l17.73 42.805a23.372 23.372 0 00-2.8 2.39c-9.15 9.15-9.15 23.985 0 33.135s23.985 9.15 33.135 0a23.372 23.372 0 002.39-2.8l42.805 17.73a23.4 23.4 0 00-.29 3.67c0 12.94 10.49 23.43 23.43 23.43s23.43-10.49 23.43-23.43c0-1.25-.1-2.47-.29-3.67l42.805-17.73a23.294 23.294 0 002.39 2.8c9.15 9.15 23.985 9.15 33.135 0s9.15-23.985 0-33.135a23.372 23.372 0 00-2.8-2.39l17.73-42.805c1.2.19 2.42.29 3.67.29 12.94 0 23.43-10.49 23.43-23.43s-10.49-23.43-23.43-23.43z"
        fill="#ff9a42"
      />
      <G fill="#ffba48">
        <Path d="M193.585 115.923c-11.955 4.952-17.632 18.658-12.68 30.613a23.44 23.44 0 001.672 3.28l20.1 30.077 29.708-12.316-7.051-35.472a23.352 23.352 0 00-1.137-3.502c-4.951-11.954-18.657-17.632-30.612-12.68zM115.923 193.585c-4.952 11.955.725 25.661 12.68 30.613 1.155.478 2.32.853 3.502 1.137l35.481 7.055 12.298-29.716-30.068-20.096a23.334 23.334 0 00-3.28-1.672c-11.955-4.953-25.661.724-30.613 12.679zM115.923 303.415c4.952 11.955 18.658 17.632 30.613 12.68a23.44 23.44 0 003.28-1.672l30.077-20.1-12.316-29.708-35.472 7.051a23.352 23.352 0 00-3.502 1.137c-11.954 4.951-17.631 18.657-12.68 30.612zM193.585 381.077c11.955 4.952 25.661-.725 30.613-12.68.478-1.155.853-2.32 1.137-3.502l7.055-35.481-29.716-12.298-20.096 30.068a23.334 23.334 0 00-1.672 3.28c-4.953 11.955.724 25.661 12.679 30.613zM303.415 381.077c11.955-4.952 17.632-18.658 12.68-30.613a23.44 23.44 0 00-1.672-3.28l-20.1-30.077-29.708 12.316 7.051 35.472a23.352 23.352 0 001.137 3.502c4.951 11.954 18.657 17.632 30.612 12.68zM381.077 303.415c4.952-11.955-.725-25.661-12.68-30.613a23.467 23.467 0 00-3.502-1.137l-35.481-7.055-12.298 29.716 30.068 20.096a23.334 23.334 0 003.28 1.672c11.955 4.953 25.661-.724 30.613-12.679zM381.077 193.585c-4.952-11.955-18.658-17.632-30.613-12.68a23.44 23.44 0 00-3.28 1.672l-30.077 20.1 12.316 29.708 35.472-7.051a23.352 23.352 0 003.502-1.137c11.955-4.951 17.632-18.657 12.68-30.612zM303.415 115.923c-11.955-4.952-25.661.725-30.613 12.68a23.467 23.467 0 00-1.137 3.502l-7.055 35.481 29.716 12.298 20.096-30.068a23.334 23.334 0 001.672-3.28c4.953-11.955-.724-25.661-12.679-30.613z" />
      </G>
      <Circle cx={248.5} cy={248.5} fill="#7d3214" r={82.5} />
      <Path
        d="M301 248.5c0-7.367-3.559-13.919-9.048-18.026.962-6.772-1.13-13.899-6.328-19.097s-12.325-7.291-19.098-6.329C262.419 199.559 255.867 196 248.5 196s-13.919 3.559-18.026 9.048c-6.772-.962-13.899 1.13-19.098 6.329s-7.291 12.325-6.328 19.097C199.559 234.581 196 241.133 196 248.5s3.559 13.919 9.048 18.026c-.962 6.772 1.13 13.899 6.328 19.097s12.325 7.291 19.098 6.329c4.107 5.489 10.658 9.048 18.026 9.048 7.367 0 13.919-3.559 18.026-9.048 6.772.962 13.899-1.13 19.098-6.329s7.291-12.325 6.328-19.097c5.489-4.107 9.048-10.659 9.048-18.026z"
        fill="#c24623"
      />
      <Circle cx={248.5} cy={248.5} fill="#ff5a35" r={15} />
    </Svg>
  )
}

export default FlowerSeven
