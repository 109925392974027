import React, { useState } from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';
import './DatePicker.css';


const WEEKDAYS_LONG = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
const MONTHS = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear, 11);

const YearMonthForm = ({ date, localeUtils, onChange }) => {
    const months = localeUtils.getMonths();
    const years = [];
    for (let i = fromMonth.getFullYear()-20; i <= toMonth.getFullYear(); i += 1) {
        years.push(i);
    }

    const handleChange = e => {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <form className="DayPicker-Caption">
            <select name="month" onChange={handleChange} value={date.getMonth()}>
                {months.map((month, i) => (
                <option key={month} value={i}>
                    {MONTHS[i]}
                </option>
                ))}
            </select>
            <select name="year" onChange={handleChange} value={date.getFullYear()}>
                {years.map(year => (
                <option key={year} value={year}>
                    {year}
                </option>
                ))}
            </select>
        </form>
    );
}

const DatePicker = ({onDateChange, initDate}) => {
    let init_date
    if (initDate) {
        init_date = new Date(initDate)
    } else {
        init_date = new Date()
    }

    const [selectedDay, setSelectedDay] = useState(`${WEEKDAYS_LONG[init_date.getDay()]} ${init_date.getDate()} ${MONTHS[init_date.getMonth()]} ${init_date.getFullYear()}`)
    const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ven', 'Sa']
    const [month, setMonth] = useState('')

    const handleDayChange = date => {
        setSelectedDay(`${WEEKDAYS_LONG[date.getDay()]} ${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`)
        onDateChange(date)
    }

    const handleYearMonthChange = month => {
        setMonth(month)
    }

    return (
        <div className="datePicker">
            <DayPickerInput
                // showOverlay
                onKeyDown={e=>e.preventDefault()}
                inputProps = {{readOnly: true}}
                keepFocus={false}
                onDayChange = {handleDayChange}
                value = {selectedDay}
                placeholder = {"Ajouter"}
                dayPickerProps={{
                    captionElement:({date, localeUtils}) => (
                        <YearMonthForm
                            date={date}
                            localeUtils={localeUtils}
                            onChange={handleYearMonthChange}
                        />
                    ),
                    months:MONTHS,
                    weekdaysShort:WEEKDAYS_SHORT,
                    firstDayOfWeek:1,
                    month:month,
                }}
            />
        </div>
    )
}

export default DatePicker;