import React, { useContext, useState } from "react";
import { Modal, View, StyleSheet, TouchableOpacity, ScrollView, Dimensions, Platform, ActivityIndicator } from "react-native";
import { AuthContext } from "./AuthProvider";
import DatePicker from './DatePicker/DatePicker';
import GenderChoice from "./GenderChoice";
import ModalAvatar from "./ModalAvatar";
import Btn from "./Btn";
import Form from "./Form";
import Input from "./Input";
import { H1, H2, H3, Warning } from "./Title";
import DynamicAvatarComponent from "./DynamicAvatarComponent";

const ModalModifyKid = ({data, closeModal, navigation}) => {
    const {updateUser} = useContext(AuthContext)

    const [warningMessage, setWarningMessage] = useState(null);
    const [loading, setLoading] = useState(false)
    const [modalAvatarVisible, setModalAvatarVisible] = useState(false);
    const [inputValue, setInputValue] = useState({
        user_param: data.user_param,
        kidId: data.kid.kid_id,
        name: data.kid.name,
        gender: data.kid.gender,
        birthday: data.kid.birthday,
        avatar: data.kid.avatar
    })

    const handleName = (name) => {
        setInputValue({
            ...inputValue,
            name: name
        });
    }
    const handleDate = (date) => {
        setInputValue({
            ...inputValue,
            birthday: date.toJSON().slice(0, 10) // format MySQL yyyy-mm-dd
        });
    }
    const handleGender = (gender) => {
        setInputValue({
            ...inputValue,
            gender: gender
        });
    }
    const handleAvatar = (avatar) => {
        setModalAvatarVisible(false)
        setInputValue({
            ...inputValue,
            avatar: avatar.name
        });
    }

    const fetchDataModify = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/modifyProfil.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                    if (response.success) {
                        closeModal();
                        updateUser(response.user_param)
                        navigation.navigate('Kid', {name: inputValue.name})
                    } else {
                        if (response.codeError === 10) {
                            setWarningMessage("Ce prénom est déjà utilisé")
                            setTimeout(() => {setWarningMessage(false)}, 5000);
                        } else{
                            setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                            setTimeout(() => {setWarningMessage(false)}, 5000);
                        }
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error);
                    setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                    setTimeout(() => {setWarningMessage(false)}, 5000);
                    setLoading(false)
                })
            }
        else{
            setError(true)
            setLoading(false)
        }
    }

    const onSubmit = () => {
        setWarningMessage(false)
        if (inputValue.name !== '') {
            setLoading(true);
            fetchDataModify()
        } else {
            setWarningMessage("Le prénom de l'enfant ne peut pas être vide")
            setTimeout(() => {setWarningMessage(false)}, 5000);
        }
    }

    return (
        <View style={styles.modalView}>
            <ScrollView style={{flexGrow: 1, width: '100%', height: '100%'}} contentContainerStyle={styles.container} >
                <Form styles={{position: 'relative'}}>
                    {!loading ? (
                        <>
                        <H1>Modifier</H1>
    
                        {warningMessage && <Warning>{warningMessage}</Warning>}
    
                        <H2>Prénom</H2>
                        <Input value={inputValue.name} onInputChange={handleName} />
                        <H2>Date de naissance</H2>
                        <DatePicker onDateChange={handleDate} initDate={inputValue.birthday}/>
    
                        <H2>Genre *</H2>
                        <GenderChoice onPress={handleGender} gender={inputValue.gender} />
    
                        <H2>Avatar</H2>
                        <TouchableOpacity style={styles.avatarBtn} onPress={()=>{setModalAvatarVisible(true)}}>
                            <DynamicAvatarComponent onPressAvatar={handleAvatar} tag={inputValue.avatar} />
                        </TouchableOpacity>
    
                        <Modal
                            style={styles.modalAvatar}
                            visible={modalAvatarVisible}
                            animationType="fade"
                            transparent={true}
                        >
                            <ModalAvatar onPressAvatar={handleAvatar}/>
                        </Modal>
    
                        <Btn action={onSubmit} text='MODIFIER' color='green' />
                        <Btn action={()=>{closeModal()}} text='ANNULER' color='red' />
    
                        <H3 style={{position: 'absolute', bottom: 0, left: 0}}>* facultatif</H3>
                        </>
                    ):(
                        <View style={{paddingTop:200}}>
                            <H1>Modifications en cours</H1>
                            <ActivityIndicator size="large" color="#57B5B5"/>
                        </View>
                    )}
                </Form>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    modalView:{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: Platform.OS === "ios" ? 30 : 10,
        marginLeft: Platform.OS === "web" ? 'auto' : 10,
        marginRight: Platform.OS === "web" ? 'auto' : 10,
        width: Platform.OS === "web" ? "50%" : 'auto',
        height: Platform.OS === "ios" ? Dimensions.get('window').height - 40 : Dimensions.get('window').height - 20,
        backgroundColor: "#FCF1DF",
        borderRadius: 20,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    container: {
        borderRadius: 20,
        alignItems: "center",
    },
    avatarBtn:{
        alignItems: 'center',
        justifyContent: 'center',
        height: 120,
        width: 120,
        backgroundColor:'#57B5B5',
        borderRadius: 100,
        marginBottom: 30
    },
    modalAvatar:{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default ModalModifyKid