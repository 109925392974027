import React from "react"
import { Svg, Path, G } from "react-native-svg"

function Owl({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Owl"
      x={0}
      y={0}
      viewBox="-57 0 512 512">
      <G fill="#ffa812">
        <Path d="M120.273 462.766a7.725 7.725 0 002.793-10.532 7.725 7.725 0 00-10.558-2.832c-1.012.582-24.746 14.641-20.871 44.149a7.73 7.73 0 008.668 6.656 7.725 7.725 0 006.656-8.672c-2.496-19.035 12.578-28.328 13.312-28.77zm0 0M158.871 461.8A7.735 7.735 0 00148 462.966c-.137.172-.84 1.058-1.84 2.515.32-2.039-.16-4.199-1.527-5.957a7.732 7.732 0 00-10.844-1.355c-.879.684-21.457 16.977-21.113 41.52a7.732 7.732 0 007.726 7.62c.04 0 .075 0 .114-.003a7.724 7.724 0 007.617-7.836c-.215-15.348 12.558-26.903 14.82-28.828-4.726 8.433-10.305 22.597-4.965 36.414A7.735 7.735 0 00145.2 512c.926 0 1.867-.168 2.785-.523a7.729 7.729 0 004.422-9.997c-4.105-10.625 4.121-24.406 7.64-28.828a7.723 7.723 0 00-1.175-10.851zm0 0M286.645 449.402c-3.7-2.136-8.45-.882-10.583 2.817s-.89 8.414 2.81 10.543c.648.386 15.827 9.675 13.32 28.773-.555 4.235 2.425 8.117 6.656 8.672a7.737 7.737 0 008.672-6.656c3.87-29.508-19.864-43.567-20.875-44.149zm0 0M265.367 458.172c-3.36-2.613-8.191-2.012-10.82 1.34-1.383 1.761-1.863 3.945-1.531 6a46.828 46.828 0 00-1.864-2.547 7.73 7.73 0 10-12.035 9.707c.125.156 12.547 16.086 7.63 28.812A7.73 7.73 0 00253.952 512a7.729 7.729 0 007.211-4.945c5.34-13.813-.238-27.977-4.965-36.41 2.27 1.93 15.035 13.484 14.82 28.828-.058 4.265 3.352 7.777 7.618 7.836h.113a7.727 7.727 0 007.727-7.622c.343-24.542-20.235-40.835-21.11-41.515zm0 0" />
      </G>
      <Path
        d="M69.191 299.273s.004 0 .008.004c-16.273-8.738-29.59-19.52-40.054-32.14l-.028-.004s-69.375 131.265 45.645 152.691c-31.266-50.758-5.57-120.55-5.57-120.55zm0 0"
        fill="#dd8858"
      />
      <Path
        d="M9.258 330.984c-4.293 36.7 5.578 77.676 65.504 88.84-6.004-9.75-9.903-20.199-12.29-30.722-35.769-10.372-49.8-33.266-53.214-58.118zm0 0"
        fill="#d97f4a"
      />
      <Path
        d="M329.965 299.273c-.004 0-.008 0-.008.004 16.27-8.738 29.586-19.52 40.05-32.14l.028-.004s69.38 131.265-45.644 152.691c31.265-50.758 5.574-120.55 5.574-120.55zm0 0"
        fill="#dd8858"
      />
      <Path
        d="M336.68 389.102c-2.387 10.523-6.285 20.972-12.29 30.722 59.926-11.164 69.798-52.14 65.505-88.836-3.415 24.848-17.446 47.742-53.215 58.114zm0 0"
        fill="#d97f4a"
      />
      <Path
        d="M329.965 299.273c-31.953 17.16-75.23 26.47-130.387 26.47-55.16 0-98.437-9.31-130.387-26.47 0 0-63.464 172.344 130.387 172.344 193.848 0 130.387-172.344 130.387-172.344zm0 0"
        fill="#ad5e2e"
      />
      <Path
        d="M199.578 443.793c-106.707 0-135.445-52.223-139.719-99.172-4.257 51.063 11.043 126.996 139.72 126.996 128.671 0 143.972-75.933 139.714-126.996-4.273 46.95-33.012 99.172-139.715 99.172zm0 0"
        fill="#a05528"
      />
      <Path
        d="M199.578 325.742c127.238 0 191.309-49.512 198.785-130.781 7.653-83.25-42.804-152.098-42.804-152.098 10.847-10.5 18.613-21.093 23.964-29.82 4.22-6.883-2.503-15.215-10.117-12.527-84.316 29.78-169.828 26.316-169.828 26.316S114.063 30.297 29.746.516C22.133-2.172 15.41 6.16 19.63 13.043c5.351 8.727 13.117 19.32 23.965 29.82 0 0-50.457 68.848-42.805 152.098 7.477 81.27 71.547 130.781 198.79 130.781zm0 0"
        fill="#dd8858"
      />
      <Path
        d="M398.363 167.133c-7.476 81.273-71.547 130.785-198.785 130.785-127.242 0-191.312-49.512-198.785-130.785-.11-1.184-.203-2.367-.29-3.547-.714 10.14-.698 20.637.29 31.375 7.473 81.27 71.543 130.781 198.785 130.781 127.238 0 191.309-49.512 198.785-130.781.985-10.738 1-21.234.29-31.375-.087 1.18-.184 2.363-.29 3.547zm0 0"
        fill="#d97f4a"
      />
      <Path
        d="M299.363 75.145c-63.437-23.008-99.785 32.867-99.785 32.867S163.227 52.137 99.79 75.145C36.352 98.152 33.742 193.637 59.805 235.238c22.312 35.614 80.785 47.274 118.757 5.684-2.425-6.043-3.832-12.785-3.832-19.168 0-17.98 11.125-26.305 24.848-26.305 13.719 0 24.844 8.324 24.844 26.305 0 6.383-1.406 13.125-3.832 19.168 37.972 41.59 96.445 29.93 118.758-5.684 26.066-41.597 23.457-137.086-39.985-160.093zm0 0"
        fill="#ffe9c8"
      />
      <Path
        d="M175.047 216.734c-37.973 37.223-93.606 25.215-115.242-9.32-7.88-12.574-13.133-30.078-14.883-49.012-2.688 28.856 3.226 58.235 14.883 76.836 22.312 35.617 80.785 47.274 118.757 5.684-2.425-6.043-3.832-12.781-3.832-19.168 0-1.766.11-3.438.317-5.02zm0 0M339.348 207.414c-21.637 34.535-77.27 46.543-115.243 9.32.207 1.582.317 3.254.317 5.02 0 6.383-1.406 13.125-3.832 19.168 37.972 41.59 96.445 29.93 118.758-5.684 11.656-18.601 17.57-47.98 14.882-76.836-1.75 18.934-7.003 36.438-14.882 49.012zm0 0"
        fill="#ffdca7"
      />
      <Path
        d="M224.422 221.754c0-17.98-11.125-26.305-24.844-26.305-13.723 0-24.848 8.324-24.848 26.305 0 17.98 11.125 38.812 24.848 38.812 13.719 0 24.844-20.832 24.844-38.812zm0 0"
        fill="#ffa812"
      />
      <Path
        d="M199.578 232.742c-9.926 0-18.484-10.894-22.469-23.726-1.523 3.527-2.379 7.761-2.379 12.738 0 17.984 11.125 38.812 24.848 38.812 13.719 0 24.844-20.832 24.844-38.812 0-4.977-.856-9.211-2.379-12.738-3.98 12.832-12.543 23.726-22.465 23.726zm0 0"
        fill="#ff9a00"
      />
      <Path
        d="M118.953 138.188c-12.137 0-21.64 12.417-21.64 28.277 0 15.855 9.503 28.277 21.64 28.277 12.133 0 21.637-12.422 21.637-28.277 0-15.86-9.504-28.278-21.637-28.278zm0 0M280.203 138.188c-12.137 0-21.64 12.417-21.64 28.277 0 15.855 9.503 28.277 21.64 28.277 12.133 0 21.64-12.422 21.64-28.277 0-15.86-9.507-28.278-21.64-28.278zm0 0"
        fill="#433f43"
      />
    </Svg>
  )
}

export default Owl
