import React, { useContext, useState } from 'react';
import { ScrollView, Modal, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import { AuthContext } from '../../modules/AuthProvider';

import BaseStyle from '../../modules/BaseStyle';
import MenuBurger from '../../modules/MenuBurger';
import { H1, H3, Warning } from '../../modules/Title';
import Form from '../../modules/Form';
import Input from '../../modules/Input';
import DatePicker from '../../modules/DatePicker/DatePicker';
import GenderChoice from '../../modules/GenderChoice';
import Bear from '../../../../../assets/img/avatar/Bear';
import ModalAvatar from '../../modules/ModalAvatar';
import Btn from '../../modules/Btn';

import styles from "./AddKid.style"


const AddKid = ({navigation}) => {
    const {user, updateUser} = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [warningMessage, setWarningMessage] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [avatar, setAvatar] = useState(<Bear />)
    const [inputValue, setInputValue] = useState({
        user_param: user,
        name: '',
        gender: '',
        birthday: '',
        avatar: 'Bear' 
    });


    const handleName = (name) => {
        setInputValue({
            ...inputValue,
            name: name
        });
    }

    const handleDate = (date) => {
        setInputValue({
            ...inputValue,
            birthday: date.toJSON().slice(0, 10) // format MySQL yyyy-mm-dd
        });
    }

    const handleGender = (gender) => {
        setInputValue({
            ...inputValue,
            gender: gender
        });
    }

    const handleAvatar = (avatar) => {
        setModalVisible(false)
        setAvatar(avatar.component)
        setInputValue({
            ...inputValue,
            avatar: avatar.name
        });
    }

    const handleDisplayModal = () => {
        setModalVisible(true)
    }

    const onSubmit = () =>{
        setLoading(true);

        if (inputValue.name !== '') {   
            fetchData();
        } else {
            setLoading(false)
            setWarningMessage("Veuillez au moins renseigner un nom s'il vous plait.")

            //SCROLL TO TOP OF SCROLLVIEW ??

            setInputValue({
                user_param: user,
                name: inputValue.name,
                gender: inputValue.gender,
                birthday: inputValue.birthday,
                avatar: inputValue.avatar
            })
        }
    }

    const fetchData = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/addKid.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {
            response.json()
                .then(response => {
                    setLoading(false)
                    if (response.success) {
                        updateUser(response.user_param)
                        navigation.navigate('Home')
                    } else {
                        if (response.codeError === 10) {
                            setWarningMessage(`Vous utilisez déjà le prénom "${response.name}", veuillez en choisir un autre s'il vous plait.`)
                        } else {
                            setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                            setInputValue({
                                user_param: user,
                                name: inputValue.name,
                                gender: inputValue.gender,
                                birthday: inputValue.birthday,
                                avatar: inputValue.avatar
                            })
                        }
                    }
                })
                .catch(error => {
                    setLoading(false)
                    setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                    setInputValue({
                        user_param: user,
                        name: inputValue.name,
                        gender: inputValue.gender,
                        birthday: inputValue.birthday,
                        avatar: inputValue.avatar
                    })                    
                    console.error(error)
                })
        }
        else{
            setLoading(false)
            setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
            setInputValue({
                user_param: user,
                name: inputValue.name,
                gender: inputValue.gender,
                birthday: inputValue.birthday,
                avatar: inputValue.avatar
            })
        }
    }


    return (
        <BaseStyle>
            <MenuBurger navigation={navigation} />
                {loading ? (
                    <View>
                        <H1>Ajout de {inputValue.name} en cours</H1>
                        <ActivityIndicator size="large" color="#57B5B5"/>
                    </View>
                ) : (
                    <ScrollView contentContainerStyle={styles.container}>
                            <H1>Agrandissez votre tribu</H1>

                            <Form styles={{position: 'relative'}}>

                                {warningMessage && <Warning>{warningMessage}</Warning>}

                                <H1>Prénom</H1>
                                <Input placeholder='Ajouter' onInputChange={handleName} />
                                <H1>Date de naissance</H1>
                                <DatePicker onDateChange={handleDate}/>

                                <H1>Genre *</H1>
                                <GenderChoice onPress={handleGender} />

                                <H1>Avatar</H1>
                                <TouchableOpacity style={styles.avatarBtn} onPress={handleDisplayModal}>
                                    {avatar}
                                </TouchableOpacity>

                                <Modal
                                    style={styles.modal}
                                    visible={modalVisible}
                                    animationType="fade"
                                    transparent={true}
                                >
                                    <ModalAvatar onPressAvatar={handleAvatar}/>
                                </Modal>

                                <Btn action={onSubmit} text='OK' color='green' />

                                <H3 style={{position: 'absolute', bottom: 0, left: 0}}>* facultatif</H3>
                            </Form>
                    </ScrollView> 
                )}
        </BaseStyle>
    )
}

export default AddKid