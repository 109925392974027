import React, { useContext, useState } from "react";
import { View, StyleSheet, ScrollView, Dimensions, Platform, ActivityIndicator } from "react-native";
import { checkEmail } from "../../tools/tools";
import { AuthContext } from "./AuthProvider";
import Btn from "./Btn";
import Form from "./Form";
import Input from "./Input";
import { H1, H2, Strong, Warning } from "./Title";

const ModalModifyAccount = ({category, closeModal}) => {
    const {user, updateUser} = useContext(AuthContext)

    const [warningMessage, setWarningMessage] = useState(null);
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState({
        user_param: user,
        newData: category,
        data: '',
        confirmPassword: ''
    })

    const handleInputChange = value => {
        setInputValue({
            ...inputValue,
            data: value
        })
    }
    const handleInputConfirmPasswordChange = value => {
        setInputValue({
            ...inputValue,
            confirmPassword: value
        })
    }


    const fetchDataModify = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/modifyAccount.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                    if (response.success) {
                        closeModal();
                        updateUser(response.user_param)
                    } else {
                        setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                        setTimeout(() => {setWarningMessage(false)}, 5000);
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error);
                    setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
                    setTimeout(() => {setWarningMessage(false)}, 5000);
                    setLoading(false)
                })
            }
        else{
            setWarningMessage("Une erreur s'est produite, veuillez réessayer.")
            setTimeout(() => {setWarningMessage(false)}, 5000);
        }
    }

    const onSubmit = () => {
        // password check
        if (category === "password" &&
            (inputValue.data !== inputValue.confirmPassword ||
            inputValue.data === '')) {
            setInputValue({
                ...inputValue,
                data: '',
                confirmPassword: ''
            })
            setWarningMessage("Les mots de passe sont différents ou vides")
            setTimeout(() => {setWarningMessage(false)}, 5000);
        } 
        // email check
        else if (category === "email" &&
                (checkEmail(inputValue.data) === false || inputValue.data ==='')) {
            setWarningMessage("L'adresse e-mail est invalide ou vide")
            setTimeout(() => {setWarningMessage(false)}, 5000)
        // check name easy
        } else {
            if (inputValue.data !== '') {
                setLoading(true);
                fetchDataModify()
            } else {
                setWarningMessage("Le champ ne peut pas être vide")
                setTimeout(() => {setWarningMessage(false)}, 5000);
            }
        }
    }

    // RENDER
    if (category === 'pseudo') {
        return (
            <View style={styles.modalView}>
                <ScrollView style={{flexGrow: 1, width: '100%', height: '100%'}} contentContainerStyle={styles.container} >
                    <Form styles={{position: 'relative'}}>
                        {!loading ? (
                            <>
                            <H1>Modifier</H1>
        
                            {warningMessage && <Warning>{warningMessage}</Warning>}
                            <Strong>{user.login}</Strong>
                            <H2>Nouveau nom d'utilisateur</H2>
                            <Input value={inputValue.data} onInputChange={handleInputChange} />
                
                            <Btn action={onSubmit} text='MODIFIER' color='green' />
                            <Btn action={()=>{closeModal()}} text='ANNULER' color='red' />
                            </>
                        ) : (
                            <View style={{paddingTop:200}}>
                                <H1>Modifications en cours</H1>
                                <ActivityIndicator size="large" color="#57B5B5"/>
                            </View>
                        )}
                    </Form>
                </ScrollView>
            </View>
        )
    } else if (category === 'email') {
        return(
            <View style={styles.modalView}>
                <ScrollView style={{flexGrow: 1, width: '100%', height: '100%'}} contentContainerStyle={styles.container} >
                    <Form styles={{position: 'relative'}}>
                        {!loading ? (
                            <>
                            <H1>Modifier</H1>
        
                            {warningMessage && <Warning>{warningMessage}</Warning>}
                            <Strong>{user.email}</Strong>
                            <H2>Nouvel e-mail</H2>
                            <Input value={inputValue.data} onInputChange={handleInputChange} />
                
                            <Btn action={onSubmit} text='MODIFIER' color='green' />
                            <Btn action={()=>{closeModal()}} text='ANNULER' color='red' />
                            </>
                        ) : (
                            <View style={{paddingTop:200}}>
                                <H1>Modifications en cours</H1>
                                <ActivityIndicator size="large" color="#57B5B5"/>
                            </View>
                        )}
                    </Form>
                </ScrollView>
            </View>
        )
    } else {
        return (
            <View style={styles.modalView}>
                <ScrollView style={{flexGrow: 1, width: '100%', height: '100%'}} contentContainerStyle={styles.container} >
                    <Form styles={{position: 'relative'}}>
                        {!loading ? (
                            <>
                            <H1>Modifier</H1>
        
                            {warningMessage && <Warning>{warningMessage}</Warning>}
                            <H2>Nouveau mot de passe</H2>
                            <Input value={inputValue.data} onInputChange={handleInputChange} secure />
                            <Input value={inputValue.confirmPassword} onInputChange={handleInputConfirmPasswordChange} secure />
                
                            <Btn action={onSubmit} text='MODIFIER' color='green' />
                            <Btn action={()=>{closeModal()}} text='ANNULER' color='red' />
                            </>
                        ) : (
                            <View style={{paddingTop:200}}>
                                <H1>Modifications en cours</H1>
                                <ActivityIndicator size="large" color="#57B5B5"/>
                            </View>
                        )}
                    </Form>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    modalView:{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: Platform.OS === "ios" ? 30 : 10,
        marginLeft: Platform.OS === "web" ? 'auto' : 10,
        marginRight: Platform.OS === "web" ? 'auto' : 10,
        width: Platform.OS === "web" ? "50%" : 'auto',
        height: Platform.OS === "ios" ? Dimensions.get('window').height - 40 : Dimensions.get('window').height - 20,
        backgroundColor: "#FCF1DF",
        borderRadius: 20,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    container: {
        borderRadius: 20,
        alignItems: "center",
    }
})

export default ModalModifyAccount