import React from 'react';
import { View } from 'react-native';

const Shadow = ({children}) =>
    <View 
        accessibilityRole="header"
        style={{
            borderRadius: 15,
            maxWidth: 500,
            maxHeight: 500,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            elevation: 5,
            shadowOpacity: 0.25,
            shadowRadius: 3,
            backgroundColor : "#0000"
        }}
    >
        {children}
    </View>

export default Shadow;