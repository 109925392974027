import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SaveImg() {
  return (
    <Svg 
      fill="#57B5B5"
      viewBox="0 0 512 512"
      height='15px'
      width='15px'
    >
      <Path d="M508.704 112.448L399.552 3.296A11.25 11.25 0 00391.596 0H36.571C16.406 0 0 16.406 0 36.571v438.857C0 495.594 16.406 512 36.571 512h438.857C495.594 512 512 495.594 512 475.429V120.404a11.25 11.25 0 00-3.296-7.956zM308.888 22.505h33.841v123.78h-33.841V22.505zm-202.466 0h179.961v123.78H106.422V22.505zm305.429 466.99H100.149v-24.193H411.85v24.193zm0-46.699H100.149v-24.193H411.85v24.193zm0-93.398h-58.796c-6.215 0-11.253 5.038-11.253 11.253s5.038 11.253 11.253 11.253h58.796v24.193H100.149v-24.193h164.29c6.215 0 11.253-5.038 11.253-11.253s-5.038-11.253-11.253-11.253h-164.29V326.33H411.85v23.068zm0-45.574H100.149v-36.571H411.85v36.571zm77.644 171.605c0 7.757-6.309 14.066-14.066 14.066h-41.073V256c0-6.215-5.038-11.253-11.253-11.253H88.897c-6.215 0-11.253 5.038-11.253 11.253v233.495H36.571c-7.756 0-14.066-6.309-14.066-14.066V36.571c0-7.756 6.309-14.066 14.066-14.066h47.345v135.033c0 6.215 5.038 11.253 11.253 11.253h258.813c6.215 0 11.253-5.038 11.253-11.253V22.505h21.7l102.56 102.56v350.364z" />
      <Path d="M307.2 371.903h1.125c6.215 0 11.253-5.038 11.253-11.253s-5.038-11.253-11.253-11.253H307.2c-6.215 0-11.253 5.038-11.253 11.253s5.038 11.253 11.253 11.253z" />
    </Svg>
  )
}

export default SaveImg

