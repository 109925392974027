import React from "react"
import { Svg, Path, Ellipse, G, Circle } from "react-native-svg"

function Cat({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Cat"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M444.379 3.741c10.828-8.798 27.018-1.092 27.018 12.859v222.01l-182.26-107.699L444.379 3.741z"
        fill="#ffc850"
      />
      <Path
        d="M454.828 228.819l-110.973-65.574 92.462-104.241c6.465-7.288 18.511-2.716 18.511 7.027v162.788z"
        fill="#ebaf4b"
      />
      <Path
        d="M67.619 3.741C56.79-5.057 40.601 2.649 40.601 16.6v222.01l182.26-107.699L67.619 3.741z"
        fill="#ffc850"
      />
      <Path
        d="M57.17 228.819l110.973-65.574L75.681 59.004c-6.465-7.288-18.511-2.716-18.511 7.027v162.788z"
        fill="#ebaf4b"
      />
      <Ellipse
        cx={255.999}
        cy={292.46}
        rx={231.97}
        ry={219.54}
        fill="#ffdc64"
      />
      <Path
        d="M289.137 429.155v16.569c0 18.302-14.836 33.138-33.138 33.138s-33.138-14.836-33.138-33.138v-16.569l33.138-16.569 33.138 16.569z"
        fill="#ff8087"
      />
      <Path
        d="M274.293 343.862h-36.588c-7.899 0-12.273 9.157-7.307 15.3l18.295 22.634c3.76 4.651 10.852 4.651 14.613 0l18.295-22.634c4.965-6.143.592-15.3-7.308-15.3z"
        fill="#5d5360"
      />
      <G fill="#e1a546">
        <Path d="M479.673 437.439a8.253 8.253 0 01-3.815-.934c-50.092-26.047-128.491-41.524-129.28-41.678-4.49-.874-7.419-5.226-6.545-9.717a8.258 8.258 0 019.717-6.545c3.301.643 81.515 16.076 133.754 43.239a8.286 8.286 0 01-3.831 15.635zM496.255 379.451a8.28 8.28 0 01-2.156-.287c-46.435-12.483-130.87-10.113-131.703-10.077-4.652.134-8.398-3.459-8.531-8.03a8.284 8.284 0 018.03-8.531c3.56-.113 87.736-2.476 136.509 10.635a8.288 8.288 0 015.849 10.153c-.996 3.698-4.341 6.133-7.998 6.137z" />
      </G>
      <Path
        d="M313.991 495.431c-128.112 0-231.967-98.291-231.967-219.54 0-89.035 56.034-165.634 136.518-200.081-110.294 16.952-194.51 107.475-194.51 216.65C24.032 413.709 127.887 512 255.999 512c34.037 0 66.328-6.995 95.449-19.459-12.198 1.875-24.7 2.89-37.457 2.89z"
        fill="#ffc850"
      />
      <G fill="#e1a546">
        <Path d="M32.324 437.439a8.286 8.286 0 01-3.831-15.635c52.24-27.163 130.453-42.596 133.754-43.239 4.494-.902 8.839 2.055 9.717 6.545.874 4.49-2.055 8.843-6.545 9.717-.789.154-79.189 15.631-129.28 41.678a8.25 8.25 0 01-3.815.934zM15.743 379.451a8.29 8.29 0 01-7.997-6.137c-1.185-4.421 1.432-8.964 5.849-10.153 48.777-13.115 132.941-10.736 136.509-10.635a8.285 8.285 0 018.03 8.531c-.138 4.571-4.098 8.196-8.531 8.03-.849-.028-85.297-2.407-131.703 10.077a8.293 8.293 0 01-2.157.287z" />
      </G>
      <Path
        d="M160.727 321.456c-15.948 0-28.996-13.048-28.996-28.996v-16.569c0-15.948 13.048-28.996 28.996-28.996 15.948 0 28.996 13.048 28.996 28.996v16.569c0 15.947-13.048 28.996-28.996 28.996z"
        fill="#4b3f4e"
      />
      <Path
        d="M160.727 246.895c-1.418 0-2.778.221-4.142.421v41.002c0 9.151 7.418 16.569 16.569 16.569s16.569-7.418 16.569-16.569v-12.427c0-15.948-13.049-28.996-28.996-28.996z"
        fill="#5d5360"
      />
      <Circle cx={160.729} cy={267.61} r={12.427} fill="#fff" />
      <Path
        d="M351.271 321.456c-15.948 0-28.996-13.048-28.996-28.996v-16.569c0-15.948 13.048-28.996 28.996-28.996 15.948 0 28.996 13.048 28.996 28.996v16.569c0 15.947-13.048 28.996-28.996 28.996z"
        fill="#4b3f4e"
      />
      <Path
        d="M351.271 246.895c-1.418 0-2.778.221-4.142.421v41.002c0 9.151 7.418 16.569 16.569 16.569s16.569-7.418 16.569-16.569v-12.427c0-15.948-13.048-28.996-28.996-28.996z"
        fill="#5d5360"
      />
      <Circle cx={351.269} cy={267.61} r={12.427} fill="#fff" />
      <Path
        d="M262.408 382.15l-18.295-36.215c-.332-.658-.518-1.378-.769-2.074h-5.639c-7.899 0-12.273 9.157-7.308 15.3l18.295 22.634c3.55 4.39 9.981 4.485 13.863.587-.044-.085-.102-.143-.147-.232z"
        fill="#4b3f4e"
      />
      <Path
        d="M255.999 412.586l-33.138 16.569v16.569c0 2.629.383 5.154.961 7.606a74.65 74.65 0 0023.892-7.153v7.831a8.285 8.285 0 0016.57 0v-7.83a74.638 74.638 0 0023.892 7.152c.578-2.452.961-4.978.961-7.606v-16.569l-33.138-16.569z"
        fill="#e6646e"
      />
      <Path
        d="M297.422 437.439c-11.719 0-23.013-3.483-32.653-10.073-5.162-3.527-12.374-3.527-17.544 0-9.636 6.59-20.93 10.073-32.649 10.073-14.259 0-27.993-5.275-38.672-14.85-3.406-3.058-3.689-8.297-.635-11.703s8.281-3.689 11.703-.635c13.911 12.483 35.683 13.847 50.905 3.434 10.841-7.403 25.408-7.403 36.241 0 15.226 10.408 37.001 9.041 50.913-3.43a8.286 8.286 0 0111.06 12.338c-10.681 9.575-24.41 14.846-38.669 14.846z"
        fill="#ebaf4b"
      />
    </Svg>
  )
}

export default Cat