import React, { useContext, useState } from 'react';
import { ScrollView, Text, View, Modal, Alert, Platform, ActivityIndicator, Linking } from 'react-native';
import { AuthContext } from '../../modules/AuthProvider';
import Constants from 'expo-constants';
import VersionCheck from 'react-native-version-check-expo';


import BaseStyle from '../../modules/BaseStyle';
import ModalModifyAccount from '../../modules/ModalModifyAccount';
import ModalCSVResponse from '../../modules/ModalCSVResponse';
import ModalModifyKid from '../../modules/ModalModifyKid';
import { H1, H2 } from '../../modules/Title';
import { DynamicAvatarComponentMenu } from '../../modules/DynamicAvatarComponent';
import MenuBurger from '../../modules/MenuBurger';
import LogoLong from '../../../../../assets/img/LogoLong';
import Btn from '../../modules/Btn';

import styles from "./Account.style"

const Account = ({navigation}) => {
    const {user, updateUser, logout} = useContext(AuthContext)
    const [modalModifyKidVisible, setModalModifyKidVisible] = useState(false);
    const [modalModifyAccountVisible, setModalModifyAccountVisible] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [loading, setLoading] = useState(false)
    const [kid, setKid] = useState(null)
    const [category, setCategory] = useState(null)
    const [showModalCSV, setShowModalCSV] = useState(false)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const [warningMessageCSV, setWarningMessageCSV] = useState(null);
    const [warningMessageCSVStatus, setWarningMessageCSVStatus] = useState(false);

    const handleShowModifyModalKid = (kid) => {
        setKid(kid)
        setModalModifyKidVisible(true)
    }

    const handleShowModifyModalAccount = (type) => {
        setCategory(type)
        setModalModifyAccountVisible(true)
    }

    const onClickDeleteAccount = () => {
        if (Platform.OS === 'web') {
            let response = window.confirm(`! IMPORTANT ! \n\n Vous êtes sur le point supprimer votre compte ! \n\n En cliquant sur 'OK' tous les profils d'enfants de votre compte ainsi que toutes les pépites liées à ces enfants seront effacées définitivement. Toutes vos données seront elles aussi effacées. \n\n Attention, cette action est irréversible !`)
            if (response) {
                fetchDataDeleteAccount()
            }
        } else {
            Alert.alert(
                "! IMPORTANT ! Suppression de compte",
                "Vous êtes sur le point supprimer votre compte ! En cliquant sur 'Confirmer' tous les profils d'enfants de votre compte ainsi que toutes les pépites liées à ces enfants seront effacées définitivement. Toutes vos données seront elles aussi effacées. Attention, cette action est irréversible !",
                [
                    {text: "Annuler", style: "cancel"},
                    {text: "Confirmer", onPress: () => {fetchDataDeleteAccount()}}
                ]
            )
        }
    }

    const fetchDataDeleteAccount = async () => {
        setLoading(true)
        const response = await fetch('https://api.kidywoo.com/POST_request/deleteAccount.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(user)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                    if (response.success) {
                        if (Platform.OS === 'web') {
                            let response = window.confirm(`Merci de votre visite, à très bientôt on l'espère ! \n\n Vous allez maintenant être redirigé sur la page d'accueil`)
                            if (response) {
                                logout()
                            }
                        } else {
                            Alert.alert(
                                "Merci de votre visite !",
                                "À très bientôt on l'espère ! \n\n Vous allez maintenant être redirigé sur la page d'accueil.",
                                [
                                    {text: "OK", onPress: () => {logout()}}
                                ]
                            )
                        }
                    } else {
                        setError(true)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    setLoading(false)
                    setError(true)
                })
        }
        else {
            setError(true)
            setLoading(false)
        }
    }

    const fetchDataDeleteKid = async (data) => {
        setLoading(true)
        const response = await fetch('https://api.kidywoo.com/POST_request/deleteProfil.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(data)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                    if (response.success) {
                        updateUser(response.user_param)
                    } else {
                        setWarningMessage("Une erreur s'est produite, veuillez réessayer")
                        setTimeout(() => {setWarningMessage(false)}, 5000);
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    setLoading(false)
                    setWarningMessage("Une erreur s'est produite, veuillez réessayer")
                    setTimeout(() => {setWarningMessage(false)}, 5000);
                })
        }
        else {
            setError(true)
            setLoading(false)
        }
    }
    const createButtonAlertKid = (kid) => {
        if (Platform.OS === 'web') {
            let confirm = window.confirm(`! ATTENTION ! \n\n Êtes-vous sur de vouloir supprimer cet enfant : "${kid.name}" ? \n\n En cliquant sur 'OK' les données de cet enfant ainsi que toutes les pépites (${kid.data.length}) qui y sont liées seront effacées définitivement`)
            if (confirm) {
                fetchDataDeleteKid({user_param: user, kidId: kid.kid_id, name: kid.name})
            }
        } else {
            Alert.alert(
                "! ATTENTION ! Suppression d'un enfant",
                `En cliquant sur confirmer les données de "${kid.name}" ainsi que toutes les pépites (${kid.data.length}) qui y sont liées seront effacées définitivement`,
                [
                    {text: "Annuler",style: "cancel"},
                    {text: "Confirmer", onPress: () => {fetchDataDeleteKid({user_param: user, kidId: kid.kid_id, name: kid.name})}}
                ]
            )
        }
    }

    const askCsv = async () => {
        setWarningMessageCSVStatus(false)
        setLoadingCSV(true)
        setShowModalCSV(true)
        const response = await fetch('https://api.kidywoo.com/POST_request/AllSentenceCSVMail/AllSentenceCSVMail.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(user)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                    if (!response.success) {
                        setWarningMessageCSVStatus(true)
                        setWarningMessageCSV("Une erreur coté serveur s'est produite et le mail n'a pas pu être envoyé. Pas de panique, ce n'est pas de votre faute ;) veuillez contacter le support")
                    }
                    setLoadingCSV(false)
                })
                .catch(error => {
                    console.error(error)
                    setWarningMessageCSVStatus(true)
                    setWarningMessageCSV("Une erreur d'url s'est produite et le mail n'a pas pu être envoyé. Pas de panique, ce n'est pas de votre faute ;) veuillez contacter le support")
                    setLoadingCSV(false)
                })
            }
            else {
                setWarningMessageCSVStatus(true)
                setWarningMessageCSV("Le serveur ne répond pas et le mail n'a pas pu être envoyé. Pas de panique, ce n'est pas de votre faute ;) veuillez réessayer")
                setLoadingCSV(false)
        }
    }


    const checkVersion = async () => {
        VersionCheck.needUpdate()
            .then(async res => {
                console.log(res);
                if (res.isNeeded) {
                    Alert.alert(
                        "Mise à jour",
                        `KidyWoo s'améliore pour vous ! Une nouvelle version est disponible sur le store. \n\n
                        Version installée : ${res.currentVersion} \n
                        Nouvelle version : ${res.latestVersion}`,
                        [
                            {text: "Plus tard",style: "cancel"},
                            {text: "Mettre à jour",
                            onPress: () => {
                                    Linking.openURL(res.storeUrl);
                                },
                            },
                        ],
                    );
                }
                else {
                    Alert.alert(
                        "Vous êtes à jours !",
                        `Votre app KidyWoo est à jours sur votre mobile.\n\n
                        Version installée : ${res.currentVersion}`,
                        [
                            {text: "OK"}
                        ],
                    );
                }
            });
    }



    return (
        <BaseStyle>
                <MenuBurger navigation={navigation} />
                {/* KID MODAL */}
                <Modal
                    style={styles.modal}
                    visible={modalModifyKidVisible}
                    animationType="fade"
                    transparent={true}
                    >
                    <ModalModifyKid navigation={navigation} closeModal={()=>{setModalModifyKidVisible(false)}} data={{kid: kid, user_param: user}}/>
                </Modal>

                {/* ACCOUNT MODAL */}
                <Modal
                    style={styles.modal}
                    visible={modalModifyAccountVisible}
                    animationType="fade"
                    transparent={true}
                >
                    <ModalModifyAccount closeModal={()=>{setModalModifyAccountVisible(false)}} category={category}/>
                </Modal>

                <View style={styles.container}>
                    <ScrollView style={{width: '100%'}} contentContainerStyle={{paddingTop: 50, alignItems: 'center'}}>
                        <LogoLong />    

                        {warningMessage && <Warning>{warningMessage}</Warning>}

                        <H1>Mon Compte</H1>
                        <View style={styles.part}>
                            <View>
                                <H2>Nom d'utilisateur</H2>
                                <Text style={styles.text}>{user.login}</Text>
                            </View>
                            <Btn action={()=>{handleShowModifyModalAccount('pseudo')}} size='small' color='miniGreen' text='MODIFIER'/>
                        </View>

                        <View style={styles.part}>
                            <View>
                                <H2>E-Mail</H2>
                                <Text style={styles.text}>{user.email}</Text>
                            </View>
                            <Btn action={()=>{handleShowModifyModalAccount('email')}} size='small' color='miniGreen' text='MODIFIER'/>
                        </View>

                        <View style={styles.part}>
                            <View>
                                <H2>Mot de passe</H2>
                            </View>
                            <Btn action={()=>{handleShowModifyModalAccount('password')}} size='small' color='miniGreen' text='MODIFIER'/>
                        </View>

                        <Btn action={()=>{onClickDeleteAccount()}} text='Supprimer mon compte' color='delete' size='small' />

                        <H1>Ma Tribu</H1>
                        <View style={styles.kidList}>
                            {!loading ? (
                                <>
                                {user.kids.map((kid, i) => (
                                    <View key={i} style={styles.kidLine}>
                                        <View style={styles.kidCard}>
                                            <H2>{kid.name}</H2>
                                            <DynamicAvatarComponentMenu size={{width: 50, height: 50}} tag={kid.avatar} />
                                            <Text style={styles.basicLink}>{kid.data.length > 0 ? `${kid.data.length} pépites` : '0 pépite'}</Text>
                                        </View>
                                        <View style={styles.btnContainer}>
                                            <Btn size='small' action={()=>{handleShowModifyModalKid(kid)}} color='miniGreen' text='MODIFIER'/>
                                            <Btn size='small' action={()=>{createButtonAlertKid(kid)}} color='miniRed' text='SUPPRIMER'/>
                                        </View>
                                    </View>
                                ))}
                                </>
                            ):(
                                <View>
                                    <H1>Modifications en cours</H1>
                                    <ActivityIndicator size="large" color="#57B5B5"/>
                                </View>
                            )}
                        </View>
                        {showModalCSV && <ModalCSVResponse 
                                            closeModal={()=>{setShowModalCSV(false)}} 
                                            loader={loadingCSV}
                                            warning={warningMessageCSVStatus}
                                            warningMessage={warningMessageCSV}
                                        />}
                        <View style={styles.part}>
                            <View>
                                <H2>Récupérer mes pépites</H2>
                                <Text style={styles.text}>Elles vous seront envoyées par email</Text>
                            </View>
                            <Btn action={()=>{askCsv()}} size='small' color='miniGreen' text='ENVOYER'/>
                        </View>

                        <H1>Mon App</H1>
                        <View style={styles.part}>
                        {Platform.OS !== "web" &&
                            <>
                                <H2>Version :</H2>
                                <Text style={styles.text}>{Constants.manifest.version}</Text>
                                <Btn size='small' action={()=>{checkVersion()}} color='miniGreen' text='METTRE À JOURS'/>
                            </>
                            }
                            <H2>Mentions Légales</H2>
                            <Btn size='small' action={()=>{navigation.navigate('Legal')}} color='miniGreen' text='VOIR'/>
                        </View>
                    </ScrollView> 
                </View>
        </BaseStyle>
    )
}

export default Account