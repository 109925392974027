import AsyncStorage from "@react-native-community/async-storage";

export const getLocalStorage  = async () => {
    try{
        const value = await AsyncStorage.getItem('kidywoo_user_param');

        if (value !== null) {
            return value
        }
    }catch(error){
        console.error(error);
    }
};

export const setLocalStorage = async (value) => {
    try{
        await AsyncStorage.setItem('kidywoo_user_param', JSON.stringify(value));
        return
    }catch(error){
        console.error(error);
        return
    }
};

export const removeLocalStorage = async () => {
    try{
        await AsyncStorage.removeItem('kidywoo_user_param')
        return
    }catch(error) {
        console.error(error);
        return
    }
}



export const dateToFrench = date => {
    const jours = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    var dateToTranslate

    if (Object.prototype.toString.call(date) !== '[object Date]') {
        dateToTranslate = new Date(date.replace(/-/g, "/"));
    } else {
        dateToTranslate = date
    }
    let dateInFrench = jours[dateToTranslate.getDay()] + " ";
    dateInFrench += dateToTranslate.getDate() + " ";
    dateInFrench += mois[dateToTranslate.getMonth()] + " ";
    dateInFrench += dateToTranslate.getFullYear();
    return dateInFrench;
}

export function decodeHtml(str) {
    var map = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#039;': "'"
    };
    return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function (m) {
        return map[m];
    });
}

export const checkEmail = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (regex.test(email)) {
        return true;
    }else{
        return false;
    }
}