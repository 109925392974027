import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import SaveImg from '../../../../assets/img/SaveImg';
import ShareImg from '../../../../assets/img/ShareImg';
import TrashCan from '../../../../assets/img/TrashCan';

export const Btn = ({text, color, size, action}) => {
    
    size ? size : size = 'normal'

    return (
        <TouchableOpacity
            accessibilityRole='button'
            onPress={action}
            style={[styles.defaultContainer, styles[color]]}
        >
            <Text style={[styles.default, styles[size], {
                color: color === 'delete' ? "#f68f6a" : '#fff',
                fontWeight: color === 'delete' ? "normal" : 'bold'
            }]}>{text}</Text>
            {color === "delete" && <TrashCan />}
        </TouchableOpacity>
    )
}

export const BtnShare = ({action}) => {
    return (
        <TouchableOpacity
            accessibilityRole='button'
            onPress={action}
            style={styles.share}
        >
            <Text style={[styles.small, {color: "#57B5B5"}]}>Partager </Text>
            <ShareImg />
        </TouchableOpacity>
    )
}

export const BtnSave = ({action}) => {
    return (
        <TouchableOpacity
            accessibilityRole='button'
            onPress={action}
            style={styles.share}
        >
            <Text style={[styles.small, {color: "#57B5B5"}]}>Enregistrer </Text>
            <SaveImg />
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    default:{        
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    normal:{
        height: 70,
        lineHeight: 70,
    },
    small:{
        height: 40,
        lineHeight: 40,
    },
    defaultContainer: {
        width: 250,
        margin: 5,
        borderRadius: 15,
        overflow: "hidden",
        alignSelf: 'center',
    },
    green: {
        borderColor: "#57B5B5",
        backgroundColor: '#57B5B5'
    },
    red: {
        borderColor: "#f68f6a",
        backgroundColor: '#f68f6a'
    },
    delete: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderWidth: 1,
        borderColor: "#f68f6a"
    },
    miniGreen:{
        borderColor: "#57B5B5",
        backgroundColor: '#57B5B5',
        width: 150
    },
    miniRed:{
        borderColor: "#f68f6a",
        backgroundColor: '#f68f6a',
        width: 150
    },
    share: {
        color: "#57B5B5",
        borderColor: "#57B5B5",
        borderWidth: 1,
        borderRadius: 15,
        width: '45%',
        maxWidth: 150,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default Btn