import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function Cheetah({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Cheetah"
      x={0}
      y={0}
      viewBox="0 0 512.003 512.003">
      <Path
        d="M114.177 94.319l14.347-11.676a151.437 151.437 0 0117.734-12.426 719.928 719.928 0 00-11.189-6.827c-9.125-6.292-83.041-55.781-119.863-34.014-36.059 21.316-1.149 123.679 33.821 160.516 3.156 4.46 6.401 8.051 9.712 10.878 3.018-41.44 22.96-80.021 55.438-106.451z"
        fill="#fdbf00"
      />
      <Path
        d="M99.018 108.495a117.416 117.416 0 00-7.644-8.869C78.752 84.351 45.616 57.455 31.897 65.565c-22.645 13.386-.011 78.481 21.94 100.279a115.87 115.87 0 009.194 8.479 151.46 151.46 0 0135.987-65.828z"
        fill="#fdea96"
      />
      <Path
        d="M397.825 94.319l-14.347-11.676a151.437 151.437 0 00-17.734-12.426 719.928 719.928 0 0111.189-6.827c9.125-6.292 83.041-55.781 119.863-34.014 36.059 21.316 1.149 123.679-33.821 160.516-3.156 4.46-6.401 8.051-9.712 10.878-3.018-41.44-22.96-80.021-55.438-106.451z"
        fill="#fdbf00"
      />
      <Path
        d="M412.984 108.495a117.416 117.416 0 017.644-8.869c12.621-15.275 45.758-42.17 59.476-34.061 22.645 13.386.011 78.481-21.94 100.279a115.87 115.87 0 01-9.194 8.479 151.448 151.448 0 00-35.986-65.828z"
        fill="#fdea96"
      />
      <Path
        d="M405.364 85.402l-15.107-12.294A159.456 159.456 0 00289.61 37.331h-67.218a159.453 159.453 0 00-100.646 35.777l-15.107 12.294A159.453 159.453 0 0047.83 209.08v40.2a99.37 99.37 0 0030.883 71.999 90.148 90.148 0 0126.199 47.299 134.492 134.492 0 0072.053 93.614l28.38 14.065c15.959 7.909 33.308 11.864 50.657 11.864s34.698-3.955 50.657-11.864l28.38-14.065a134.49 134.49 0 0072.053-93.614 90.162 90.162 0 0126.199-47.299 99.37 99.37 0 0030.883-71.999v-40.2a159.459 159.459 0 00-58.81-123.678z"
        fill="#fece00"
      />
      <Path
        d="M405.364 85.402l-15.107-12.294A159.456 159.456 0 00289.61 37.331h-30.865a159.455 159.455 0 01100.646 35.777l15.107 12.294a159.455 159.455 0 0158.809 123.678v40.2a99.37 99.37 0 01-30.883 71.999 90.148 90.148 0 00-26.199 47.299 134.49 134.49 0 01-72.053 93.614l-28.38 14.066a114.006 114.006 0 01-35.224 10.807c5.12.699 10.276 1.057 15.433 1.057 17.349 0 34.698-3.955 50.657-11.864l28.38-14.066a134.49 134.49 0 0072.053-93.614 90.162 90.162 0 0126.199-47.299 99.37 99.37 0 0030.883-71.999v-40.2a159.455 159.455 0 00-58.809-123.678z"
        fill="#e2b700"
      />
      <Path
        d="M356.371 384.621c1.398-9.628.839-19.809-2.091-29.95-4.745-16.424-11.749-31.83-22.065-42.609-16.39-17.125-34.272-49.273-48.593-78.458-11.775-23.995-43.467-23.995-55.242 0-14.321 29.184-32.203 61.333-48.593 78.458-10.317 10.78-17.32 26.185-22.065 42.609-2.93 10.142-3.489 20.322-2.091 29.951-3.32 13.386.592 27.444 6.945 39.935 6.715 13.204 18.637 22.022 32.026 25.316 12.431 13.62 35.268 22.754 61.398 22.754s48.967-9.135 61.398-22.754c13.39-3.294 25.311-12.112 32.027-25.316 6.354-12.492 10.266-26.55 6.946-39.936z"
        fill="#fca800"
      />
      <Path
        d="M347.278 367.412c-34.25-39.601-91.277 4.281-91.277 4.281s-57.027-43.883-91.277-4.281c-15.415 17.824-11.317 39.116-2.148 57.145 6.715 13.204 18.637 22.022 32.027 25.316h-.001c12.431 13.62 35.269 22.754 61.399 22.754s48.968-9.135 61.399-22.754h-.001c13.389-3.294 25.312-12.112 32.027-25.316 9.169-18.029 13.267-39.321-2.148-57.145z"
        fill="#fef3ca"
      />
      <Path
        d="M354.867 379.868c-1.408 4.521-3.281 8.951-5.441 13.198-12.192 23.971-41.533 33.529-65.69 21.711l-16.377-8.011a20.261 20.261 0 01-11.358-18.2h-.003a20.26 20.26 0 01-11.358 18.2l-16.377 8.011c-24.158 11.818-53.499 2.26-65.69-21.711-2.16-4.247-4.032-8.677-5.441-13.198-5.723 14.766-1.669 30.709 5.441 44.689 12.192 23.972 41.533 33.529 65.69 21.711l16.377-8.011a20.262 20.262 0 0011.358-18.201v-.013l.003.013c0 7.738 4.407 14.8 11.358 18.201l16.377 8.011c24.158 11.818 53.499 2.26 65.69-21.711 7.11-13.981 11.164-29.923 5.441-44.689z"
        fill="#eadaa7"
      />
      <Path
        d="M313.975 333.853c-7.917-14.483-26.12-19.74-40.539-11.706a35.819 35.819 0 01-34.871 0c-14.419-8.034-32.622-2.778-40.539 11.706-8.02 14.673-2.359 33.072 12.522 40.698 7.49 3.838 13.247 10.294 16.46 18.073 4.215 10.205 14.264 17.386 25.991 17.386H259.001c11.727 0 21.777-7.181 25.991-17.386 3.213-7.779 8.97-14.235 16.46-18.073 14.883-7.626 20.544-26.025 12.523-40.698z"
        fill="#513622"
      />
      <Path
        d="M313.975 333.853c-7.917-14.484-26.12-19.74-40.539-11.706-.351.196-.712.367-1.068.551a29.564 29.564 0 0110.671 11.155c8.02 14.673 2.359 33.072-12.522 40.698-7.49 3.838-9.137 10.52-12.35 18.298-1.538 3.725-1.879 10.307-2.262 17.161H259.001c11.727 0 21.777-7.181 25.991-17.386 3.213-7.779 8.97-14.235 16.46-18.073 14.883-7.626 20.544-26.025 12.523-40.698z"
        fill="#3a2516"
      />
      <G fill="#fcb400">
        <Circle cx={207.731} cy={70.101} r={12.06} />
        <Circle cx={259.911} cy={70.101} r={12.06} />
        <Circle cx={312.091} cy={70.101} r={12.06} />
        <Circle cx={207.731} cy={139.891} r={12.06} />
        <Circle cx={259.911} cy={139.891} r={12.06} />
        <Circle cx={312.091} cy={139.891} r={12.06} />
        <Circle cx={178.401} cy={102.901} r={12.06} />
        <Circle cx={341.421} cy={102.901} r={12.06} />
        <Circle cx={232.741} cy={102.901} r={12.06} />
        <Circle cx={287.081} cy={102.901} r={12.06} />
        <Circle cx={130.291} cy={306.321} r={12.06} />
        <Circle cx={102.151} cy={265.001} r={12.06} />
        <Circle cx={156.491} cy={265.001} r={12.06} />
        <Circle cx={389.531} cy={306.321} r={12.06} />
        <Circle cx={417.671} cy={265.001} r={12.06} />
        <Circle cx={363.331} cy={265.001} r={12.06} />
      </G>
      <G fill="#513622">
        <Path d="M134.709 236.239c0-6.122 4.98-11.103 11.102-11.103s11.102 4.981 11.102 11.103c0 4.143 3.358 7.501 7.501 7.501s7.501-3.358 7.501-7.501c0-14.394-11.71-26.104-26.104-26.104s-26.103 11.71-26.103 26.104a7.5 7.5 0 1015.001 0zM340.087 236.239c0 4.143 3.358 7.501 7.501 7.501s7.501-3.358 7.501-7.501c0-6.122 4.98-11.103 11.102-11.103s11.103 4.981 11.103 11.103c0 4.143 3.358 7.501 7.501 7.501s7.501-3.358 7.501-7.501c0-14.394-11.71-26.104-26.104-26.104s-26.105 11.71-26.105 26.104z" />
      </G>
    </Svg>
  )
}

export default Cheetah
