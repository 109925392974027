import { StyleSheet } from "react-native";  

export default StyleSheet.create({
    container:{
        flex: 1,
        width: '100%',
        alignItems: 'center'    },
    backArrow: {
        zIndex: 1000,
        position: 'absolute',
        top: 10,
        left: 10, 
        borderRadius: 50
    },
    text: {
        textAlign: "left",
        color: "#337373",
        fontSize: 16,
    },
    basicLink:{
        fontSize: 16,
        color: "#e1a0a2",
    },

})