import React from "react"
import { Svg, Path, Ellipse, G } from "react-native-svg"

function Beaver({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Beaver"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Ellipse
        cx={407.895}
        cy={79.567}
        fill="#c17d4f"
        rx={90.536}
        ry={66.81}
        transform="rotate(-45 407.911 79.648)"
      />
      <Path
        d="M479.635 25.524c-27.471-16.253-70.029-6.757-101.289 24.503-31.252 31.26-40.747 73.818-24.503 101.289a50.157 50.157 0 01-9.973-7.729c-26.084-26.093-18.576-75.903 16.783-111.262 35.35-35.35 85.169-42.867 111.253-16.774a50.157 50.157 0 017.729 9.973z"
        fill="#985e33"
      />
      <Ellipse
        cx={413.941}
        cy={94.307}
        fill="#e4936b"
        rx={48.467}
        ry={35.765}
        transform="rotate(-45 413.956 94.39)"
      />
      <Ellipse
        cx={104.106}
        cy={79.567}
        fill="#c17d4f"
        rx={66.81}
        ry={90.536}
        transform="rotate(-45 104.1 79.593)"
      />
      <Path
        d="M32.366 25.524c27.471-16.253 70.029-6.757 101.289 24.503 31.252 31.26 40.747 73.818 24.503 101.289a50.157 50.157 0 009.973-7.729c26.084-26.093 18.576-75.903-16.783-111.262-35.35-35.35-85.169-42.867-111.253-16.774a50.157 50.157 0 00-7.729 9.973z"
        fill="#985e33"
      />
      <Ellipse
        cx={98.06}
        cy={94.307}
        fill="#e4936b"
        rx={35.765}
        ry={48.467}
        transform="rotate(-45 98.05 94.332)"
      />
      <Path
        d="M469.858 226.69c-8.904-14.622-14.932-30.76-18.832-47.43-17.663-75.494-98.258-132.518-195.025-132.518S78.638 103.766 60.976 179.26c-3.9 16.67-9.929 32.808-18.833 47.43-16.166 26.549-25.268 56.525-25.268 88.253C16.875 423.775 123.935 512 256.001 512c132.065 0 239.125-88.225 239.125-197.057 0-31.728-9.102-61.704-25.268-88.253z"
        fill="#c17d4f"
      />
      <Path
        d="M254.316 511.987c-131.291-.746-237.442-88.675-237.442-197.04 0-31.729 9.1-61.704 25.271-88.258 8.902-14.626 14.932-30.764 18.825-47.429 17.663-75.496 98.268-132.519 195.034-132.519-52.736 0-96.667 57.022-106.293 132.519-2.116 16.665-5.405 32.804-10.262 47.429-8.804 26.554-13.771 56.529-13.771 88.258 0 107.971 57.439 195.67 128.638 197.04z"
        fill="#985e33"
      />
      <G fill="#282528">
        <Path d="M132.033 206.013c-13.092 0-23.743 15.918-23.743 35.485s10.651 35.485 23.743 35.485 23.743-15.919 23.743-35.485-10.651-35.485-23.743-35.485zM377.885 206.013c-13.092 0-23.743 15.918-23.743 35.485s10.651 35.485 23.743 35.485c13.091 0 23.743-15.919 23.743-35.485s-10.652-35.485-23.743-35.485z" />
      </G>
      <Path
        d="M292.7 479.612h-37.793L239.42 440.44l15.487-87.668h56.389z"
        fill="#f9f6f9"
      />
      <Path d="M217.114 479.612h37.793v-126.84h-56.389z" fill="#dfdde2" />
      <Path
        d="M303.901 271.33h-97.82c-27.013 0-48.91 21.898-48.91 48.91v36.649c0 27.013 21.898 48.91 48.91 48.91 27.012 0 48.91-21.898 48.91-48.91 0 27.013 21.898 48.91 48.91 48.91 27.013 0 48.91-21.898 48.91-48.91V320.24c0-27.012-21.898-48.91-48.91-48.91z"
        fill="#e4936b"
      />
      <Path
        d="M222.743 402.891a48.694 48.694 0 01-16.666 2.915c-27.011 0-48.912-21.901-48.912-48.912v-36.655c0-27.01 21.901-48.911 48.912-48.911h33.343c-27.021 0-48.911 21.901-48.911 48.911v36.655c-.001 21.159 13.426 39.184 32.234 45.997z"
        fill="#dd6938"
      />
      <Path
        d="M277.264 231.325h-44.547c-22.094 0-40.005 17.911-40.005 40.005s17.911 40.005 40.005 40.005h44.547c22.094 0 40.005-17.911 40.005-40.005 0-22.095-17.911-40.005-40.005-40.005z"
        fill="#665e66"
      />
      <Path
        d="M243.5 299.619c7.242 7.242 17.235 11.723 28.289 11.723h-39.075c-11.044 0-21.047-4.481-28.289-11.723-7.232-7.242-11.713-17.235-11.713-28.289 0-22.087 17.905-40.002 40.002-40.002h39.075c-22.098 0-40.002 17.915-40.002 40.002 0 11.054 4.471 21.047 11.713 28.289z"
        fill="#454045"
      />
    </Svg>
  )
}

export default Beaver
