import React from "react"
import { Svg, Path, G } from "react-native-svg"

function CowOne({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Cow"
      x={0}
      y={0}
      viewBox="-3 0 511 512">
      <Path
        d="M149.14 186.406S80.61 152.617 89.06 59.348c1.543-17.067 6.367-33.684 13.968-49.04l3.516-7.113c2.394-4.836 9.523-3.996 10.734 1.262 7.176 31.211 29.735 95.66 94.453 102.945l16.239 2.309zm0 0"
        fill="#bc7d53"
      />
      <Path
        d="M169.785 166.316l-20.648 20.086S80.613 152.61 89.05 59.352c1.547-17.075 6.367-33.692 13.972-49.043l3.528-7.118c.164-.324.34-.617.543-.882a161.087 161.087 0 00-2.602 17.59c-8.351 92.316 47.352 135.14 65.293 146.417zm0 0"
        fill="#af663e"
      />
      <Path
        d="M122.14 186.547S86.013 144.94 8.446 161.199c-5.488 1.153-8.996 6.57-7.66 12.02 5.692 23.219 27.543 80.277 105.668 87.594zm0 0"
        fill="#ffc1b8"
      />
      <Path
        d="M110.727 240.566l-4.278 20.254C28.33 253.5 6.473 196.434.781 173.223c-1.324-5.453 2.176-10.871 7.664-12.024 1.43-.3 2.836-.574 4.23-.828 7.813 25.274 31.118 71.55 98.052 80.195zm0 0"
        fill="#ffb3a8"
      />
      <Path
        d="M357.328 186.406s68.527-33.789 60.082-127.058c-1.547-17.067-6.367-33.684-13.969-49.04l-3.52-7.113c-2.394-4.836-9.519-3.996-10.73 1.262-7.175 31.211-29.734 95.66-94.453 102.945l-16.238 2.309zm0 0"
        fill="#bc7d53"
      />
      <Path
        d="M336.684 166.316l20.644 20.086s68.527-33.793 60.086-127.05c-1.543-17.075-6.367-33.692-13.969-49.043l-3.53-7.118a5.081 5.081 0 00-.544-.882 160.144 160.144 0 012.602 17.59c8.355 92.316-47.348 135.14-65.29 146.417zm0 0"
        fill="#af663e"
      />
      <Path
        d="M384.324 186.547s36.13-41.606 113.7-25.348c5.488 1.153 8.992 6.57 7.66 12.02-5.692 23.219-27.547 80.277-105.672 87.594zm0 0"
        fill="#ffc1b8"
      />
      <Path
        d="M395.742 240.566l4.274 20.254c78.125-7.32 99.976-64.386 105.671-87.597 1.325-5.453-2.18-10.871-7.667-12.024-1.426-.3-2.833-.574-4.227-.828-7.816 25.274-31.121 71.55-98.05 80.195zm0 0"
        fill="#ffb3a8"
      />
      <Path
        d="M258.09 84.313c-43.754-1.06-87.914 14.144-117.926 44.785-44.34 45.273-45.004 110.382-33.672 166.914.89 4.433 2.028 8.812 3.34 13.14 5.07 16.75 19.152 65.45 19.152 89.871 0 32.364 4.805 65.895 33.598 88.754 11.156 8.856 24.773 14.715 39.16 17.946 9.2 2.066 18.668 3.062 28.133 3.343 36.98 1.106 79.371 3.547 110.59-18.464 19.894-14.024 30.887-35.313 35.328-57.274 2.34-11.55 2.05-22.691 2.05-34.305 0-24.41 14.063-73.07 19.145-89.843a150.112 150.112 0 003.676-14.895c12.926-66.969 6.79-144.504-60.61-186.36-24.343-15.12-53.066-22.913-81.964-23.613zm0 0"
        fill="#e7e7e7"
      />
      <Path
        d="M400.656 294.29a148.922 148.922 0 01-3.668 14.89c-5.082 16.773-19.144 65.437-19.144 89.847 0 11.614.285 22.754-2.051 34.305-4.445 21.957-15.434 43.25-35.332 57.27-26.074 18.378-59.93 19.71-91.926 19.015 25.16-.734 50.14-4.715 70.418-19.015 19.899-14.02 30.883-35.313 35.332-57.27 2.336-11.55 2.047-22.691 2.047-34.305 0-24.406 14.066-73.074 19.14-89.847a146.847 146.847 0 003.676-14.89c12.93-66.97 6.79-144.509-60.601-186.364-22.535-13.992-48.817-21.715-75.524-23.332 5.012-.305 10.04-.403 15.067-.278 28.902.7 57.62 8.493 81.969 23.614 67.39 41.851 73.527 119.39 60.597 186.36zm0 0"
        fill="#cecece"
      />
      <Path
        d="M356.98 367.5c-13.566-11.023-29.835-17.578-46-22.027-38.578-10.621-76.976-10.606-115.492 0-16.168 4.449-32.433 11.004-46.004 22.027-25.058 20.36-37.375 61.754-24.984 94.496 14.496 38.316 48.418 49.25 82.879 49.703 30.71.399 60.984.403 91.71 0 34.462-.449 68.38-11.387 82.88-49.703 12.39-32.742.074-74.137-24.989-94.496zm0 0"
        fill="#ffc1b8"
      />
      <Path
        d="M381.969 462c-14.5 38.313-48.418 49.25-82.88 49.7a3467.62 3467.62 0 01-55.503.277c12.039-.024 24.078-.122 36.2-.278 34.46-.449 68.386-11.387 82.886-49.699 12.39-32.742.074-74.133-24.992-94.504-13.57-11.023-29.836-17.578-46.004-22.023-16.059-4.418-32.067-7-48.09-7.727 22.445-1.047 44.89 1.535 67.398 7.727 16.168 4.445 32.43 11 46.004 22.023 25.051 20.371 37.367 61.762 24.98 94.504zm0 0"
        fill="#ffb3a8"
      />
      <G fill="#484a4c">
        <Path d="M249.2 187.168c-13.63 11.242-34.243 6.086-50.942 11.824-14.184 4.875-24.414 17.254-32.418 29.93-8.016 12.691-14.754 26.457-25.465 36.96-9.453 9.27-22.902 12.411-36.86 13.087-7.788-52.207-3.296-109.09 36.645-149.864 29.664-30.28 73.133-45.484 116.371-44.82-.207.762-.398 1.512-.582 2.246-4.14 16.883-3.812 30.563 1.34 51.328 4.25 17.098 5.496 38.118-8.09 49.309zm0 0M202.578 288.453c0 6.266-5.078 11.344-11.348 11.344-6.265 0-11.343-5.078-11.343-11.344 0-6.27 5.078-11.348 11.343-11.348 6.27 0 11.348 5.079 11.348 11.348zm0 0M326.582 288.453c0 6.266-5.082 11.344-11.348 11.344s-11.347-5.078-11.347-11.344c0-6.27 5.082-11.348 11.347-11.348s11.348 5.079 11.348 11.348zm0 0M231.246 430.23c0-8.812-4.184-15.957-9.34-15.957-5.16 0-9.344 7.145-9.344 15.957 0 8.817 4.184 15.961 9.344 15.961 5.156 0 9.34-7.144 9.34-15.96zm0 0M293.902 430.23c0-8.812-4.18-15.957-9.34-15.957s-9.34 7.145-9.34 15.957c0 8.817 4.18 15.961 9.34 15.961s9.34-7.144 9.34-15.96zm0 0" />
      </G>
    </Svg>
  )
}

export default CowOne