import React, { useEffect, useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { getLocalStorage } from '../../../tools/tools';


import BaseStyle from '../../modules/BaseStyle';
import LogoLong from '../../../../../assets/img/LogoLong';
import { H1, H2, H3, Warning } from '../../modules/Title';
import Input from '../../modules/Input';
import Btn from '../../modules/Btn';
import Form from '../../modules/Form';

import styles from "./ChangePassword.style";

const ChangePassword = ({route}) => {

    const [init, setInit] = useState(true)
    const [display,setDisplay] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [inputValue, setInputValue] = useState({
        password: '',
        confirmPassword: '',
        token: '',
        tmstmp: ''
    });
    const [urlParam, setUrlParam] = useState({
        from: '',
        token: '',
        fk_control: '',
        tmstmp: ''
    })

    // redirection si on est log
    useEffect(()=>{
        (async () => {
            const user_param = await getLocalStorage()
            if (user_param) {
                login()
            }
        })()
    },[])

    useEffect(()=>{
        // gestion initialisation form & redirection si les paramètres ne sont pas bons à première vu
        if (init) {      
            const param = route.params;
            if (param && param.token && param.from === "mail_forget_kidywoo" && param.fk_control === "13121986") {
                if (param.token.length === 60) {
                        setUrlParam({
                            token: param.token,
                            from: param.from,
                            fk_control: param.fk_control,
                            tmstmp: param.tmstmp
                        });
                        setInit(false)
                } else {
                    window.location = "https://www.kidywoo.com"
                }
            } else{
                window.location = "https://www.kidywoo.com"
            }
        } else {
            fetchDataVerifyUrlParam()
        }
    }, [init])
    
    
    const handleInputPasswordChange = (value) => {
        setInputValue({
            ...inputValue,
            password: value
        })
    }
    const handleInputConfirmPasswordChange = (value) => {
        setInputValue({
            ...inputValue,
            confirmPassword: value
        })
    }

    const onSubmit = async function() {
        if ((inputValue.password !== '' || inputValue.confirmPassword !== '') && (inputValue.password === inputValue.confirmPassword)) {
            setLoading(true)
            fetchDataSetNewPassword();
        } else {
            setWarningMessage("Désolé, les mot de passe ne sont pas valides")
            setTimeout(() => {setWarningMessage(false)}, 5000);
        }
    }

    const fetchDataVerifyUrlParam = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/Forget/confirm_changePassword.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(urlParam)
        })
        if (response.ok) {            
            response.json()
            .then(response => {
                console.log(response);
                if (response.success) {
                    setInputValue({
                        ...inputValue,
                        token: route.params.token,
                        tmstmp: route.params.tmstmp
                    })
                    setDisplay(true)
                } else {
                    window.location = "https://www.kidywoo.com"
                }
            })
            .catch(error => {
                console.error(error)
                window.location = "https://www.kidywoo.com"
            })
        }
    }

    const fetchDataSetNewPassword = async () => {
        console.log(inputValue);
        const response = await fetch('https://api.kidywoo.com/POST_request/Forget/ChangePasswordController.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {            
            response.json()
            .then(response => {
                if (response.success) {
                    setSuccess(true);
                } else {
                    setLoading(false)
                    setWarningMessage("Désolé, cet email n'est pas enregistré dans nos bases de données")
                    setTimeout(() => {setWarningMessage(false)}, 5000);
                }
            })
            .catch(error => console.error(error))
        }
    }
    
    if(display) {
        if (success) {
            return (
                <BaseStyle>
                    <LogoLong style={styles.logo}/>

                    <H1>Réinitialisation réussi !</H1>
                    <H2>Vous pouvez maintenant vous reconnecter</H2>

                    <Btn text="CONNEXION" action={()=>{window.location = "https://www.kidywoo.com/Connexion"}} color='green' />
                </BaseStyle>
            )
        } else {
            return (
                <BaseStyle>
                    <KeyboardAwareScrollView>
                        <View style={styles.container}>
        
                            <LogoLong style={styles.logo}/>

                            <Form styles={{position: 'relative'}}>
                                {!loading ? (
                                    <>
                                    <H1>Modifier</H1>
                
                                    {warningMessage && <Warning>{warningMessage}</Warning>}
                                    <H2>Nouveau mot de passe</H2>
                                    <Input value={inputValue.data} onInputChange={handleInputPasswordChange} secure />
                                    <H2>Confirmez votre nouveau mot de passe</H2>
                                    <Input value={inputValue.confirmPassword} onInputChange={handleInputConfirmPasswordChange} secure />
                        
                                    <Btn action={onSubmit} text='MODIFIER' color='green' />
                                    </>
                                ) : (
                                    <View style={{paddingTop:200}}>
                                        <H1>Modifications en cours, veuillez patienter...</H1>
                                        <ActivityIndicator size="large" color="#57B5B5"/>
                                    </View>
                                )}
                            </Form>
                            
                            {loading ? <></> : <Text onPress={()=>{window.location = "https://www.kidywoo.com"}} style={styles.basicLink}>Accueil</Text>}
                        </View>
                    </KeyboardAwareScrollView>
                </BaseStyle>
            )
        }
    } else {
        return (
            <BaseStyle>
                <LogoLong />
                <H1>Vérification des paramètres en cours</H1>
                <ActivityIndicator size="large" color="#57B5B5"/>
            </BaseStyle>
        )
    }
};

export default ChangePassword;