import React from "react"
import { Svg, Path, Ellipse, G } from "react-native-svg"

function CowTwo({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Cow"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M330.252 96.212S441.006 59.134 512 130.127c0 0-29.418 70.056-87.598 70.056"
        fill="#934d36"
      />
      <Path
        d="M365.682 135.355l58.72 64.829c58.174 0 87.596-70.052 87.596-70.052s-.01 0-.021-.01c-1.277-.464-66.024-23.921-117.049 3.441z"
        fill="#f1d0a5"
      />
      <Path
        d="M373.964 17.834l-5.068 103.924-72.864-19.285 11.888-23.478 35.119-69.351a16.343 16.343 0 0118.77-8.416 16.363 16.363 0 0112.155 16.606z"
        fill="#f6f1f1"
      />
      <Path
        d="M361.808 1.228c-9.797-1.906-24.415 85.855-24.415 85.855l-29.473-8.087 35.119-69.351a16.344 16.344 0 0118.769-8.417z"
        fill="#dfdfdf"
      />
      <Path
        d="M181.748 96.217S70.994 59.139 0 130.132c0 0 29.418 70.056 87.598 70.056"
        fill="#934d36"
      />
      <Path
        d="M146.318 135.359l-58.72 64.829c-58.174 0-87.596-70.052-87.596-70.052s.01 0 .021-.01c1.277-.464 66.024-23.921 117.049 3.441z"
        fill="#f1d0a5"
      />
      <Path
        d="M215.966 102.484l-72.854 19.275-.628-12.949-4.44-90.975c-.381-7.706 4.687-14.628 12.146-16.606a16.315 16.315 0 019.539.36 16.295 16.295 0 019.23 8.066z"
        fill="#f6f1f1"
      />
      <Path
        d="M177.52 112.199l-35.036-3.389-4.44-90.975c-.381-7.706 4.687-14.628 12.146-16.606a16.315 16.315 0 019.539.36c-16.926-2.267 17.791 110.61 17.791 110.61z"
        fill="#dfdfdf"
      />
      <Path
        d="M429.728 246.626c0 27.773-5.553 54.136-15.514 77.871C386.894 389.635 326.33 434.922 256 434.922c-95.951 0-173.729-84.309-173.729-188.295 0-103.996 77.778-188.296 173.729-188.296 13.526 0 26.682 1.679 39.322 4.842 77.004 19.305 134.406 94.116 134.406 183.453z"
        fill="#aa593d"
      />
      <Path
        d="M429.728 246.626c0 27.773-5.553 54.136-15.514 77.871l-97.62-97.619c-39.858-26.589-56.042-128.937-21.273-163.705 77.005 19.305 134.407 94.116 134.407 183.453z"
        fill="#f6f1f1"
      />
      <Path
        d="M255.991 434.917c-95.94 0-173.719-84.309-173.719-188.295 0-103.996 77.778-188.296 173.719-188.296-41.248 0-74.688 84.299-74.688 188.296 0 103.986 33.44 188.295 74.688 188.295z"
        fill="#934d36"
      />
      <Ellipse
        cx={255.999}
        cy={365.432}
        fill="#f1d0a5"
        rx={193.213}
        ry={145.888}
      />
      <Path
        d="M254.718 511.305C148.6 510.789 62.786 445.682 62.786 365.432c0-80.261 85.814-145.368 191.932-145.883-53.847 1.01-97.28 65.931-97.28 145.883.001 79.941 43.433 144.863 97.28 145.873z"
        fill="#f1b986"
      />
      <G fill="#454045">
        <Ellipse cx={154.68} cy={186.681} rx={19.613} ry={29.291} />
        <Ellipse cx={357.317} cy={186.681} rx={19.613} ry={29.291} />
        <G>
          <Ellipse cx={202.557} cy={370.277} rx={21.925} ry={38.368} />
          <Ellipse cx={309.441} cy={370.277} rx={21.925} ry={38.368} />
        </G>
      </G>
    </Svg>
  )
}

export default CowTwo
