import React from "react"
import { Svg, Path, Circle } from "react-native-svg"

function Koala({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Koala"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Circle cx={421.16} cy={90.84} r={90.84} fill="#c0bcc1" />
      <Circle cx={421.16} cy={90.84} r={57.806} fill="#9c969e" />
      <Circle cx={90.84} cy={90.84} r={90.84} fill="#c0bcc1" />
      <Circle cx={90.84} cy={90.84} r={57.806} fill="#9c969e" />
      <Path
        d="M256 49.548c-132.263 0-239.484 99.826-239.484 222.968 0 70.75 35.394 133.804 90.596 174.651 40.901 30.265 91.242 45.011 142.122 45.061l13.039.013c50.935.05 101.356-14.634 142.344-44.873 55.357-40.841 90.867-103.985 90.867-174.851 0-123.143-107.221-222.969-239.484-222.969z"
        fill="#c9c6ca"
      />
      <Path
        d="M173.176 453.743c-55.202-42.37-90.596-107.776-90.596-181.166 0-106.453 74.493-196.049 175.863-222.971-.818-.008-1.624-.057-2.444-.057-132.263 0-239.484 99.826-239.484 222.968 0 70.75 35.394 133.804 90.596 174.651 40.901 30.265 91.242 45.011 142.122 45.061l3.344.003c-28.482-7.531-55.567-20.195-79.401-38.489z"
        fill="#c0bcc1"
      />
      <Path
        d="M256 512c-27.692 0-50.458-21.837-51.611-49.505l-3.789-90.932c-1.313-31.501 23.871-57.756 55.4-57.756s56.712 26.255 55.4 57.756l-3.789 90.932C306.458 490.163 283.692 512 256 512z"
        fill="#dbd9dc"
      />
      <Path
        d="M256 313.806c-31.529 0-56.713 26.255-55.4 57.756l3.06 73.447c13.838 5.681 32.143 9.184 52.339 9.184s38.502-3.503 52.339-9.184l3.06-73.447c1.315-31.501-23.869-57.756-55.398-57.756z"
        fill="#5d5360"
      />
      <Path
        d="M144.516 313.806c-15.897 0-28.903-13.006-28.903-28.903v-16.516c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c0 15.897-13.006 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M144.516 239.484c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516v-12.387c0-15.897-13.006-28.903-28.903-28.903z"
        fill="#5d5360"
      />
      <Circle cx={144.52} cy={260.13} r={12.387} fill="#fff" />
      <Path
        d="M367.484 313.806c-15.897 0-28.903-13.006-28.903-28.903v-16.516c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c0 15.897-13.006 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M367.484 239.484c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516v-12.387c0-15.897-13.006-28.903-28.903-28.903z"
        fill="#5d5360"
      />
      <Circle cx={367.48} cy={260.13} r={12.387} fill="#fff" />
      <Path
        d="M328.774 432.863a8.257 8.257 0 00-11.524-1.895c-12.863 9.234-36.331 14.968-61.25 14.968-4.993 0-9.907-.256-14.697-.715-4.039-.387-7.118-3.805-7.287-7.859l-2.742-65.799c-1.091-26.172 16.131-48.666 40.062-55.548-9-2.588-18.873-3.149-29.295-.344-25.746 6.929-42.439 31.962-41.329 58.601l2.563 61.521c-3.149-1.487-6.081-3.07-8.527-4.826a8.24 8.24 0 00-11.524 1.895 8.26 8.26 0 001.895 11.524c15.758 11.31 42.254 18.065 70.879 18.065 28.629 0 55.121-6.754 70.879-18.065a8.25 8.25 0 001.897-11.523z"
        fill="#4b3f4e"
      />
    </Svg>
  )
}

export default Koala
