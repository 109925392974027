import React from "react"
import { Svg, Path, Ellipse, G, Circle } from "react-native-svg"

function Panda({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Panda"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Circle cx={421.16} cy={90.84} r={90.84} fill="#4b3f4e" />
      <Circle cx={421.16} cy={90.84} r={57.806} fill="#6f6571" />
      <Circle cx={90.84} cy={90.84} r={90.84} fill="#4b3f4e" />
      <Circle cx={90.84} cy={90.84} r={57.806} fill="#6f6571" />
      <Ellipse cx={256} cy={280.77} rx={239.48} ry={231.23} fill="#edebed" />
      <Ellipse
        transform="rotate(60 370.78 286.131)"
        cx={370.771}
        cy={286.135}
        rx={82.578}
        ry={70.148}
        fill="#4b3f4e"
      />
      <Path
        d="M90.839 272.516c0-91.778 45.208-172.233 113.09-217.422C96.715 78.057 16.516 170.346 16.516 280.774c0 124.145 101.338 225.41 228.498 230.958C154.887 474.98 90.839 381.768 90.839 272.516z"
        fill="#dbd9dc"
      />
      <Path
        d="M355.097 305.548c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c0 13.683-11.092 24.774-24.774 24.774z"
        fill="#5d5360"
      />
      <Path
        d="M355.097 247.742v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c0-13.682-11.092-24.774-24.774-24.774z"
        fill="#6f6571"
      />
      <G fill="#fff">
        <Circle cx={355.1} cy={264.26} r={8.258} />
        <Path d="M338.581 404.645c0-18.243-22.595-33.032-50.466-33.032-12.201 0-23.389 2.835-32.115 7.551-8.726-4.716-19.914-7.551-32.115-7.551-27.871 0-50.466 14.789-50.466 33.032 0 10.966 8.198 20.656 20.764 26.664 1.251 26.482 28.408 47.659 61.817 47.659s60.566-21.177 61.817-47.659c12.566-6.008 20.764-15.698 20.764-26.664z" />
      </G>
      <Path
        d="M297.29 371.613c0 13.682-18.486 33.032-41.29 33.032s-41.29-19.35-41.29-33.032 18.486-24.774 41.29-24.774 41.29 11.091 41.29 24.774z"
        fill="#5d5360"
      />
      <Path
        d="M338.419 403.024c-.883-4.415-5.157-7.234-9.565-6.431-4.435.81-7.387 5.097-6.653 9.544.04.25.96 6.21-3.734 11.069-4.024 4.169-15.371 10.565-45.972 6.597a127.94 127.94 0 00-8.238-.798v-20.837c0-4.56-3.698-5.78-8.258-5.78s-8.258 1.219-8.258 5.78v20.837c-2.753.177-5.5.443-8.238.798-30.605 3.968-41.948-2.427-45.972-6.597-4.694-4.859-3.774-10.819-3.758-10.94a8.256 8.256 0 00-6.476-9.718c-4.504-.887-8.827 2.004-9.718 6.476-1.04 5.21-.589 16.391 7.71 25.274 8.246 8.827 21.867 13.266 40.625 13.266 6.04 0 12.621-.46 19.718-1.383a111.26 111.26 0 0128.734 0c29.129 3.79 49.435-.214 60.343-11.883 8.299-8.883 8.751-20.064 7.71-25.274z"
        fill="#6f6571"
      />
      <G fill="#4b3f4e">
        <Path d="M272.516 396.387c-22.804 0-41.29-22.114-41.29-37.751 0-2.637.695-5.139 1.678-7.561-10.977 4.453-18.195 11.989-18.195 20.538 0 13.682 18.486 33.032 41.29 33.032 10.504 0 19.984-4.189 27.272-9.955-3.434 1.057-7.028 1.697-10.755 1.697z" />
        <Ellipse
          transform="rotate(-60 141.249 286.12)"
          cx={141.253}
          cy={286.115}
          rx={82.578}
          ry={70.148}
        />
      </G>
      <Path
        d="M156.903 305.548c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c0 13.683-11.091 24.774-24.774 24.774z"
        fill="#5d5360"
      />
      <Path
        d="M156.903 247.742v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c0-13.682-11.091-24.774-24.774-24.774z"
        fill="#6f6571"
      />
      <Circle cx={156.9} cy={264.26} r={8.258} fill="#fff" />
    </Svg>
  )
}

export default Panda