import { Dimensions, StyleSheet } from "react-native";
 
const windowWidth = Dimensions.get('window').width;

export default StyleSheet.create({
    container:{
        paddingTop: 50,
        width: '95%',
        maxWidth: 500,
    },
    avatarHeader: {
        width: '100%',
        position: 'relative',
        alignItems: 'center'
    },
    smallAvatar: {
        width: windowWidth - 120,
        maxWidth: 500,
        position: 'absolute',
        top : 10,
        backgroundColor: '#fcf1df',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        elevation: 5,
        shadowOpacity: 0.25,
        shadowRadius: 3,
        paddingLeft: 10
    },
    smallName:{
        fontFamily: "ParisienNight",
        color: "#57B5B5",
        textAlign: 'center',
        fontSize: 35,
        padding: 10,
    },
    note:{
        fontFamily: "roboto",
        fontStyle: 'italic',
        color: "#57B5B5",
        textAlign: 'center',
        fontSize: 12,
        padding: 5,
    },
    feather: {
        position: 'absolute',
        bottom: 20,
        right: 10
    },
    addSentence : {
        width: "90%",
        height: 260,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: '#fcf1df',
        borderRadius: 15
    },
    card: {
        width: '90%',
        maxWidth: 500,
        marginTop: 20,
        backgroundColor: '#fcf1df',
        borderRadius: 15
    },
    cardHeader:{
       backgroundColor: '#57b5b5',
       borderRadius: 15,
       padding: 10,
       justifyContent: 'space-between',
       flexDirection: 'row'
    },
    date:{
        color: '#fff',
        fontSize: 16,
        fontFamily: 'Roboto'
    },
    pepite:{
        fontFamily: 'Roboto',
        textAlign: "center",
        padding: 10,
        color: "#337373",
        fontSize: 16
    },
    shareContainer: {
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-around",
        marginBottom: 10
    }
})