import React from 'react';
import { StyleSheet, TextInput } from 'react-native';

const Input = ({placeholder, secure, onInputChange, height, multiline, value}) =>{
    return (
        <TextInput 
            style={[
                styles.default,
                height ? {height:height} : {height:50}
            ]}
            placeholderStyle={styles.default}
            onChangeText = {onInputChange}
            secureTextEntry={secure ? true : false}
            multiline={multiline ? true : false}
            autoCapitalize = "none"
            placeholder= {placeholder}
            placeholderTextColor='#808080'
            value = {value}
        />
    )
}

const styles = StyleSheet.create({
    default: {
        fontFamily: 'Roboto',
        color: '#000',
        backgroundColor: '#fff',
        width: '90%',
        marginBottom: 20,
        height: 50,
        lineHeight: 25,
        borderRadius: 15,
        borderWidth: 0,
        fontSize: 20,
        textAlign:'center'
    }
})

export default Input;