import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function FlowerFive({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <G fill="#eaf6ff">
        <Path d="M156.484 155.515l77.349 67.827-17.199 29.789-97.415-33.073c-54.439-21.355-82.716-49.703-96.123-67.598l-.05-.066c-2.095-2.801.131-6.78 3.623-6.582l42.667 2.417-23.426-35.741c-1.917-2.925.416-6.842 3.889-6.428l.082.01c22.201 2.662 60.89 12.978 106.603 49.445zM392.78 291.941l-97.415-33.073-17.199 29.789 77.349 67.827c45.713 36.468 84.402 46.783 106.603 49.446l.082.01c3.473.414 5.806-3.503 3.889-6.428l-23.426-35.742 42.667 2.417c3.492.198 5.718-3.781 3.623-6.582l-.05-.066c-13.406-17.895-41.684-46.243-96.123-67.598z" />
      </G>
      <G fill="#d8ecfe">
        <Path d="M216.63 253.136l-97.405-33.076c-54.44-21.354-82.726-49.702-96.128-67.594-.021-.031-.031-.052-.052-.072-2.091-2.802.134-6.778 3.626-6.582l15.657.886c15.822 17.12 42.656 38.742 86.311 55.872l97.415 33.066zM466.086 399.512c1.916 2.925-.412 6.84-3.883 6.428-.031 0-.051-.01-.082-.01-22.198-2.658-60.888-12.979-106.604-49.444l-77.349-67.831 9.868-17.089 76.896 67.43c37.063 29.564 69.5 41.935 92.286 46.982z" />
      </G>
      <Path
        d="M293.264 119.574l-20.065 100.9h-34.398l-20.065-100.9c-8.726-57.823 1.685-96.486 10.48-117.044a2.46 2.46 0 00.032-.076c1.378-3.215 5.937-3.277 7.512-.154L256 40.459 275.24 2.3c1.575-3.123 6.133-3.061 7.512.154l.032.076c8.795 20.559 19.206 59.222 10.48 117.044zM293.264 392.426l-20.065-100.9h-34.398l-20.065 100.9c-8.726 57.823 1.685 96.486 10.48 117.044l.032.076c1.378 3.215 5.937 3.277 7.512.154L256 471.54l19.24 38.159c1.575 3.123 6.133 3.061 7.512-.154a2.46 2.46 0 00.032-.076c8.795-20.558 19.206-59.221 10.48-117.043z"
        fill="#eaf6ff"
      />
      <G fill="#d8ecfe">
        <Path d="M293.269 119.575l-20.066 100.897H252.2l20.066-100.897c6.891-45.643 1.844-79.348-4.903-101.639l7.88-15.637c1.576-3.121 6.129-3.059 7.509.155l.031.072c8.796 20.561 19.21 59.23 10.486 117.049zM282.782 509.474c-.01.021-.021.052-.031.072-1.38 3.214-5.933 3.276-7.509.154l-7.88-15.637c6.747-22.291 11.794-55.996 4.903-101.639l-20.066-100.897h21.003l20.066 100.897c8.725 57.82-1.689 96.489-10.486 117.05z" />
      </G>
      <G fill="#eaf6ff">
        <Path d="M119.22 291.941l97.415-33.073 17.199 29.789-77.349 67.827c-45.713 36.468-84.402 46.783-106.603 49.446l-.082.01c-3.473.414-5.806-3.503-3.889-6.428l23.426-35.742-42.667 2.417c-3.492.198-5.718-3.781-3.623-6.582l.05-.066c13.406-17.895 41.684-46.243 96.123-67.598zM355.516 155.515l-77.349 67.827 17.199 29.789 97.415-33.073c54.439-21.355 82.716-49.703 96.123-67.598l.05-.066c2.095-2.801-.131-6.78-3.623-6.582l-42.667 2.417 23.426-35.742c1.917-2.925-.416-6.842-3.889-6.428l-.082.01c-22.201 2.663-60.89 12.979-106.603 49.446z" />
      </G>
      <G fill="#d8ecfe">
        <Path d="M233.832 288.654l-77.349 67.831c-45.715 36.465-84.405 46.787-106.604 49.444-.031 0-.052.01-.082.01-3.472.412-5.8-3.502-3.883-6.428l13.309-20.313c22.044-6.088 51.144-18.799 83.849-44.881l77.349-67.831zM488.954 152.394c-.021.021-.031.041-.052.072-13.401 17.893-41.688 46.241-96.128 67.594l-97.405 33.076-12.907-22.343 96.9-32.901c39.36-15.441 65.04-34.529 81.315-50.68l24.65-1.401c3.493-.195 5.718 3.781 3.627 6.583z" />
      </G>
      <G fill="#c4e2ff">
        <Path d="M220.059 119.22l33.073 97.415-29.789 17.199-67.827-77.349c-36.468-45.713-46.783-84.402-49.446-106.603l-.01-.082c-.414-3.473 3.503-5.806 6.428-3.889l35.742 23.426-2.417-42.667c-.198-3.492 3.781-5.718 6.582-3.623l.066.05c17.895 13.406 46.243 41.684 67.598 96.123zM356.485 355.516l-67.827-77.349-29.789 17.199 33.073 97.415c21.355 54.439 49.703 82.716 67.598 96.123l.066.05c2.801 2.095 6.78-.131 6.582-3.623l-2.417-42.667 35.742 23.426c2.925 1.917 6.842-.416 6.428-3.889l-.01-.082c-2.663-22.201-12.979-60.89-49.446-106.603z" />
      </G>
      <G fill="#c4e2ff">
        <Path d="M119.574 218.736l100.9 20.065v34.398l-100.9 20.065c-57.823 8.726-96.486-1.685-117.044-10.48a2.46 2.46 0 00-.076-.032c-3.215-1.378-3.277-5.937-.154-7.512L40.459 256 2.301 236.76c-3.123-1.575-3.061-6.133.154-7.512a2.46 2.46 0 01.076-.032c20.558-8.795 59.221-19.206 117.043-10.48zM392.426 218.736l-100.9 20.065v34.398l100.9 20.065c57.823 8.726 96.486-1.685 117.044-10.48a2.46 2.46 0 01.076-.032c3.215-1.378 3.277-5.937.154-7.512L471.54 256l38.159-19.24c3.123-1.575 3.061-6.133-.154-7.512a2.46 2.46 0 00-.076-.032c-20.558-8.795-59.221-19.206-117.043-10.48z" />
      </G>
      <G fill="#c4e2ff">
        <Path d="M155.515 355.516l67.827-77.349 29.789 17.199-33.073 97.415c-21.355 54.439-49.703 82.716-67.598 96.123l-.066.05c-2.801 2.095-6.78-.131-6.582-3.623l2.417-42.667-35.742 23.426c-2.925 1.917-6.842-.416-6.428-3.889l.01-.082c2.663-22.201 12.979-60.89 49.446-106.603zM291.941 119.22l-33.073 97.415 29.789 17.199 67.827-77.349c36.468-45.713 46.783-84.402 49.446-106.603l.01-.082c.414-3.473-3.503-5.806-6.428-3.889L363.77 69.336l2.417-42.667c.198-3.492-3.781-5.718-6.582-3.623l-.066.05c-17.895 13.407-46.243 41.685-67.598 96.124z" />
      </G>
      <Circle cx={256} cy={256} fill="#fed402" r={57.979} />
      <Path
        d="M313.984 256c0 32.025-25.958 57.984-57.984 57.984-15.019 0-28.709-5.707-39.009-15.091a57.739 57.739 0 0020.571 3.76c32.015 0 57.973-25.958 57.973-57.984 0-17.007-7.314-32.293-18.974-42.893 21.879 8.292 37.423 29.44 37.423 54.224z"
        fill="#fac600"
      />
    </Svg>
  )
}

export default FlowerFive
