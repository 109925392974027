import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function FlowerHeight({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 493.412 493.412">
      <Path
        d="M250.681 268.13c108.584-13.702 148.409 43.568 148.409 43.568 36.497 43.39 29.207 114.593 25.377 138.915a11.021 11.021 0 01-10.065 9.284c-25.161 1.904-99.705 3.472-139.373-39.532-48.206-52.26-26.653-150.553-26.653-150.553z"
        fill="#f9f7f8"
      />
      <Path
        d="M399.09 311.698s-4.332-6.226-14.027-14.081c-39.504 9.07-82.826-14.429-107.786-31.521-8.369.207-17.22.85-26.596 2.033l-2.305 1.681s-1.292 5.903-2.536 15.569c21.865 16.696 69.93 48.231 121.064 48.231 8.582 0 17.254-.889 25.899-2.885a112.971 112.971 0 0016.09-5.027c-2.851-4.899-6.098-9.594-9.803-14z"
        fill="#efedef"
      />
      <Path
        d="M244.986 262.755s74.719 67.404 143.993 51.407c57.005-13.164 92.561-78.7 103.368-101.502a11.022 11.022 0 00-3.081-13.342c-19.232-15.373-77.48-56.969-133.25-46.751 0 0-69.476 6.235-111.349 107.353z"
        fill="#f9f7f8"
      />
      <Path
        d="M305.072 175.979c-9.512 22.006-26.139 41.271-40.83 55.339v44.896c22.753-17.563 74.392-63.307 81.75-121.527-9.358 2.657-24.338 8.527-40.92 21.292z"
        fill="#efedef"
      />
      <Path
        d="M257.242 17.709a11.022 11.022 0 00-13.689-.334c-11.7 8.805-35.815 28.669-54.276 55.22l72.389 183.848c24.671-20.6 66.953-63.115 68.179-113.37 1.429-58.488-53.205-109.226-72.603-125.364z"
        fill="#f9f7f8"
      />
      <Path
        d="M224.612 81.019c8.136-11.702 17.364-22.085 26.148-30.813l3.43-4.106.422.352a281.284 281.284 0 0117.507-15.374 271.934 271.934 0 00-14.876-13.37 11.022 11.022 0 00-13.689-.334c-11.7 8.805-35.815 28.669-54.276 55.22l17.045 43.288z"
        fill="#efedef"
      />
      <Path
        d="M189.278 72.595c-12.581 18.094-22.536 39.293-24.147 62.216 0 0-11.24 68.844 76.286 134.55l2.825.396s7.203-4.778 17.424-13.314z"
        fill="#f9f7f8"
      />
      <Path
        d="M200.465 143.234c.664-9.444 2.76-18.589 5.857-27.351l-17.045-43.288c-12.581 18.094-22.536 39.293-24.147 62.216 0 0-11.24 68.844 76.286 134.55l2.825.396s4.774-3.167 12.063-8.949c-65.212-60.062-55.839-117.574-55.839-117.574z"
        fill="#efedef"
      />
      <Path
        d="M247.181 269.38c-50.684 53.379-108.219 50.546-108.219 50.546-12.2 1.298-24.643.821-36.867-2.282C45.394 303.239 11.278 236.946.964 213.916a11.04 11.04 0 013.373-13.278c20.046-15.317 81.672-57.296 138.373-42.891 68.913 17.502 104.471 111.633 104.471 111.633z"
        fill="#f9f7f8"
      />
      <Path
        d="M152.51 281.17c-12.2 1.298-24.642.821-36.867-2.282-47.807-12.145-79.558-61.176-94.525-90.063a272.178 272.178 0 00-16.781 11.813 11.038 11.038 0 00-3.373 13.278c10.314 23.03 44.431 89.323 101.132 103.728 12.224 3.103 24.666 3.581 36.867 2.282 0 0 57.535 2.833 108.219-50.546 0 0-2.894-7.654-8.62-18.994-43.918 32.853-86.052 30.784-86.052 30.784z"
        fill="#efedef"
      />
      <Path
        d="M248.374 269.807c30.94 66.79 7.1 119.23 7.1 119.23-3.29 11.82-8.32 23.21-15.71 33.43-34.29 47.4-108.49 54.68-133.7 55.78-5.12.22-9.7-3.1-11.1-8.03-6.85-24.28-23.16-97.04 11.13-144.44 41.67-57.61 142.28-55.97 142.28-55.97z"
        fill="#f9f7f8"
      />
      <Path
        d="M248.374 269.807s-8.395-.134-21.326 1.058c20.176 58.513.278 102.304.278 102.304-3.29 11.82-8.32 23.21-15.71 33.43-29.039 40.142-86.697 51.508-118.987 54.717a260.727 260.727 0 002.335 8.901c1.4 4.93 5.98 8.25 11.1 8.03 25.21-1.1 99.41-8.38 133.7-55.78 7.39-10.22 12.42-21.61 15.71-33.43 0 0 23.84-52.44-7.1-119.23z"
        fill="#efedef"
      />
      <G fill="#fff9e2">
        <Path d="M187.142 75.742zM261.654 256.454zM244.536 269.559l-.156.105.156-.105zM241.417 269.36c-.322-.242-.63-.484-.949-.725.32.242.628.484.949.725l1.306.183zM244.935 269.286l-.315.215c.09-.06.194-.132.315-.215zM257.967 259.479c-6.424 5.178-11.188 8.539-12.963 9.76 1.766-1.214 6.53-4.575 12.963-9.76zM259.729 258.046z" />
      </G>
      <Circle cx={251.416} cy={281.641} fill="#fef0ae" r={51.42} />
      <Path
        d="M241.383 281.641c0-21.034 12.636-39.108 30.726-47.072a51.23 51.23 0 00-20.694-4.348c-28.398 0-51.42 23.021-51.42 51.42s23.021 51.42 51.42 51.42c7.364 0 14.36-1.559 20.694-4.348-18.09-7.965-30.726-26.038-30.726-47.072z"
        fill="#fee97d"
      />
    </Svg>
  )
}

export default FlowerHeight
