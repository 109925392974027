import React from "react"
import { View, StyleSheet, ScrollView, TouchableOpacity, Platform, Dimensions } from "react-native"

import Bear from "../../../../assets/img/avatar/Bear"
import Beaver from "../../../../assets/img/avatar/Beaver"
import Cat from "../../../../assets/img/avatar/Cat"
import Cheetah from "../../../../assets/img/avatar/Cheetah"
import Chicken from "../../../../assets/img/avatar/Chicken"
import CowOne from "../../../../assets/img/avatar/CowOne"
import CowTwo from "../../../../assets/img/avatar/CowTwo"
import Deer from "../../../../assets/img/avatar/Deer"
import Dog from "../../../../assets/img/avatar/Dog"
import Duck from "../../../../assets/img/avatar/Duck"
import FoxOne from "../../../../assets/img/avatar/FoxOne"
import FoxTwo from "../../../../assets/img/avatar/FoxTwo"
import Frog from "../../../../assets/img/avatar/Frog"
import Giraffe from "../../../../assets/img/avatar/Giraffe"
import HamsterOne from "../../../../assets/img/avatar/HamsterOne"
import HamsterTwo from "../../../../assets/img/avatar/HamsterTwo"
import Hedgehog from "../../../../assets/img/avatar/Hedgehog"
import Hippopotamus from "../../../../assets/img/avatar/Hippopotamus"
import Koala from "../../../../assets/img/avatar/Koala"
import LadyBug from "../../../../assets/img/avatar/LadyBug"
import Monkey from "../../../../assets/img/avatar/Monkey"
import Mouse from "../../../../assets/img/avatar/Mouse"
import Owl from "../../../../assets/img/avatar/Owl"
import Panda from "../../../../assets/img/avatar/Panda"
import Penguin from "../../../../assets/img/avatar/Penguin"
import PolarBear from "../../../../assets/img/avatar/PolarBear"
import Rabbit from "../../../../assets/img/avatar/Rabbit"
import RaccoonOne from "../../../../assets/img/avatar/RaccoonOne"
import RaccoonTwo from "../../../../assets/img/avatar/RaccoonTwo"
import Tiger from "../../../../assets/img/avatar/Tiger"
import Unicorn from "../../../../assets/img/avatar/Unicorn"
import Walrus from "../../../../assets/img/avatar/Walrus"
import Wolf from "../../../../assets/img/avatar/Wolf"
import Zebra from "../../../../assets/img/avatar/Zebra"

import FlowerOne from "../../../../assets/img/avatar/FlowerOne";
import FlowerTwo from "../../../../assets/img/avatar/FlowerTwo";
import FlowerThree from "../../../../assets/img/avatar/FlowerThree";
import FlowerFour from "../../../../assets/img/avatar/FlowerFour";
import FlowerFive from "../../../../assets/img/avatar/FlowerFive";
import FlowerSix from "../../../../assets/img/avatar/FlowerSix";
import FlowerSeven from "../../../../assets/img/avatar/FlowerSeven";
import FlowerHeight from "../../../../assets/img/avatar/FlowerHeight";
import FlowerNine from "../../../../assets/img/avatar/FlowerNine";
import FlowerTen from "../../../../assets/img/avatar/FlowerTen";
import FlowerEleven from "../../../../assets/img/avatar/FlowerEleven";
import FlowerTwelve from "../../../../assets/img/avatar/FlowerTwelve";
import FlowerThirteen from "../../../../assets/img/avatar/FlowerThirteen";
import FlowerFourteen from "../../../../assets/img/avatar/FlowerFourteen";
import FlowerFifteen from "../../../../assets/img/avatar/FlowerFifteen";

const ModalAvatar = React.memo(({onPressAvatar}) =>{
    return (
        <View style={styles.modalView}>
            <ScrollView style={{flexGrow: 1, width: '100%', height: '100%'}} contentContainerStyle={styles.container} >
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Bear", component: <Bear />})}} style={styles.avatar} ><Bear size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Beaver", component: <Beaver />})}} style={styles.avatar} ><Beaver size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Cat", component: <Cat />})}} style={styles.avatar} ><Cat size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Cheetah", component: <Cheetah />})}} style={styles.avatar} ><Cheetah size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Chicken", component: <Chicken />})}} style={styles.avatar} ><Chicken size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "CowOne", component: <CowOne />})}} style={styles.avatar} ><CowOne size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "CowTwo", component: <CowTwo />})}} style={styles.avatar} ><CowTwo size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Deer", component: <Deer />})}} style={styles.avatar} ><Deer size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Dog", component: <Dog />})}} style={styles.avatar} ><Dog size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Duck", component: <Duck />})}} style={styles.avatar} ><Duck size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FoxOne", component: <FoxOne />})}} style={styles.avatar} ><FoxOne size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FoxTwo", component: <FoxTwo />})}} style={styles.avatar} ><FoxTwo size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Frog", component: <Frog />})}} style={styles.avatar} ><Frog size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Giraffe", component: <Giraffe />})}} style={styles.avatar} ><Giraffe size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "HamsterOne", component: <HamsterOne />})}} style={styles.avatar} ><HamsterOne size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "HamsterTwo", component: <HamsterTwo />})}} style={styles.avatar} ><HamsterTwo size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Hedgehog", component: <Hedgehog />})}} style={styles.avatar} ><Hedgehog size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Hippopotamus", component: <Hippopotamus />})}} style={styles.avatar} ><Hippopotamus size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Koala", component: <Koala />})}} style={styles.avatar} ><Koala size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "LadyBug", component: <LadyBug />})}} style={styles.avatar} ><LadyBug size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Monkey", component: <Monkey />})}} style={styles.avatar} ><Monkey size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Mouse", component: <Mouse />})}} style={styles.avatar} ><Mouse size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Owl", component: <Owl />})}} style={styles.avatar} ><Owl size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Panda", component: <Panda />})}} style={styles.avatar} ><Panda size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Penguin", component: <Penguin />})}} style={styles.avatar} ><Penguin size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "PolarBear", component: <PolarBear />})}} style={styles.avatar} ><PolarBear size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Rabbit", component: <Rabbit />})}} style={styles.avatar} ><Rabbit size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "RaccoonOne", component: <RaccoonOne />})}} style={styles.avatar} ><RaccoonOne size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "RaccoonTwo", component: <RaccoonTwo />})}} style={styles.avatar} ><RaccoonTwo size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Tiger", component: <Tiger />})}} style={styles.avatar} ><Tiger size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Unicorn", component: <Unicorn />})}} style={styles.avatar} ><Unicorn size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Walrus", component: <Walrus />})}} style={styles.avatar} ><Walrus size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Wolf", component: <Wolf />})}} style={styles.avatar} ><Wolf size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "Zebra", component: <Zebra />})}} style={styles.avatar} ><Zebra size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerOne", component: <FlowerOne />})}} style={styles.avatar} ><FlowerOne size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerTwo", component: <FlowerTwo />})}} style={styles.avatar} ><FlowerTwo size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerThree", component: <FlowerThree />})}} style={styles.avatar} ><FlowerThree size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerFour", component: <FlowerFour />})}} style={styles.avatar} ><FlowerFour size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerFive", component: <FlowerFive />})}} style={styles.avatar} ><FlowerFive size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerSix", component: <FlowerSix />})}} style={styles.avatar} ><FlowerSix size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerSeven", component: <FlowerSeven />})}} style={styles.avatar} ><FlowerSeven size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerHeight", component: <FlowerHeight />})}} style={styles.avatar} ><FlowerHeight size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerNine", component: <FlowerNine />})}} style={styles.avatar} ><FlowerNine size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerTen", component: <FlowerTen />})}} style={styles.avatar} ><FlowerTen size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerEleven", component: <FlowerEleven />})}} style={styles.avatar} ><FlowerEleven size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerTwelve", component: <FlowerTwelve />})}} style={styles.avatar} ><FlowerTwelve size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerThirteen", component: <FlowerThirteen />})}} style={styles.avatar} ><FlowerThirteen size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerFourteen", component: <FlowerFourteen />})}} style={styles.avatar} ><FlowerFourteen size={{width:60,height:60}} /></TouchableOpacity>
                <TouchableOpacity onPress={()=>{onPressAvatar({name: "FlowerFifteen", component: <FlowerFifteen />})}} style={styles.avatar} ><FlowerFifteen size={{width:60,height:60}} /></TouchableOpacity>
            </ScrollView>
        </View>
    )
})


const styles = StyleSheet.create({
    avatar: {
        width: 85,
        height: 85,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 85,
        margin: 10,
        backgroundColor: '#57B5B5',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    modalView: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 30,
        marginLeft: Platform.OS === "web" ? 'auto' : 30,
        marginRight: Platform.OS === "web" ? 'auto' : 30,
        width: Platform.OS === "web" ? "50%" : 'auto',
        height: Platform.OS !== "web" ? Dimensions.get('window').height - 60 : '80%',
        backgroundColor: "#FCF1DF",
        borderRadius: 20,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    container:{
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
    }
});


export default ModalAvatar