import React from "react";
import { Modal, View, Text, ActivityIndicator } from "react-native";
import Btn from "./Btn";

const ModalCSVResponse = ({closeModal, loader, warning, warningMessage}) => {

    return (
        <Modal
            animationType="slide"
            transparent={true}
        >
            <View style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
            }}>
                <View style={{
                    width: '90%',
                    maxWidth: 500,
                    backgroundColor: "white",
                    borderRadius: 20,
                    padding: 35,
                    alignItems: "center",
                    shadowColor: "#000",
                    shadowOffset: {
                    width: 0,
                    height: 2
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    backgroundColor: "#fae8c9"
                }}>
                    {loader ? 
                        <View>
                            <ActivityIndicator size="large" color="#57B5B5" />
                            <Text style={{
                                fontSize: 20,
                                color: "#337373",
                                textAlign: 'center',
                                marginBottom: 10 
                            }}>
                                Envoie du mail en cours, veuillez patienter quelques instants
                            </Text>
                        </View>
                    : 
                        warning ? 
                            <Text style={{
                                fontSize: 20,
                                color: "#337373",
                                textAlign: 'center',
                                marginBottom: 10 
                            }}>
                                {warningMessage}
                            </Text>
                        : 
                            <Text style={{
                                fontSize: 20,
                                color: "#337373",
                                textAlign: 'center',
                                marginBottom: 10 
                            }}>
                                L'email a été envoyé avec succès a votre adresse. Veuillez vérifier les spams et les couriers indésirables si vous ne le trouvez pas.
                            </Text>
                    }
                    {!loader && <Btn color='green' text='OK' action={closeModal} />}
                </View>
            </View>
        </Modal>
    )
}

export default ModalCSVResponse