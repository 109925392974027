import React, { useContext, useEffect, useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { AuthContext } from '../../modules/AuthProvider';
import { getLocalStorage, setLocalStorage } from '../../../tools/tools';

import BaseStyle from '../../modules/BaseStyle';
import { HeaderNoSignIn } from '../../modules/HeaderNoSignIn';
import LogoLong from '../../../../../assets/img/LogoLong';
import { H1, H2, H3} from '../../modules/Title';

import Form from '../../modules/Form';
import Input from '../../modules/Input';
import Btn from '../../modules/Btn';

import styles from "./SignUp.style";

const SignUp = ({navigation}) => {
    const { login } = useContext(AuthContext)

    const [display,setDisplay] = useState(false)
    const [passwordWarning, setPasswordWarning] = useState(false)
    const [acountWarning, setAccountWarning] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState(
        {
            login: '',
            email: '',
            password: '',
            confirmPassword: ''
        });

    // redirection si on est log
    useEffect(()=>{
        (async () => {
            const user_param = await getLocalStorage()
            if (user_param) {
                login()
            } else {
                setDisplay(true)
            }
        })()
    },[])
    
    const handleInputChange = (value, name) => {
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const fetchData = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/signUp/signUpController.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {            
            response.json()
            .then(response => {
                if (response.connexion) {
                    setSuccess(true);
                } else {
                    setLoading(false)
                    setAccountWarning(true)
                    setInputValue({
                            login: inputValue.login,
                            email: inputValue.email,
                            password: '',
                            confirmPassword: ''
                    })
                }
            })
            .catch(error => console.error(error))
        }
    }

    const onSubmit = async function() {

        setAccountWarning(false)
        setLoading(true);

        if (inputValue.password === inputValue.confirmPassword &&
            inputValue.password !== '' &&
            inputValue.login !== '' &&
            inputValue.email !== ''
            ) {            
            fetchData();
        } else{
            setPasswordWarning(true);
            setLoading(false)
            setInputValue({
                login: inputValue.login,
                email: inputValue.email,
                password: '',
                confirmPassword: ''
            })
        }
    }

    if (display) {
        if (success) {
            return (
                <BaseStyle>
                    <HeaderNoSignIn navigation={navigation}/>
    
                    <View style={styles.containerSuccess}>
                            <LogoLong />
                        <View style={{width: '100%',}}>
                            <H1>On vous attend !</H1>
                            <H2>Un email vous a été envoyé pour finaliser votre inscription.{"\n"}</H2>
                            <H3>(N'hésitez pas à vérifier dans votre boite de courriers indésirables)</H3>
                        </View>
                    </View>
                </BaseStyle>
            )
        }
        return (
            <BaseStyle>
                <HeaderNoSignIn navigation={navigation}/>
    
                <KeyboardAwareScrollView>
                    <View style={styles.container}>
    
                        <LogoLong />
    
                        {loading ? (
                            <View style={styles.loader}>
                                <H1>Inscription en cours, veuillez patienter nous traitons votre demande</H1>
                                <ActivityIndicator size="large" color="#57B5B5"/>
                            </View>
                        ) : (
    
                            <Form>
                                <H1>Inscription</H1>
    
                                {acountWarning ? (<Text style={styles.danger}>Cet email est déjà utilisé</Text>) : <></>}
                                {passwordWarning ? (<Text style={styles.danger}>Mot de passe différents</Text>) : <></>}
    
                                <Input value={inputValue.login} placeholder='Prénom' onInputChange={value => {handleInputChange(value,'login')}} />                            
                                <Input value={inputValue.email} placeholder='Email' onInputChange={(value) => {handleInputChange(value,'email')}} />
                                <Input
                                    value={inputValue.password}
                                    placeholder='Mot de passe'
                                    onInputChange={(value) => {handleInputChange(value,'password')}}
                                    secure
                                />
                                <Input 
                                    value={inputValue.confirmPassword}
                                    placeholder='Confirmez votre mot de passe'
                                    onInputChange={(value) => {handleInputChange(value,'confirmPassword')}}
                                    secure
                                />
                                <Btn text='OK' color='green' action={onSubmit}/>
                            </Form>
                        )}
                        {loading ? <></> : <Text style={{color: "#fff"}}><Text onPress={()=>{navigation.navigate('LandingPage')}} style={styles.basicLink}>Accueil</Text> | <Text onPress={()=>{navigation.navigate('Legal')}} style={styles.basicLink}>Mentions Légales</Text></Text>}
                    </View>
                </KeyboardAwareScrollView>
            </BaseStyle>
        );
    } else {
        return (
            <BaseStyle>
                <LogoLong />
                <ActivityIndicator size="large" color="#57B5B5"/>
            </BaseStyle>
        )
    }
};

export default SignUp;