import React from 'react';
import { Text, View, Platform } from 'react-native';

import BaseStyle from '../../modules/BaseStyle';
import { HeaderNoSignIn } from '../../modules/HeaderNoSignIn';

import LogoLong from '../../../../../assets/img/LogoLong';
import { H1 } from '../../modules/Title';

import styles from "./Welcome.style";


const Welcome = ({navigation}) => {

    return (
        <BaseStyle>
            {Platform.OS === "ios" ?
                <HeaderNoSignIn navigation={navigation}/>
            :
                <></>
            }

                <View style={styles.container}>

                    <View style={styles.logo} >
                        <LogoLong />
                    </View>

                    <H1>Inscription réussie {'\r\n'}</H1>
                    <Text style={styles.text}>Vous pouvez maintenant vous connecter à l'application KidyWoo sur votre mobile et fermer cette fenêtre !</Text>
                    <Text style={styles.text}>Où bien continuer par <Text style={{color: '#57B5B5', fontWeight: 'bold'}} onPress={()=>{navigation.navigate('Login')}}>ici</Text> si vous n'avez pas l'application !</Text>

                </View>
        </BaseStyle>
    );
};

export default Welcome;