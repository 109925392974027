import React from "react"
import { Svg, Path, Ellipse, G } from "react-native-svg"

function RaccoonOne({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Raccon"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M162.874 116.846S131.14 51.4 47.905 26.916c0 0-94.67 143.647 23.817 214.64"
        fill="#dd6938"
      />
      <Path
        d="M71.721 241.56l6.799-9.292 34.223-46.822-1.092-4.461c0-30.256-15.906-68.713-31.822-99.639-15.958-30.998-31.926-54.424-31.926-54.424S-46.76 170.57 71.721 241.56z"
        fill="#665e66"
      />
      <Path
        d="M62.233 210.757c5.687 11.126 11.126 17.997 16.287 21.51l34.223-46.822-1.092-4.461c0-30.256-15.906-68.713-31.822-99.639-20.758 22.49-48.985 68.024-17.596 129.412z"
        fill="#454045"
      />
      <Path
        d="M71.187 146.81a7.71 7.71 0 01-5.906-2.74l-17.908-21.198a7.726 7.726 0 0111.804-9.973l17.908 21.198a7.726 7.726 0 01-.916 10.888 7.688 7.688 0 01-4.982 1.825zM56.569 163.51a7.78 7.78 0 01-1.835-.221l-14.326-3.49a7.727 7.727 0 013.658-15.014l14.326 3.49a7.727 7.727 0 01-1.823 15.235z"
        fill="#f9f6f9"
      />
      <Path
        d="M349.126 116.846S380.86 51.4 464.095 26.916c0 0 94.67 143.647-23.817 214.64"
        fill="#dd6938"
      />
      <Path
        d="M440.279 241.56l-6.799-9.292-34.223-46.822 1.092-4.461c0-30.256 15.906-68.713 31.822-99.639 15.958-30.998 31.925-54.425 31.925-54.425S558.76 170.57 440.279 241.56z"
        fill="#665e66"
      />
      <Path
        d="M449.767 210.757c-5.687 11.126-11.126 17.997-16.287 21.51l-34.223-46.822 1.092-4.461c0-30.256 15.906-68.713 31.822-99.639 20.758 22.49 48.985 68.024 17.596 129.412z"
        fill="#454045"
      />
      <Path
        d="M440.813 146.81a7.727 7.727 0 01-5.899-12.712l17.908-21.198a7.725 7.725 0 0110.888-.915 7.727 7.727 0 01.917 10.888l-17.908 21.198a7.712 7.712 0 01-5.906 2.739z"
        fill="#f9f6f9"
      />
      <Ellipse cx={256} cy={292.502} fill="#e4936b" rx={256} ry={192.582} />
      <Path
        d="M256 485.084c-121.51 0-224.003-81.364-256-192.582C31.997 181.284 134.49 99.92 256 99.92c-117.739 0-256 297.589 0 385.164z"
        fill="#dd6938"
      />
      <Path
        d="M447.366 315.218C423.446 383.107 346.831 432.762 256 432.762c-43.299 0-83.372-11.281-116.04-30.462-35.861-21.026-62.81-51.561-75.327-87.081 10.116-28.701 29.659-54.157 55.712-73.967 17.75-13.495 38.518-24.384 61.399-31.894 24.673-8.108 50.088 10.127 50.088 36.098v103.245h48.336V245.454c0-25.971 25.415-44.205 50.088-36.098 56.453 18.544 100.102 57.598 117.11 105.862z"
        fill="#665e66"
      />
      <Path
        d="M139.96 402.299c-35.861-21.026-62.81-51.561-75.327-87.081 10.116-28.701 29.659-54.157 55.712-73.967-15.081 52.807-12.928 111.867 19.615 161.048z"
        fill="#454045"
      />
      <Ellipse cx={256} cy={375.531} fill="#f9f6f9" rx={89.595} ry={57.235} />
      <Path
        d="M256 432.758c-49.48 0-89.595-25.621-89.595-57.227 0-31.616 40.115-57.237 89.595-57.237-27.073 0-49.026 25.62-49.026 57.237-.001 31.607 21.953 57.227 49.026 57.227z"
        fill="#dfdde2"
      />
      <Ellipse cx={256} cy={344.329} fill="#454045" rx={40.752} ry={26.033} />
      <G fill="#282528">
        <Path d="M181.281 236.256c-12.908 0-23.409 15.694-23.409 34.985s10.501 34.985 23.409 34.985c12.907 0 23.408-15.694 23.408-34.985.001-19.291-10.501-34.985-23.408-34.985zM330.719 236.256c-12.908 0-23.409 15.694-23.409 34.985s10.501 34.985 23.409 34.985c12.907 0 23.408-15.694 23.408-34.985s-10.501-34.985-23.408-34.985z" />
      </G>
      <Path
        d="M455.431 163.51a7.727 7.727 0 01-1.823-15.235l14.326-3.49a7.724 7.724 0 019.336 5.678 7.727 7.727 0 01-5.678 9.336l-14.326 3.49a7.793 7.793 0 01-1.835.221z"
        fill="#f9f6f9"
      />
    </Svg>
  )
}

export default RaccoonOne
