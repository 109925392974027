import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function FlowerThirteen({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Circle cx={256.006} cy={256.006} r={28.132} fill="#df3333" />
      <Path
        d="M256.002 0v199.178c38.424 0 69.574-44.588 69.574-99.589S294.427 0 256.002 0z"
        fill="#4b7a2f"
      />
      <Path
        d="M291.817 99.589C291.817 44.588 275.783 0 256.002 0l-33.57 99.589 33.57 99.589c19.781 0 35.815-44.588 35.815-99.589z"
        fill="#619243"
      />
      <Path
        d="M186.428 99.589c0 55.001 31.149 99.589 69.574 99.589V0c-38.424 0-69.574 44.588-69.574 99.589z"
        fill="#88ad72"
      />
      <Path
        d="M477.705 384.001l-172.493-99.589c-19.212 33.276 3.827 82.546 51.46 110.048 47.632 27.499 101.821 22.817 121.033-10.459z"
        fill="#4b7a2f"
      />
      <Path
        d="M373.551 365.223c47.633 27.501 94.264 35.908 104.154 18.778l-69.461-78.867-103.031-20.722c-9.892 17.129 20.705 53.309 68.338 80.811z"
        fill="#619243"
      />
      <Path
        d="M426.245 273.953c-47.633-27.501-101.822-22.819-121.034 10.459l172.493 99.589c19.214-33.278-3.826-82.547-51.459-110.048z"
        fill="#88ad72"
      />
      <Path
        d="M477.705 128.001L305.213 227.59c19.212 33.276 73.401 37.959 121.034 10.459 47.631-27.502 70.671-76.772 51.458-110.048z"
        fill="#4b7a2f"
      />
      <Path
        d="M409.366 208.812c47.633-27.501 78.23-63.681 68.339-80.811l-103.031 20.722-69.461 78.867c9.889 17.129 56.52 8.722 104.153-18.778z"
        fill="#619243"
      />
      <Path
        d="M356.671 117.542c-47.633 27.501-70.672 76.77-51.46 110.048l172.493-99.589c-19.211-33.278-73.4-37.96-121.033-10.459z"
        fill="#88ad72"
      />
      <Circle cx={256.006} cy={256.006} r={28.132} fill="#992a2a" />
      <Circle cx={256.006} cy={199.741} r={28.132} fill="#df3333" />
      <Path
        d="M34.295 128.001l69.464 78.867 103.034 20.722c19.212-33.276-3.828-82.545-51.461-110.046S53.507 94.724 34.295 128.001z"
        fill="#619243"
      />
      <Path
        d="M85.757 238.046c47.634 27.501 101.823 22.819 121.035-10.457l-172.497-99.59c-19.213 33.277 3.828 82.547 51.462 110.047z"
        fill="#88ad72"
      />
      <Circle cx={207.269} cy={227.873} r={28.132} fill="#df3333" />
      <Path
        d="M34.295 384.001l103.034-20.722 69.464-78.867c-19.212-33.276-73.402-37.957-121.035-10.457s-70.676 76.769-51.463 110.046z"
        fill="#619243"
      />
      <Path
        d="M155.331 394.457c47.634-27.501 70.673-76.77 51.461-110.046L34.295 383.999c19.213 33.278 73.402 37.959 121.036 10.458z"
        fill="#88ad72"
      />
      <Circle cx={207.269} cy={284.138} r={28.132} fill="#df3333" />
      <Path
        d="M256.002 312.822V512c38.424 0 69.574-44.588 69.574-99.589s-31.149-99.589-69.574-99.589z"
        fill="#4b7a2f"
      />
      <Path
        d="M291.817 412.411c0-55.001-16.034-99.589-35.815-99.589l-33.57 99.589L256.002 512c19.781 0 35.815-44.588 35.815-99.589z"
        fill="#619243"
      />
      <Path
        d="M186.428 412.411c0 55.001 31.149 99.589 69.574 99.589V312.822c-38.424 0-69.574 44.588-69.574 99.589z"
        fill="#88ad72"
      />
      <G fill="#df3333">
        <Circle cx={256.006} cy={312.271} r={28.132} />
        <Circle cx={304.731} cy={284.138} r={28.132} />
        <Circle cx={304.731} cy={227.873} r={28.132} />
      </G>
    </Svg>
  )
}

export default FlowerThirteen
