import React from "react"
import { Svg, G, Path, Circle } from "react-native-svg"

function Giraffe({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Giraffe"
      x={0}
      y={0}
      viewBox="0 0 512.001 512.001">
      <Path
        d="M322.427 16.516l-33.032 1.367v41.34c0 4.381-1.74 8.581-4.838 11.679-6.45 6.45-16.907 6.45-23.357 0a16.515 16.515 0 01-4.838-11.679v-41.34l-33.032-1.367L203 131.243l139.904.886-20.477-115.613z"
        fill="#ffdc64"
      />
      <G fill="#ebaf4b">
        <Path d="M347.201 148.645l8.258 16.516C442.055 145.178 458.8 82.209 462.038 58.604c.57-4.156-3.581-7.335-7.399-5.597-20.67 9.41-75.631 38.671-107.438 95.638zM198.556 148.645l-8.258 16.516C103.702 145.178 86.957 82.209 83.719 58.604c-.57-4.156 3.581-7.335 7.399-5.597 20.67 9.41 75.631 38.671 107.438 95.638z" />
      </G>
      <Path
        d="M363.717 206.452l-90.839 214.71v74.323c0 9.122 7.395 16.516 16.516 16.516h96.715c10.001 0 17.707-8.817 16.367-18.728l-38.759-286.821z"
        fill="#e69641"
      />
      <Path
        d="M347.201 272.516h-11.434l-62.888 148.645v74.323c0 9.121 7.395 16.516 16.516 16.516h33.858l23.948-239.484z"
        fill="#d27d38"
      />
      <Path
        d="M223.965 363.679c3.805 32.591 24.256 57.482 48.913 57.482 24.739 0 45.244-25.056 48.951-57.806l-97.864.324z"
        fill="#e69641"
      />
      <Path
        d="M135.895 394.16c-7.902 11.854-11.226 25.084-10.231 36.842.316 3.736 4.072 6.24 7.642 5.095 11.236-3.604 22.171-11.76 30.073-23.614s11.226-25.084 10.231-36.842c-.316-3.736-4.072-6.24-7.642-5.095-11.236 3.604-22.171 11.761-30.073 23.614z"
        fill="#afd755"
      />
      <G fill="#bee86e">
        <Path d="M70.974 354.23c-10.317 5.199-17.461 14.13-20.614 24.243-.971 3.115.757 6.377 3.842 7.441 9.977 3.44 21.36 2.994 31.638-2.186 10.317-5.199 17.461-14.13 20.614-24.243.971-3.115-.757-6.377-3.842-7.441-9.978-3.439-21.36-2.994-31.638 2.186zM115.084 324.788c3.345 13.848 10.808 25.267 20.119 32.515 2.959 2.303 7.347 1.243 8.928-2.156 4.977-10.699 6.406-24.265 3.062-38.113-3.345-13.848-10.808-25.267-20.119-32.515-2.959-2.303-7.347-1.243-8.928 2.156-4.977 10.698-6.406 24.265-3.062 38.113z" />
      </G>
      <Path
        d="M229.197 380.435c-35.339 0-111.778-4.935-175.278-43.032a8.259 8.259 0 01-2.831-11.331 8.244 8.244 0 0111.331-2.831c79.27 47.556 184.012 40.21 185.056 40.137 4.552-.435 8.512 3.056 8.863 7.605.347 4.548-3.056 8.516-7.601 8.863-1.088.081-8.201.589-19.54.589z"
        fill="#96be4b"
      />
      <Path
        d="M272.878 99.097c-56.891 0-87.575 36.881-96.989 74.323l6.151 41.29s22.193 97.211 30.968 131.356c9.715 37.809 46.107 42.063 59.872 42.063 13.763 0 50.155-4.254 59.87-42.063 8.774-34.146 30.968-131.356 30.968-131.356l6.151-41.29c-9.414-37.443-40.097-74.323-96.991-74.323z"
        fill="#ffc850"
      />
      <Path
        d="M247.391 346.066c-3.735-34.146-13.184-131.356-13.184-131.356l-1.427-22.496c-1.063-16.767.072-33.698 4.51-49.902 6.681-24.397 18.289-43.214 35.589-43.214h-.001c-56.892 0-87.576 36.881-96.989 74.323l6.151 41.29s22.193 97.211 30.968 131.356c9.715 37.808 46.107 42.063 59.871 42.063-5.861-.001-21.353-4.255-25.488-42.064z"
        fill="#ebaf4b"
      />
      <G fill="#4b3f4e">
        <Path d="M248.104 338.581a8.231 8.231 0 01-5.839-2.419l-8.258-8.258a8.254 8.254 0 010-11.677 8.254 8.254 0 0111.677 0l8.258 8.258a8.254 8.254 0 010 11.677 8.231 8.231 0 01-5.838 2.419zM297.653 338.581a8.231 8.231 0 01-5.839-2.419 8.254 8.254 0 010-11.677l8.258-8.258c3.226-3.226 8.452-3.226 11.677 0s3.226 8.452 0 11.677l-8.258 8.258a8.231 8.231 0 01-5.838 2.419zM363.717 222.968c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c0 13.682-11.091 24.774-24.774 24.774z" />
      </G>
      <Path
        d="M363.717 165.161v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c0-13.682-11.091-24.774-24.774-24.774z"
        fill="#5d5360"
      />
      <Circle cx={363.72} cy={181.68} r={8.258} fill="#fff" />
      <Path
        d="M182.04 222.968c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c0 13.682-11.092 24.774-24.774 24.774z"
        fill="#4b3f4e"
      />
      <Path
        d="M182.04 165.161v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c0-13.682-11.092-24.774-24.774-24.774z"
        fill="#5d5360"
      />
      <Circle cx={182.04} cy={181.68} r={8.258} fill="#fff" />
      <G fill="#d27d38">
        <Path d="M239.846 33.032c-9.122 0-16.516-7.395-16.516-16.516C223.33 7.395 230.725 0 239.846 0c9.122 0 16.516 7.395 16.516 16.516 0 9.122-7.394 16.516-16.516 16.516zM305.911 33.032c-9.122 0-16.516-7.395-16.516-16.516C289.395 7.395 296.789 0 305.911 0c9.122 0 16.516 7.395 16.516 16.516 0 9.122-7.395 16.516-16.516 16.516z" />
      </G>
    </Svg>
  )
}

export default Giraffe