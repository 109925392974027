import React from "react"
import { Svg, Path } from "react-native-svg"

function HamsterTwo({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Hamster"
      x={0}
      y={0}
      viewBox="0 -20 511.999 511">
      <Path
        d="M423.66 169.324c78.106-64.094 48.82-193.75 1.262-164.566-31.895 19.574-54.813 50.137-66.824 68.89 31.773 23.329 55.308 56.872 65.562 95.676zm0 0"
        fill="#e87e04"
      />
      <Path
        d="M448.629 3.328c-6.93-3.914-15.008-3.906-23.707 1.43-31.895 19.574-54.813 50.137-66.824 68.89 31.773 23.329 55.308 56.872 65.562 95.676a127.626 127.626 0 0016.098-15.789c-9.555-25.73-25.106-48.625-45-67.05-8.828-8.176-9.953-21.692-2.64-31.25 22.155-28.965 45.855-46.575 56.51-51.907zm0 0"
        fill="#d66e07"
      />
      <Path
        d="M358.102 73.64c0 .005 0 .005-.004.008 18.297 13.434 33.851 30.258 45.718 49.52 31.989-109.676 62.122-96.258 62.122-96.258-7.528-20.418-22.34-33.617-41.016-22.152-26.89 16.5-49.938 42.531-66.82 68.883zm0 0"
        fill="#f3a334"
      />
      <Path
        d="M448.629 3.328c-12.227-6.906-22.496-.008-33.875 8.25-22.332 16.23-44.645 42.055-56.656 62.07 17.703 12.997 33.496 29.688 45.718 49.52a519.733 519.733 0 016.395-20.402 176.32 176.32 0 00-15.453-16.282c-8.828-8.175-9.953-21.691-2.64-31.246 22.155-28.968 45.855-46.578 56.51-51.91zm0 0"
        fill="#ee911c"
      />
      <Path
        d="M88.484 169.324c10.348-38.87 34-72.453 65.895-95.773-12.07-18.809-34.957-49.254-66.766-68.785-47.547-29.192-76.855 100.23.871 164.558zm0 0"
        fill="#e87e04"
      />
      <Path
        d="M84.727 3.2c-45.809-22.34-72.38 103.109 3.757 166.124a170.915 170.915 0 014.918-15.453C47.122 99.68 56.422 18.746 84.727 3.2zm0 0"
        fill="#d66e07"
      />
      <Path
        d="M108.59 122.855c11.914-19.187 27.5-35.93 45.789-49.304-12.07-18.809-34.957-49.254-66.766-68.785-17.8-10.93-33.043.394-41.074 22.195 0 0 30.078-13.406 62.05 95.894zm0 0"
        fill="#f3a334"
      />
      <Path
        d="M65.059 33.473C69.102 18.977 76.012 7.984 84.727 3.199 76.148-.984 68.242.016 61.562 4.82c-.019.012-.035.024-.05.035-7.106 5.133-11.914 13.848-14.965 22.106.125-.055 7.387-3.156 18.512 6.512zm0 0"
        fill="#ee911c"
      />
      <Path
        d="M447.86 218.484c-10.505-7.324-17.766-18.48-19.65-31.144C415.728 103.43 343.384 39.055 256 39.055c-87.387 0-159.727 64.375-172.21 148.285-1.884 12.664-9.145 23.82-19.65 31.144-51.527 35.934-76.105 87.793-58.46 146.235.707 2.347 3.851 2.758 5.117.66l7.691-12.777c0 1.027 8.39 35.117 35.45 70.261 1.136 1.477 3.445 1.18 4.156-.543l4.695-11.375c.727-1.761 2.926-2.324 4.426-1.144 103.355 81.12 274.308 81.043 377.57 0 1.5-1.18 3.7-.617 4.426 1.144l4.691 11.375c.711 1.723 3.024 2.02 4.16.543 27.981-36.343 35.446-70.261 35.446-70.261l7.695 12.777c1.262 2.098 4.406 1.687 5.113-.66 17.684-58.563-7.039-110.375-58.457-146.235zm0 0"
        fill="#f6e1c6"
      />
      <Path
        d="M39.094 352.598l-7.696 12.78c-1.261 2.099-4.406 1.684-5.113-.66-17.68-58.542 7.024-110.366 58.461-146.234 10.5-7.324 17.762-18.48 19.649-31.144C116.383 106.75 183.585 44.19 266.3 39.363c-3.406-.199-6.84-.308-10.301-.308-87.387 0-159.727 64.37-172.21 148.285-1.884 12.664-9.145 23.82-19.65 31.144C12.657 254.387-11.98 306.23 5.68 364.72c.707 2.347 3.851 2.758 5.117.66l7.691-12.777c.004 0 7.465 33.918 35.45 70.261 1.136 1.477 3.445 1.18 4.156-.543l4.695-11.375c.387-.941 1.195-1.523 2.09-1.699-20.086-30.766-25.785-56.644-25.785-56.648zm0 0"
        fill="#e2c8ac"
      />
      <Path
        d="M83.79 187.34c-1.884 12.664-9.145 23.82-19.65 31.144-51.527 35.934-76.105 87.793-58.46 146.235.707 2.347 3.851 2.758 5.117.66l7.691-12.777c0 1.027 8.39 35.117 35.45 70.261 1.136 1.477 3.445 1.18 4.156-.543l4.695-11.375c.727-1.761 2.926-2.324 4.426-1.144 17.12 13.437 37.363 25.375 59.976 35.043C8.145 323.133 195.88 300.672 229.168 247.75c27.547-43.79-8.023-162.379-21.121-202.004-64.8 18.527-114.152 73.652-124.258 141.594zm0 0"
        fill="#f3a334"
      />
      <Path
        d="M18.488 352.598s7.465 33.918 35.45 70.261c1.101 1.434 3.437 1.204 4.156-.539 3.355-8.125 2.554-6.187 4.695-11.375a2.845 2.845 0 012.09-1.699c-20.086-30.77-25.785-56.644-25.785-56.648l-7.696 12.78c-1.261 2.099-4.406 1.685-5.113-.66-17.68-58.542 7.024-110.366 58.461-146.234 10.5-7.324 17.762-18.48 19.649-31.144 9.12-61.313 50.203-112.18 105.757-135.137a955.65 955.65 0 00-2.105-6.457C142.567 64.47 93.8 120.078 83.793 187.32c-1.785 11.98-8.602 23.457-19.652 31.164C13.18 254.027-12.125 305.75 5.68 364.72c.664 2.203 3.468 2.695 4.855 1.023a3.04 3.04 0 00.258-.363zm0 0"
        fill="#ee911c"
      />
      <Path
        d="M428.21 187.34c1.884 12.664 9.145 23.82 19.65 31.144 51.527 35.934 76.105 87.793 58.46 146.235-.707 2.344-3.851 2.758-5.113.66l-7.695-12.781c0 .004-7.465 33.922-35.45 70.261-1.136 1.477-3.445 1.184-4.156-.539l-4.691-11.375c-.73-1.765-2.93-2.324-4.43-1.148-17.117 13.437-37.363 25.379-59.976 35.047 119.05-121.711-68.688-144.172-101.977-197.094-27.543-43.79 8.023-162.379 21.121-202.004 64.8 18.527 114.149 73.652 124.258 141.594zm0 0"
        fill="#f3a334"
      />
      <Path
        d="M422.922 425.063c80.851-104.575-87.957-127.395-119.356-177.313-26.171-41.605 4.633-150.727 19.004-195.496a173.073 173.073 0 00-18.617-6.508c-13.098 39.625-48.668 158.215-21.121 202.004 33.29 52.922 221.023 75.383 101.977 197.094 13.62-5.824 26.375-12.477 38.113-19.781zm0 0"
        fill="#ee911c"
      />
      <Path
        d="M256 370.7c0 15.054-11.082 27.488-25.527 29.675v26.227c0 5.636 4.574 10.207 10.21 10.207h30.63c5.64 0 10.21-4.57 10.21-10.207v-26.227C267.082 398.184 256 385.754 256 370.699zm0 0"
        fill="#fff"
      />
      <Path
        d="M251.078 426.598v-15.004c0-6.004 3.516-11.352 8.89-14.035a30.114 30.114 0 006.329-4.25c-6.309-5.5-10.297-13.582-10.297-22.61 0 15.055-11.082 27.488-25.523 29.676v26.227c0 5.636 4.57 10.207 10.207 10.207h20.605c-5.64 0-10.21-4.57-10.21-10.211zm0 0"
        fill="#e4e3e1"
      />
      <Path
        d="M371.836 239.234c0 11.664-9.457 21.121-21.125 21.121-11.664 0-21.121-9.457-21.121-21.12 0-11.669 9.457-21.126 21.12-21.126 11.669 0 21.126 9.457 21.126 21.125zm0 0M182.879 239.234c0 11.664-9.457 21.121-21.125 21.121-11.664 0-21.121-9.457-21.121-21.12 0-11.669 9.457-21.126 21.12-21.126 11.669 0 21.126 9.457 21.126 21.125zm0 0"
        fill="#525468"
      />
      <Path
        d="M316.098 353.809a7.725 7.725 0 00-7.723 7.726v9.164c0 12.309-10.016 22.324-22.324 22.324-12.309 0-22.324-10.015-22.324-22.324V327.75a7.726 7.726 0 10-15.454 0v42.95c0 12.308-10.011 22.323-22.32 22.323-12.312 0-22.324-10.015-22.324-22.324v-9.164c0-4.265-3.461-7.726-7.727-7.726s-7.726 3.46-7.726 7.726v9.164c0 35.992 45.937 51.567 67.828 22.86 21.871 28.683 67.824 13.171 67.824-22.86v-9.164a7.732 7.732 0 00-7.73-7.726zm0 0"
        fill="#e2c8ac"
      />
      <Path
        d="M298.809 315.527c0-13.007-19.11-23.554-42.68-23.554s-42.676 10.547-42.676 23.554c0 13.008 19.106 23.555 42.676 23.555s42.68-10.547 42.68-23.555zm0 0"
        fill="#af7561"
      />
      <Path
        d="M350.191 239.234c0-7.925 4.368-14.82 10.825-18.433a21.028 21.028 0 00-10.305-2.692c-11.664 0-21.121 9.457-21.121 21.125 0 11.664 9.457 21.121 21.12 21.121 3.747 0 7.255-.98 10.306-2.687-6.454-3.617-10.825-10.512-10.825-18.434zm0 0M161.234 239.234c0-7.925 4.371-14.82 10.825-18.433a21.028 21.028 0 00-10.305-2.692c-11.664 0-21.121 9.457-21.121 21.125 0 11.664 9.457 21.121 21.12 21.121 3.747 0 7.255-.98 10.306-2.687-6.454-3.617-10.825-10.512-10.825-18.434zm0 0"
        fill="#3e4151"
      />
      <Path
        d="M234.055 315.527c0-11.047 13.785-20.312 32.375-22.855a75.75 75.75 0 00-10.301-.695c-23.57 0-42.676 10.543-42.676 23.55 0 13.008 19.106 23.555 42.676 23.555a75.75 75.75 0 0010.3-.695c-18.59-2.543-32.374-11.809-32.374-22.86zm0 0"
        fill="#a0634a"
      />
    </Svg>
  )
}

export default HamsterTwo
