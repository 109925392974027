import React from "react"
import { Svg, Path, Circle } from "react-native-svg"

function Rabbit({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Rabbit"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M478.968 0s-3.969 183.681-105.29 267.871L299.871 208C366.574 66.722 478.968 0 478.968 0z"
        fill="#aea8af"
      />
      <Path
        d="M373.677 267.871l-32.684-26.514-2.454-21.745C356.36 178.724 403.169 82.115 478.968 0c0 0-3.97 183.681-105.291 267.871z"
        fill="#9c969e"
      />
      <Path
        d="M33.032 0s3.969 183.681 105.29 267.871L212.129 208C145.426 66.722 33.032 0 33.032 0z"
        fill="#aea8af"
      />
      <Path
        d="M138.323 267.871l32.684-26.514 2.454-21.745C155.64 178.724 108.831 82.115 33.032 0c0 0 3.97 183.681 105.291 267.871z"
        fill="#9c969e"
      />
      <Path
        d="M401.638 361.412c-8.914-15.482-14.206-32.579-13.566-50.215.038-1.053.058-2.111.058-3.172 0-60.659-59.156-109.832-132.129-109.832s-132.129 49.174-132.129 109.832c0 1.061.019 2.118.058 3.172.639 17.636-4.652 34.733-13.566 50.215-7.251 12.594-11.266 26.338-11.266 40.755C99.097 462.826 169.345 512 256 512s156.903-49.174 156.903-109.832c0-14.418-4.014-28.162-11.265-40.756z"
        fill="#c9c6ca"
      />
      <Path
        d="M159.656 415.105c0-16.695 4.015-32.608 11.266-47.191 8.914-17.926 14.206-37.723 13.566-58.144a117.893 117.893 0 01-.057-3.672c0-45.302 24.685-84.965 61.739-107.492-68.364 4.195-122.298 51.52-122.298 109.419 0 1.061.019 2.118.057 3.172.64 17.636-4.652 34.733-13.566 50.215-7.251 12.594-11.266 26.338-11.266 40.756 0 48.893 45.67 90.279 108.754 104.512-29.66-23.137-48.195-55.575-48.195-91.575z"
        fill="#c0bcc1"
      />
      <Path
        d="M181.677 388.129c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c.001 13.682-11.091 24.774-24.774 24.774z"
        fill="#4b3f4e"
      />
      <Path
        d="M181.677 330.323v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c.001-13.683-11.091-24.774-24.774-24.774z"
        fill="#5d5360"
      />
      <Circle cx={181.68} cy={346.84} r={8.258} fill="#fff" />
      <Path
        d="M330.323 388.129c-13.682 0-24.774-11.092-24.774-24.774v-8.258c0-13.682 11.092-24.774 24.774-24.774s24.774 11.092 24.774 24.774v8.258c0 13.682-11.092 24.774-24.774 24.774z"
        fill="#4b3f4e"
      />
      <Path
        d="M330.323 330.323v28.903c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-4.129c0-13.683-11.092-24.774-24.774-24.774z"
        fill="#5d5360"
      />
      <Circle cx={330.32} cy={346.84} r={8.258} fill="#fff" />
      <Path
        d="M330.323 412.903c-49.548-16.516-57.806-41.29-57.806-57.806s-8.258-24.774-16.516-24.774-16.516 8.258-16.516 24.774-8.258 41.29-57.806 57.806c-34.586 11.529-44.987 35.14-42.524 62.384C167.884 497.769 209.517 512 256 512s88.116-14.232 116.847-36.713c2.463-27.244-7.939-50.855-42.524-62.384z"
        fill="#dbd9dc"
      />
      <Path
        d="M284.012 500.988c-9.79 0-19.085-3.278-26.871-9.48-.649-.524-1.633-.52-2.282-.004-7.79 6.206-17.081 9.484-26.871 9.484-14.782 0-28.512-7.585-36.73-20.286-2.476-3.827-1.379-8.94 2.452-11.419 3.823-2.476 8.94-1.375 11.419 2.452 5.157 7.976 13.706 12.738 22.859 12.738 6.012 0 11.746-2.036 16.577-5.883 6.621-5.282 16.242-5.286 22.871.004 4.827 3.843 10.56 5.879 16.577 5.879 9.153 0 17.698-4.762 22.859-12.742 2.48-3.823 7.593-4.919 11.419-2.452 3.831 2.48 4.927 7.593 2.452 11.419-8.219 12.705-21.949 20.29-36.731 20.29z"
        fill="#c0bcc1"
      />
      <Path
        d="M256 412.903c20.787 0 37.638 12.262 37.638 27.389 0 14.588-15.818 22.269-27.234 27.949-6.617 3.292-14.19 3.292-20.807 0-11.416-5.68-27.234-13.361-27.234-27.949-.001-15.126 16.85-27.389 37.637-27.389z"
        fill="#ff96a0"
      />
      <Path
        d="M264.416 464.756c-11.416-6.727-27.234-15.826-27.234-33.105 0-6.234 2.076-12.036 5.611-16.98-14.262 3.896-24.431 13.881-24.431 25.621 0 14.587 15.818 22.269 27.234 27.949 6.617 3.292 14.19 3.292 20.807 0a827.9 827.9 0 002.834-1.416c-1.641-.487-3.261-1.15-4.821-2.069z"
        fill="#ff8087"
      />
      <Path
        d="M207.851 506.68c-27.293-21.29-44.97-50.498-47.65-83.068-17.48 12.784-22.899 31.203-21.048 51.675l-.021.045c18.344 14.33 41.934 25.305 68.719 31.348z"
        fill="#c9c6ca"
      />
    </Svg>
  )
}

export default Rabbit