import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function FlowerNine({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512.001 512.001">
      <Path
        d="M193.832 387.658c0 50.847 24.42 95.988 62.168 124.343l22.505-124.343L256 263.315c-37.748 28.355-62.168 73.496-62.168 124.343z"
        fill="#619243"
      />
      <Path
        d="M256 512.001c37.748-28.355 62.168-73.496 62.168-124.343 0-50.846-24.42-95.987-62.168-124.343v248.686z"
        fill="#88ad72"
      />
      <Path
        d="M355.046 360.864c48.358 15.712 98.836 6.438 137.468-20.701L381.21 280.335 256 263.315c15.303 44.664 50.688 81.837 99.046 97.549z"
        fill="#619243"
      />
      <Path
        d="M492.514 340.164c-15.303-44.663-50.688-81.837-99.047-97.549-48.356-15.714-98.835-6.438-137.467 20.7l236.514 76.849z"
        fill="#88ad72"
      />
      <Path
        d="M379.382 199.261c29.887-41.136 36.665-92.009 22.792-137.137l-91.295 87.367L256 263.315c47.205-.751 93.495-22.918 123.382-64.054z"
        fill="#619243"
      />
      <Path
        d="M402.173 62.124c-47.205.752-93.495 22.918-123.382 64.054-29.886 41.136-36.664 92.009-22.791 137.137L402.173 62.124z"
        fill="#88ad72"
      />
      <Path
        d="M233.209 126.179c-29.887-41.136-76.177-63.302-123.382-64.055l54.88 113.824L256 263.315c13.872-45.128 7.095-96.001-22.791-137.136z"
        fill="#619243"
      />
      <Path
        d="M109.826 62.124c-13.872 45.128-7.095 96.002 22.792 137.137S208.795 262.564 256 263.315L109.826 62.124z"
        fill="#88ad72"
      />
      <Path
        d="M118.532 242.614c-48.358 15.712-83.744 52.887-99.047 97.549l125.213-17.02L256 263.315c-38.632-27.138-89.111-36.414-137.468-20.701z"
        fill="#619243"
      />
      <Path
        d="M19.485 340.164c38.632 27.138 89.111 36.414 137.468 20.701 48.358-15.712 83.744-52.886 99.046-97.549L19.485 340.164z"
        fill="#88ad72"
      />
      <Path
        d="M375.521 316.177c61.388-19.947 118.326-75.343 130.905-134.231l-163.218-15.968c0-64.548-35.091-135.816-87.209-165.978L150.366 269.531l92.294 123.79c37.941 52.221 108.22 89.252 168.113 83.019l-145.48-155.66c36.179 8.006 75.896 6.652 110.228-4.503z"
        fill="#ffdb92"
      />
      <G fill="#ffebc2">
        <Path d="M279.393 299.404c18.599-35.675 30.057-86.329 30.057-133.425 0-64.548-21.507-135.816-53.451-165.978-46.509 26.915-79.434 86.565-85.988 144.962-57.563-11.813-124.468 1.068-164.438 36.984 11.226 52.549 57.773 102.315 111.285 126.593-29.014 51.094-37.433 118.694-15.63 167.801 53.16 5.532 115.122-23.696 154.771-66.7-4.54-5.298-9.368-10.852-13.34-16.319-20.124-27.699-33.371-62.731-37.729-97.737 9.121 6.178 19.159 11.424 29.831 15.721a312.98 312.98 0 01-5.86-14.591c15.387 2.585 32.589 3.445 50.492 2.689zM341.992 144.792c.299 7.292 1.216 14.413 1.216 21.189 0 46.624-18.314 96.744-48.122 132.357 23.486-2.174 47.608-6.988 70.003-14.264 61.388-19.947 122.523-62.425 141.338-102.124-39.786-35.753-107.051-48.69-164.435-37.158z" />
        <Path d="M375.521 316.177c-44.345 14.408-97.677 12.477-140.759-4.871 9.331 21.667 21.369 43.124 35.209 62.173 37.941 52.221 97.23 97.236 140.802 102.861 21.686-48.844 12.85-116.829-15.789-167.825-6.859 2.839-13.054 5.581-19.463 7.662z" />
      </G>
      <Circle cx={256} cy={263.311} r={67.52} fill="#ffdb92" />
      <Circle cx={256} cy={263.311} r={33.758} fill="#e8a44d" />
      <G fill="#ef7f4a">
        <Circle cx={256} cy={195.801} r={8.44} />
        <Circle cx={216.31} cy={208.691} r={8.44} />
        <Circle cx={191.79} cy={242.451} r={8.44} />
        <Circle cx={191.79} cy={284.181} r={8.44} />
        <Circle cx={216.31} cy={317.941} r={8.44} />
        <Circle cx={256} cy={330.831} r={8.44} />
        <Circle cx={295.69} cy={317.941} r={8.44} />
        <Circle cx={320.21} cy={284.181} r={8.44} />
        <Circle cx={320.21} cy={242.451} r={8.44} />
        <Circle cx={295.69} cy={208.691} r={8.44} />
      </G>
    </Svg>
  )
}

export default FlowerNine
