import React, { useContext, useEffect, useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { AuthContext } from '../../modules/AuthProvider';
import { checkEmail, getLocalStorage } from '../../../tools/tools';

import BaseStyle from '../../modules/BaseStyle';
import { HeaderNoSignIn } from '../../modules/HeaderNoSignIn';
import LogoLong from '../../../../../assets/img/LogoLong';
import { H1, H2, H3, Warning } from '../../modules/Title';
import Input from '../../modules/Input';
import Btn from '../../modules/Btn';
import Form from '../../modules/Form';

import styles from "./Forget.style";

const Forget = ({navigation}) => {

    const NB_TRY = 5

    const { login } = useContext(AuthContext)
    
    const [display,setDisplay] = useState(false)
    const [connexionTry, setConnexionTry] = useState(0);
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState({email: ''});
    const [warningMessage, setWarningMessage] = useState(null);

    // redirection si on est log
    useEffect(()=>{
        getLocalStorage()
            .then(user_param => {
                if (user_param) {
                    login()
                } else {
                    setDisplay(true)
                }
            })
    }, [])
    
    
    const handleInputChange = (value) => {
        setInputValue({
            ...inputValue,
            email: value
        })
    }

    const fetchData = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/Forget/forgetController.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {            
            response.json()
            .then(response => {
                if (response.success) {
                    setSuccess(true);
                } else {
                    setLoading(false)
                    setWarningMessage("Désolé, cet email n'est pas enregistré dans nos bases de données")
                    setConnexionTry(connexionTry + 1)
                    setInputValue({email: ''})
                }
            })
            .catch(error => console.error(error))
        }
    }
    
    const onSubmit = async function() {
        if (inputValue.email !== '' && checkEmail(inputValue.email)) {
            setLoading(true)
            fetchData();
        } else {
            setConnexionTry(connexionTry + 1)
            setWarningMessage("Désolé, cet email n'est pas enregistré dans nos bases de données")
        }
    }
    
    if (connexionTry > 0) {
        var tryCount = <Text className ='danger'>Email ou mot de passe invalide. {'\r\n'}
        Attention, plus que {(NB_TRY - connexionTry)} essais</Text>
    }

    if(display) {
        if (success) {
            return (
                <BaseStyle>
                    <HeaderNoSignIn />

                    <H1>Réinitialisation réussi !</H1>
                    <H2>Un email vous a été envoyé pour finaliser la réinitialisation de votre mot de passe.</H2>
                    <H3>(N'hésitez pas à vérifier dans votre boite de courriers indésirables)</H3>

                    <Text onPress={()=>{navigation.navigate('LandingPage')}} style={styles.basicLink}>Accueil</Text>
                </BaseStyle>
            )
        } else {
            if (connexionTry < NB_TRY) {
                return (
                    <BaseStyle>
                        <HeaderNoSignIn navigation={navigation}/>
            
                        <KeyboardAwareScrollView>
                            <View style={styles.container}>
            
                                <LogoLong style={styles.logo}/>
    
                                {loading ? (
                                    <View style={styles.loader}>
                                        <H1>Réinitialisation en cours, veuillez patienter nous traitons votre demande</H1>
                                        <ActivityIndicator size="large" color="#57B5B5"/>
                                    </View>
                                ) : (
                                    <Form>
                                        {warningMessage && <Warning>{warningMessage}</Warning>}
                                        <H1>Mot de passe oublié</H1>
                                        {tryCount}
                                        <Input placeholder='Email' onInputChange={value=>{handleInputChange(value)}} />
                                        
                                        <Btn text='OK' color='green' action={onSubmit}/>
    
                                        <Text onPress={()=>{navigation.navigate('LandingPage')}} style={styles.basicLink}>Accueil</Text>
                                    </Form>
                                )}
                                
                                {loading ? <></> : <Text onPress={()=>{navigation.navigate('LandingPage')}} style={styles.basicLink}>Accueil</Text>}
                            </View>
                        </KeyboardAwareScrollView>
                    </BaseStyle>
                );
            } else {
                return <Text>Trop d'essais</Text>
            }
        }
    } else {
        return (
            <BaseStyle>
                <LogoLong />
                <ActivityIndicator size="large" color="#57B5B5"/>
            </BaseStyle>
        )
    }
};

export default Forget;