import React from "react"
import { Svg, Path } from "react-native-svg"

function FlowerTen({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M185.379 512C224.382 512 256 480.382 256 441.379V256H70.621C31.618 256 0 287.618 0 326.621 0 429.003 82.997 512 185.379 512z"
        fill="#ff5a5a"
      />
      <Path
        d="M326.621 512C287.618 512 256 480.382 256 441.379V256h185.379C480.382 256 512 287.618 512 326.621 512 429.003 429.003 512 326.621 512z"
        fill="#ff464b"
      />
      <Path
        d="M326.621 0C287.618 0 256 31.618 256 70.621V256h185.379C480.382 256 512 224.382 512 185.379 512 82.997 429.003 0 326.621 0z"
        fill="#ff5a5a"
      />
      <Path
        d="M185.379 0C224.382 0 256 31.618 256 70.621V256H70.621C31.618 256 0 224.382 0 185.379 0 82.997 82.997 0 185.379 0z"
        fill="#ff464b"
      />
      <Path
        d="M256 150.069c-58.505 0-105.931 47.426-105.931 105.931H256V150.069z"
        fill="#ff8c4e"
      />
      <Path
        d="M361.931 256c0-58.505-47.426-105.931-105.931-105.931V256h105.931z"
        fill="#ffa555"
      />
      <Path
        d="M256 361.931c58.505 0 105.931-47.426 105.931-105.931H256v105.931z"
        fill="#ff8c4e"
      />
      <Path
        d="M150.069 256c0 58.505 47.426 105.931 105.931 105.931V256H150.069z"
        fill="#ffa555"
      />
      <Path
        d="M401.849 144.47c-2.962-3.866-8.504-4.625-12.375-1.651l-86.243 65.951 65.951-86.243a8.825 8.825 0 00-1.651-12.375c-3.866-2.957-9.409-2.22-12.375 1.651L249.704 249.704 111.801 355.155a8.827 8.827 0 005.37 15.841c1.866 0 3.754-.59 5.354-1.815l86.243-65.951-65.951 86.243a8.825 8.825 0 001.651 12.375 8.788 8.788 0 005.357 1.815 8.811 8.811 0 007.017-3.466l105.452-137.901 137.901-105.452a8.823 8.823 0 001.654-12.374z"
        fill="#d7464b"
      />
      <Path
        d="M400.199 355.155L262.297 249.703 156.845 111.801a8.827 8.827 0 10-14.026 10.724l65.951 86.243-86.243-65.951c-3.88-2.974-9.422-2.216-12.375 1.651a8.825 8.825 0 001.651 12.375l137.901 105.452 105.452 137.901a8.811 8.811 0 007.017 3.466c1.87 0 3.758-.59 5.357-1.815a8.827 8.827 0 001.651-12.375L303.23 303.23l86.243 65.951a8.786 8.786 0 005.354 1.815 8.813 8.813 0 007.021-3.466 8.827 8.827 0 00-1.649-12.375z"
        fill="#cd4146"
      />
      <Path
        d="M335.448 256c0-11.886-7.884-21.825-18.669-25.175 5.258-9.995 3.805-22.598-4.6-31.003-8.405-8.405-21.009-9.858-31.003-4.6-3.351-10.786-13.29-18.67-25.176-18.67s-21.825 7.884-25.175 18.669c-9.995-5.258-22.598-3.805-31.003 4.6-8.405 8.405-9.858 21.009-4.6 31.002-10.786 3.351-18.67 13.29-18.67 25.176s7.884 21.825 18.668 25.175c-5.257 9.995-3.804 22.598 4.601 31.003s21.009 9.858 31.002 4.6c3.351 10.786 13.29 18.67 25.176 18.67s21.825-7.884 25.175-18.669c9.995 5.258 22.598 3.805 31.003-4.6s9.858-21.007 4.601-31.003c10.786-3.35 18.67-13.289 18.67-25.175z"
        fill="#91323c"
      />
      <Path
        d="M256 308.966c-29.207 0-52.966-23.758-52.966-52.966s23.758-52.966 52.966-52.966 52.966 23.758 52.966 52.966-23.759 52.966-52.966 52.966zm0-88.276c-19.47 0-35.31 15.84-35.31 35.31s15.84 35.31 35.31 35.31 35.31-15.84 35.31-35.31-15.84-35.31-35.31-35.31z"
        fill="#af3a41"
      />
    </Svg>
  )
}

export default FlowerTen
