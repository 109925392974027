import React, { useContext, useEffect, useState, useRef } from 'react';
import { ActivityIndicator, ScrollView, Text, TouchableOpacity, View, Modal, Alert, Linking, Platform, Share } from 'react-native';
import { AuthContext } from '../../modules/AuthProvider';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import * as MediaLibrary from 'expo-media-library';
import * as Sharing from 'expo-sharing';
import Constants from 'expo-constants';
import { AdMobInterstitial } from 'expo-ads-admob';

import BaseStyle from '../../modules/BaseStyle';
import DynamicAvatarComponent from '../../modules/DynamicAvatarComponent';
import { H1, Warning} from '../../modules/Title';
import MenuBurger from '../../modules/MenuBurger';
import Btn, { BtnSave, BtnShare } from '../../modules/Btn';
import PointMenu from '../../../../../assets/img/PointMenu';
import Input from '../../modules/Input';
import { decodeHtml, dateToFrench } from '../../../tools/tools';
import ModalPepiteKid from '../../modules/ModalPepiteKid';

import styles from "./Kid.style"

const Kid = ({route, navigation}) => {

    const {user, updateUser} = useContext(AuthContext)
    const [kid, setKid] = useState(null)
    const [inputValue, setInputValue] = useState({sentence: '',kidId: ''})
    const [isScroll, setIsScroll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorInput, setErrorInput] = useState(false);
    const [modalPepiteVisible, setModalPepiteVisible] = useState(false);
    const [dataPepite, setDataPepite] = useState('');

    // initialisation du state avec remise a zéro quand kid change
    useEffect(()=>{
        navigation.setOptions({ title: `Kidywoo | ${route.params.name}` })
        
        for (let i = 0; i < user.kids.length; i++) {
            if(user.kids[i].name === route.params.name){
                setKid(user.kids[i])
                break
            }
        }
    })
    useEffect(()=>{
        if (kid) {
            const userWithoutKid = {...user}
            delete userWithoutKid['kids']
    
            setInputValue({
                kidId: kid.kid_id,
                sentence: '',
                user_param: userWithoutKid
            })
        }
    }, [kid])

    const handleInputChange = (value) => {
        setInputValue({
            ...inputValue,
            sentence: value
        })
    }
    const handleScroll = (event) => {
        if (event.nativeEvent.contentOffset.y > 325) {
            setIsScroll(true)
        } else {
            setIsScroll(false)
        }
    }
    const handlePepiteModal = (data) => {
        setDataPepite(data)
        setModalPepiteVisible(true)
    }
    
    const fetchData = async () => {
        const response = await fetch('https://api.kidywoo.com/POST_request/addSentence.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(inputValue)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                    if (response.success) {
                        updateUser(response.user_param)
                        setInputValue({
                            ...inputValue,
                            sentence: ''
                        })
                    } else {
                        setError(true)
                        setTimeout(() => {setError(false)}, 5000);
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    setLoading(false)
                    setError(true)
                    setTimeout(() => {setError(false)}, 5000);
                })
        }
    }

    const onsubmit = () => {
        setErrorInput(false)
        if (inputValue.sentence !== '') {
            setLoading(true);
            fetchData()
        } else {
            setErrorInput(true)
            setTimeout(() => {setErrorInput(false)}, 5000);
        }
    }

    const textWrap = (string, width) => string.replace(new RegExp(`(?![^\\n]{1,${width}}$)([^\\n]{1,${width}})\\s`, 'g'), '$1|').split('|');
    const kidAge = (date) => {
        const dif = new Date(date.replace(/-/g, "/")) - new Date(kid.birthday.replace(/-/g, "/"))
        const age = (dif/86400000)/365;

        if (Math.round(age) > age) {
            return Math.floor(age) + " ans et demi"
        }
        else {
            return Math.floor(age)+" ans"
        }
    }

    const fetchImage = async (data, action) => {
        const response = await fetch('https://api.kidywoo.com/POST_request/CreateImages/createPepiteImage.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(data)
        })
        if (response.ok) {
            response.json()
            .then(response => {
                if (action === "save") {
                    SaveToCameraRoll(response)
                } else {
                    ShareImage(response)
                }
            })
            .catch(error => {
                console.error(error)
            })
        }
    }

    const onClickSaveImage = (date, content) => {
        const fullName = kid.name + ", " + kidAge(date)
        const contentArray = textWrap(content, 35)
        const data = {
            name: fullName,
            pepite: contentArray,
        }
        fetchImage(data, 'save')
    }
    const SaveToCameraRoll = async (response) => {
        const base64Code = response.split("data:image/png;base64,")[1];

        let galleryAccessPermission = await Permissions.getAsync(Permissions.CAMERA_ROLL)
        if (galleryAccessPermission.status !== 'granted') {
            galleryAccessPermission = await Permissions.askAsync(Permissions.CAMERA_ROLL)
        }

        if (galleryAccessPermission.status === 'granted') {
            const filename = FileSystem.documentDirectory + 'KidyWoo_' + Date.now() + '.png';
            await FileSystem.writeAsStringAsync(filename, base64Code, {
                encoding: FileSystem.EncodingType.Base64,
            });

            await MediaLibrary.saveToLibraryAsync(filename)
                .then(()=>{
                    Alert.alert(
                        "Image enregistrée",
                        `Votre pépite est désormais dans votre galerie d'image`,
                        [
                            {text: "OK"}
                        ]
                    )
                })
                .catch(error=> console.log(error))
        } else {
            if (Platform.OS === 'ios') {
                Alert.alert(
                    "Permission requise",
                    `Pour sauvegarder vos pépites sur votre téléphone, KidyWoo a besoin d'accéder à votre galerie d'image`,
                    [
                        {text: "Non merci"},
                        {text: "Modifier", onPress: () => {Linking.openURL('app-settings:')}}
                    ]
                )
            } else {
                Alert.alert(
                    "Permission requise",
                    "Pour sauvegarder vos pépites sur votre téléphone, KidyWoo a besoin d'accéder à votre galerie d'image.",
                    [
                        {text: "OK"}
                    ]
                )
            }
        }
    }

    const onClickShareImage = (date, content) => {
        const fullName = kid.name + ", " + kidAge(date)
        const contentArray = textWrap(content, 35)
        const data = {
            name: fullName,
            pepite: contentArray,
        }
        fetchImage(data, 'share')
    }
    const ShareImage = async (response) => {
        const base64Code = response.split("data:image/png;base64,")[1];

        let galleryAccessPermission = await Permissions.getAsync(Permissions.CAMERA_ROLL)
        if (galleryAccessPermission.status !== 'granted') {
            galleryAccessPermission = await Permissions.askAsync(Permissions.CAMERA_ROLL)
        }
        
        if (galleryAccessPermission.status === 'granted') {
            const filename = FileSystem.documentDirectory + 'KidyWoo_.png';
            await FileSystem.writeAsStringAsync(filename, base64Code, {
                encoding: FileSystem.EncodingType.Base64,
            })
            .then(()=>{
                if (Platform.OS === 'ios') {
                    // expo-sharing instagram ne fonctionne pas donc on utilise Share from react-native
                    Share.share({
                        url:response
                    });
                } else {
                    // expo-sharing a + d'option que Share from react-native
                    Sharing.shareAsync(filename);
                }
            })
            .then(AdMobInterstitial.showAdAsync())
            .catch(error => {
                console.error(error); 
            })
        }
    }

    return (
        <BaseStyle>
            <MenuBurger navigation={navigation} />

            {kid === null ? (
                <View style={{width: '100%'}}>
                    <H1>Chargement du contenu de {route.params.name} en cours</H1>
                    <ActivityIndicator size="large" color="#57B5B5"/>
                </View>
            ) : (
                <>
                <View style={[styles.smallAvatar, {opacity: isScroll ? 1 : 0},{zIndex: isScroll ? 10 : 0}]}>
                    <DynamicAvatarComponent size={{width: 50, height: 50}} tag={kid.avatar} />
                    <Text style={styles.smallName}>{kid.name}</Text>
                </View>
                    <KeyboardAwareScrollView onScroll={event=>{handleScroll(event)}} contentContainerStyle={{alignItems: 'center'}} style={{width: '100%'}}>
                        <ScrollView contentContainerStyle={{alignItems: 'center'}} style={styles.container}>
                            <View style={styles.avatarHeader}>
                                <DynamicAvatarComponent size={{width: 200, height: 200}} tag={kid.avatar} />
                                <H1>{kid.name}</H1>
                                {/* Modification Pépites en dehors de la boucle pour éviter qu'elle ne s'affiche 10000x*/}
                                <Modal
                                    style={styles.modal}
                                    visible={modalPepiteVisible}
                                    animationType="fade"
                                    transparent={true}
                                >
                                    <ModalPepiteKid data={dataPepite} kidName={kid.name} closeModal={()=>{setModalPepiteVisible(false)}} />
                                </Modal>
                            </View>
                            
                            <View style={styles.addSentence}>
                                {loading ? (
                                    <View style={{width: '100%'}}>
                                        <H1>Sauvegarde de la pépite</H1>
                                        <ActivityIndicator size="large" color="#57B5B5"/>
                                    </View>
                                ) : (
                                    <>  
                                        {error && <Warning>Désolé, une erreur s'est produite. Veuillez réessayer</Warning>}
                                        {errorInput && <Warning>Vous ne pouvez pas poster une pépite vide</Warning>}
                                        <Input value={inputValue.sentence} placeholder="+ Nouvelle pépite" onInputChange={value=>{handleInputChange(value)}} height={120} multiline /> 
                                        <Btn text='OK' color='green' action={()=>{onsubmit()}} />
                                    </>
                                )}
                            </View>

                            {kid.data.map((data, i) => (
                                <View key={i} style={styles.card}>
                                    <View style={styles.cardHeader}>
                                        <Text style={styles.date}>{dateToFrench(data.created_at)}</Text>
                                        <TouchableOpacity onPress={()=>{handlePepiteModal(data)}}>
                                            <PointMenu style={{position: 'absolute'}}/>
                                        </TouchableOpacity>
                                    </View>
                                    <Text style={styles.pepite}>{decodeHtml(data.content)}</Text>
                                    
                                        {Platform.OS !== 'web' ? (
                                            <View style={styles.shareContainer}>
                                                <BtnSave action={()=>{
                                                        onClickSaveImage(data.created_at, decodeHtml(data.content))}
                                                    } />
                                                <BtnShare action={()=>{onClickShareImage(data.created_at, decodeHtml(data.content))}} />
                                            </View>
                                        ) : 
                                            <Text style={styles.note}>
                                                (Le partage et l'enregistrement de pépite sur votre appareil sont uniquement accessibles via les applications mobiles)
                                            </Text>
                                        }                                        
                                </View>
                            ))}
                                
                        </ScrollView>
                    </KeyboardAwareScrollView>
                </>
            )}
        </BaseStyle>
    )
}

export default Kid