import React from "react"
import { Svg, Circle } from "react-native-svg"

function PointMenu() {
  return (
    <Svg 
      viewBox="0 0 512 512" 
      fill="#fff"
      height={20}
      width={20}
    >
      <Circle cx={256} cy={256} r={64} />
      <Circle cx={256} cy={448} r={64} />
      <Circle cx={256} cy={64} r={64} />
    </Svg>
  )
}

export default PointMenu
