import React from "react"
import { Svg, Path, Ellipse, G } from "react-native-svg"

function Penguin({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Penguin"
      x={0}
      y={0}
      viewBox="0 0 511.999 511.999">
      <Path
        d="M364.979 149.528a16.034 16.034 0 0121-11.316c1.441.517 35.625 13.119 64.081 51.88 16.441 22.395 27.748 49.027 33.605 79.152 7.15 36.779 6.127 78.895-3.042 125.179a16.032 16.032 0 01-14.277 12.851c-.042.004-.084.009-.125.01-11.611.422-41.118-27.741-52.418-40.84-21.718-25.173-37.491-53.548-46.881-84.337-12.595-41.289-13.248-85.896-1.943-132.579z"
        fill="#3e3d42"
      />
      <Path
        d="M372.956 139.188a16.03 16.03 0 0113.023-.976c.839.301 12.772 4.708 28.205 15.967 3.661 37.072 26.763 82.962 27.834 85.172 4.026 7.502 27.316 53.135 27.316 104.464 0 22.305-3.516 42.874-10.366 61.432-13.819-6.641-35.774-27.915-45.166-38.8-21.718-25.173-37.491-53.548-46.881-84.337-12.593-41.292-13.246-85.899-1.941-132.582a16.025 16.025 0 017.976-10.34z"
        fill="#2d2d30"
      />
      <Path
        d="M147.02 149.528a16.034 16.034 0 00-21-11.316c-1.441.517-35.625 13.119-64.081 51.88-16.441 22.395-27.748 49.027-33.605 79.152-7.15 36.779-6.127 78.895 3.042 125.179a16.032 16.032 0 0014.277 12.851c.042.004.084.009.125.01 11.611.422 41.118-27.741 52.418-40.84 21.718-25.173 37.491-53.548 46.881-84.337 12.594-41.289 13.247-85.896 1.943-132.579z"
        fill="#3e3d42"
      />
      <Path
        d="M139.043 139.188a16.03 16.03 0 00-13.023-.976c-.839.301-12.772 4.708-28.205 15.967-3.661 37.072-26.763 82.962-27.835 85.173-4.026 7.502-27.316 53.135-27.316 104.464 0 22.305 3.516 42.874 10.366 61.432 13.819-6.641 35.774-27.915 45.166-38.8 21.718-25.173 37.491-53.548 46.881-84.337 12.593-41.292 13.246-85.899 1.941-132.582a16.02 16.02 0 00-7.975-10.341z"
        fill="#2d2d30"
      />
      <Path
        d="M418.758 251.255c-9.848-20.46-31.959-56.746-31.781-120.277C387.179 58.641 328.337 0 255.999 0S124.82 58.641 125.023 130.977c.178 63.531-21.933 99.817-31.781 120.277-.033.069-24.454 44.103-24.454 92.561 0 103.395 83.818 143.982 187.212 143.982s187.212-40.588 187.212-143.982c0-48.457-24.421-92.492-24.454-92.56z"
        fill="#57565c"
      />
      <Path
        d="M116.974 343.815c0-48.458 24.42-92.492 24.454-92.561 9.848-20.46 31.959-56.746 31.781-120.277-.18-64.108 46.019-117.45 106.885-128.759A131.874 131.874 0 00255.999 0c-72.337 0-131.18 58.641-130.977 130.977.178 63.531-21.933 99.817-31.781 120.277-.033.069-24.454 44.103-24.454 92.561 0 103.395 83.818 143.982 187.212 143.982 8.164 0 16.205-.255 24.093-.771-92.014-6.031-163.118-47.98-163.118-143.211z"
        fill="#3e3d42"
      />
      <Path
        d="M403.557 347.608c0 81.494-66.064 112.403-147.557 112.403s-147.557-30.909-147.557-112.403S174.507 200.052 256 200.052s147.557 66.064 147.557 147.556z"
        fill="#fff"
      />
      <G fill="#e0e0e2">
        <Path d="M309.5 422.824c-1.377-4.102.963-8.532 5.088-9.846 9.23-2.941 17.277-6.692 23.92-11.148 17.646-11.839 25.864-29.067 25.864-54.222a7.837 7.837 0 0115.674 0c0 30.435-10.731 52.428-32.807 67.238-7.87 5.28-17.254 9.676-27.894 13.065-.79.254-7.444 2.064-9.845-5.087zM255.999 436.502a7.837 7.837 0 010-15.674c11.258 0 21.966-.675 31.827-2.008a7.832 7.832 0 018.816 6.717 7.837 7.837 0 01-6.717 8.816c-10.554 1.426-21.969 2.149-33.926 2.149z" />
      </G>
      <Path
        d="M199.052 463.595h-48.405c-13.311 0-24.202 10.891-24.202 24.202 0 13.311 10.891 24.202 24.202 24.202h48.405c13.311 0 24.202-10.891 24.202-24.202.001-13.311-10.889-24.202-24.202-24.202z"
        fill="#ffe477"
      />
      <Path
        d="M199.052 463.595h-27.595c13.311 0 24.202 10.891 24.202 24.202 0 13.311-10.891 24.202-24.202 24.202h27.595c13.311 0 24.202-10.891 24.202-24.202.001-13.311-10.889-24.202-24.202-24.202z"
        fill="#ffd422"
      />
      <Path
        d="M361.351 463.595h-48.405c-13.311 0-24.202 10.891-24.202 24.202 0 13.311 10.891 24.202 24.202 24.202h48.405c13.311 0 24.202-10.891 24.202-24.202 0-13.311-10.891-24.202-24.202-24.202z"
        fill="#ffe477"
      />
      <Path
        d="M312.946 463.595h27.595c-13.311 0-24.202 10.891-24.202 24.202 0 13.311 10.891 24.202 24.202 24.202h-27.595c-13.311 0-24.202-10.891-24.202-24.202-.001-13.311 10.89-24.202 24.202-24.202z"
        fill="#ffd422"
      />
      <Ellipse
        cx={255.999}
        cy={131.741}
        rx={48.191}
        ry={22.841}
        fill="#ffe477"
      />
      <G fill="#1d1d1f">
        <Path d="M210.958 89.586a9.212 9.212 0 01-6.503-2.7 9.235 9.235 0 01-2.7-6.503c0-2.418.981-4.798 2.7-6.504a9.211 9.211 0 016.503-2.699 9.213 9.213 0 019.203 9.203 9.214 9.214 0 01-9.203 9.203zM303.257 89.586a9.212 9.212 0 01-6.503-2.7 9.235 9.235 0 01-2.7-6.503c0-2.418.981-4.798 2.7-6.504a9.211 9.211 0 016.503-2.699 9.213 9.213 0 019.203 9.203 9.214 9.214 0 01-9.203 9.203z" />
      </G>
      <Path
        d="M207.814 131.74c0 12.615 21.574 22.841 48.185 22.841s48.185-10.226 48.185-22.841c0-.886-.117-1.759-.325-2.617h-95.723a11.24 11.24 0 00-.322 2.617z"
        fill="#ffd422"
      />
    </Svg>
  )
}

export default Penguin
