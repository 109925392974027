import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function PolarBear({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Polar Bear"
      x={0}
      y={0}
      viewBox="0 0 511.999 511.999">
      <Circle cx={68.065} cy={102.676} r={64.601} fill="#d4d3d3" />
      <Path
        d="M68.065 135.821c-18.278 0-33.149-14.871-33.149-33.148 0-18.279 14.871-33.149 33.149-33.149s33.148 14.871 33.148 33.149c.001 18.278-14.87 33.148-33.148 33.148z"
        fill="#f09a99"
      />
      <Circle cx={443.936} cy={102.676} r={64.601} fill="#d4d3d3" />
      <Path
        d="M443.936 135.821c18.279 0 33.148-14.871 33.148-33.148 0-18.279-14.871-33.149-33.148-33.149-18.279 0-33.149 14.871-33.149 33.149s14.871 33.148 33.149 33.148z"
        fill="#f09a99"
      />
      <Path
        d="M256.001 48.556c-96.229 0-200.437 30.994-239.711 127.426C-2.764 222.768-3.974 276.05 6.823 324.978c4.563 20.675 11.26 41.239 21.238 59.972 18.967 35.603 46.332 38.606 83.185 48.267 16.602 4.353 32.269 10.482 47.749 17.82 32.16 15.248 60.863 22.887 97.004 22.887s64.844-7.639 97.004-22.887c15.48-7.339 31.147-13.469 47.749-17.82 36.853-9.661 64.218-12.664 83.185-48.267 9.979-18.732 16.676-39.297 21.238-59.972 10.797-48.928 9.589-102.209-9.466-148.996C456.438 79.55 352.23 48.556 256.001 48.556z"
        fill="#eeeeec"
      />
      <Path
        d="M69.27 407.323c-9.979-19.977-16.677-41.91-21.238-63.96-10.797-52.183-9.589-109.008 9.465-158.905C94.026 88.799 186.73 53.523 276.94 49.059a437.154 437.154 0 00-20.939-.504c-96.229 0-200.437 30.994-239.711 127.426-19.055 46.787-20.264 100.068-9.467 148.996 4.563 20.675 11.26 41.239 21.238 59.972 13.325 25.015 30.804 33.932 52.8 40.377-4.165-4.957-8.026-10.865-11.591-18.003z"
        fill="#d4d3d3"
      />
      <G fill="#424342">
        <Circle cx={162.882} cy={208.146} r={17.242} />
        <Circle cx={349.119} cy={208.146} r={17.242} />
        <Path d="M298.456 381.536c-15.237 0-28.204-9.869-32.871-23.546 36.979-4.717 58.736-35.633 45.641-44.799-14.19-9.933-27.67 7.095-55.226 7.095s-41.036-17.027-55.226-7.095c-13.095 9.165 8.663 40.082 45.642 44.799-4.667 13.678-17.634 23.546-32.871 23.546a7.726 7.726 0 100 15.452c17.853 0 33.558-9.372 42.455-23.453 8.897 14.082 24.602 23.453 42.455 23.453a7.726 7.726 0 007.726-7.726 7.724 7.724 0 00-7.725-7.726z" />
      </G>
    </Svg>
  )
}

export default PolarBear