import React from "react"
import { Svg, Path, Ellipse, Circle } from "react-native-svg"

function Dog({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Dog"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M437.677 45.419h-82.581C325.161 57.29 318.525 37.161 256 37.161s-69.161 20.129-99.097 8.258h-82.58L63.312 252.904c6.882 81.548 57.462 125.935 97.377 142.107h190.623c39.914-16.172 90.495-60.559 97.377-142.107L437.677 45.419z"
        fill="#f5dcb4"
      />
      <Path
        d="M355.097 45.419C448.078 73.25 397.46 216.774 457.33 278.813c8.595 8.906 23.781 4.95 27.368-6.895 4.74-15.651 9.474-40.411 8.721-75.789C492.387 147.613 512 144.517 512 144.517S487.226 28.903 412.903 28.903c-24.774 0-57.806 16.516-57.806 16.516z"
        fill="#965a50"
      />
      <Path
        d="M169.29 260.129c-15.897 0-28.903-13.006-28.903-28.903V214.71c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c.001 15.898-13.006 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M169.29 185.806c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516V214.71c.001-15.896-13.006-28.904-28.903-28.904z"
        fill="#5d5360"
      />
      <Circle cx={169.29} cy={206.451} r={12.387} fill="#fff" />
      <Path
        d="M342.71 260.129c-15.897 0-28.903-13.006-28.903-28.903V214.71c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c0 15.898-13.007 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M342.71 185.806c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516V214.71c0-15.896-13.007-28.904-28.903-28.904z"
        fill="#5d5360"
      />
      <Circle cx={342.71} cy={206.451} r={12.387} fill="#fff" />
      <Path
        d="M457.33 278.814c8.595 8.906 23.781 4.95 27.368-6.896 2.718-8.976 5.399-21.06 7.08-36.313-16.234-8.092-30.092-33.91-38.617-77.669C437.677 78.452 416 34.065 355.097 45.419 448.078 73.25 397.46 216.775 457.33 278.814z"
        fill="#824641"
      />
      <Path
        d="M160.689 395.011h39.141s-68.732-46.107-80.087-127.656C97.654 108.712 139.7 61.247 176.067 49.135c-5.761.195-11.745-.774-19.163-3.715H74.323L63.312 252.904c6.882 81.548 57.462 125.935 97.377 142.107z"
        fill="#e7c9a5"
      />
      <Path
        d="M156.903 45.419C63.922 73.25 114.541 216.775 54.67 278.813c-8.595 8.906-23.781 4.95-27.368-6.895-4.74-15.651-9.474-40.411-8.721-75.789C19.613 147.613 0 144.517 0 144.517S24.774 28.903 99.097 28.903c24.774 0 57.806 16.516 57.806 16.516z"
        fill="#965a50"
      />
      <Path
        d="M154.139 44.104c-8.38-3.888-34.516-15.201-55.042-15.201C24.774 28.903 0 144.517 0 144.517s19.613 3.097 18.581 51.613c-.014.648.005 1.231-.005 1.872C66.097 17.715 139.119 37.773 154.139 44.104z"
        fill="#824641"
      />
      <Ellipse cx={256} cy={400.52} rx={90.84} ry={66.06} fill="#bc8e78" />
      <Path
        d="M214.71 400.516v41.29c0 22.804 18.486 41.29 41.29 41.29s41.29-18.486 41.29-41.29v-41.29h-82.58z"
        fill="#ff8087"
      />
      <Path
        d="M214.71 432.768c11.941-1.496 23.208-5.129 33.032-10.622v11.402a8.259 8.259 0 0016.516 0v-11.507c9.729 5.552 21.026 9.225 33.032 10.73v-32.256h-82.58v32.253z"
        fill="#e6646e"
      />
      <Path
        d="M309.677 326.194H202.323c-34.206 0-61.935 20.335-61.935 45.419s27.73 45.419 61.935 45.419c17.225 0 32.782-5.169 43.995-13.498 2.839-2.108 6.262-3.162 9.679-3.162 3.423 0 6.846 1.053 9.685 3.162 11.213 8.329 26.77 13.498 43.995 13.498 34.206 0 61.935-20.335 61.935-45.419.001-25.084-27.729-45.419-61.935-45.419z"
        fill="#d4af91"
      />
      <Path
        d="M256 293.161c27.365 0 49.548 14.789 49.548 33.032 0 17.594-20.824 26.858-35.853 33.708a32.98 32.98 0 01-27.392 0c-15.029-6.85-35.853-16.114-35.853-33.708.002-18.242 22.185-33.032 49.55-33.032z"
        fill="#5d5360"
      />
      <Path
        d="M230.968 317.161c-1.155-10.393 6.166-21.507 6.166-21.507-18.003 4.949-30.682 16.751-30.682 30.539 0 17.593 20.824 26.857 35.853 33.708a32.988 32.988 0 0027.392 0c3.232-1.473 6.732-3.069 10.235-4.824-20.319-3.593-46.642-17.012-48.964-37.916z"
        fill="#4b3f4e"
      />
    </Svg>
  )
}

export default Dog