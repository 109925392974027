import React from "react"
import { Svg, Path, G } from "react-native-svg"

function ShareImage() {
  return (
    <Svg 
      viewBox="0 0 60 60"
      height='20px'
      width='20px'
    >
      <G fill="#57B5B5" fillRule="nonzero">
        <Path d="M21 17a4 4 0 100 8 4 4 0 000-8zm0 6a2 2 0 110-4 2 2 0 010 4z" />
        <Path d="M55 33.851V16a7.008 7.008 0 00-7-7h-2.031l-.564-3.212A7.012 7.012 0 0037.3.105L5.788 5.665a7.01 7.01 0 00-5.683 8.109l3.821 21.673a6.983 6.983 0 005.814 5.667A7 7 0 0016 45h14a15 15 0 1025-11.149zM5.9 35.1L2.075 13.428a5.007 5.007 0 014.059-5.793l31.508-5.56a5.006 5.006 0 015.793 4.058L43.939 9H16a7.008 7.008 0 00-7 7v22c.002.298.023.596.063.892A5 5 0 015.9 35.1zM16 43a5.006 5.006 0 01-5-5V16a5.006 5.006 0 015-5h32a5.006 5.006 0 015 5v16.333a14.983 14.983 0 00-2-1.072V16a3 3 0 00-3-3H16a3 3 0 00-3 3v22a3 3 0 003 3h14.558a15.014 15.014 0 00-.409 2zm33-16.828l-6.586-6.586a2 2 0 00-2.828 0L29 30.172l-3.565-3.565a2.048 2.048 0 00-2.828 0L15 34.213V16a1 1 0 011-1h32a1 1 0 011 1zm-34 10.87l9.021-9.021 3.565 3.565a2 2 0 002.828 0L41 21l8 8v1.558A14.922 14.922 0 0031.261 39H16a1 1 0 01-1-1zM45 58c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13c-.008 7.176-5.824 12.992-13 13z" />
        <Path d="M49 43a4 4 0 10-4-4c.004.328.048.653.133.97l-4.223 2.3a4 4 0 100 5.452l4.223 2.3c-.085.32-.13.648-.133.978a4.031 4.031 0 101.09-2.726l-4.223-2.3a3.6 3.6 0 000-1.94l4.223-2.3A3.974 3.974 0 0049 43zm0 6a2 2 0 110 4 2 2 0 010-4zm-11-2a2 2 0 110-4 2 2 0 010 4zm11-10a2 2 0 110 4 2 2 0 010-4z" />
      </G>
    </Svg>
  )
}

export default ShareImage