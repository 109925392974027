import { StyleSheet } from "react-native";  

export default StyleSheet.create({
    container:{
        flex: 1,
        width: '100%'
    },
    kidList: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    kidCard:{
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
        width: 200,
        borderRadius: 20,
        backgroundColor: '#FCF1DF',
        padding: 10,
        margin: 10
    },
    basicLink:{
        color: "#e1a0a2",
    },
    buttonGreen:{
        width: '70%',
        height: 70,
        borderRadius: 15,
        backgroundColor: '#57B5B5',
        marginBottom: 50
    },
    buttonGreenText:{
        textAlign:'center',
        lineHeight: 70,
        fontSize: 15,
        color: '#fff',
        fontWeight: 'bold',
    },
    welcomeTextContainer:{
        alignItems: 'center',
        padding: 10
    },
    welcomeText: {
        color: '#337373',
        textAlign: 'center',
        marginBottom: 20,
        fontSize: 20
    }
})