import React from 'react';
import { Dimensions, Platform, StyleSheet, TouchableHighlight } from "react-native"
import { DrawerActions } from '@react-navigation/native';
import Burger from '../../../../assets/img/Burger';

export const MenuBurger = ({navigation}) => {
    if (Platform.OS !== 'web' || (Platform.OS === 'web' && Dimensions.get('window').width <= 700)) {
        return (
            <TouchableHighlight
                underlayColor='rgba(255,255,255,0.2)'
                style={styles.burger}
                onPress={() => navigation.dispatch(DrawerActions.openDrawer())}>
                <Burger />
            </TouchableHighlight> 
        )
    }
    return <></>
}

const styles = StyleSheet.create({
    burger:{
        zIndex: 1000,
        position: 'absolute',
        top: 10,
        right: 10, 
        borderRadius: 50
    }
});

export default MenuBurger