import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';

const Form = ({children}) =>{
    return (
        <View style={styles.default}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    default: {
        justifyContent: 'center',
        alignItems: 'center',
        width: ((Dimensions.get('window').width)*90)/100,
        maxWidth: 500,
        marginBottom: 20,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 15,
        backgroundColor: "#FCF1DF"
    }
})

export default Form;