import React from "react"
import { Svg, Path, Circle } from "react-native-svg"

function FlowerEleven({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Flower"
      x={0}
      y={0}
      viewBox="0 0 496.988 496.988">
      <Path
        d="M418.353 192.781l-30.738-9.987-46.757 192.251 12.614 17.42c28.813 39.665 90.446 29.903 105.594-16.718l34.888-107.374c15.148-46.621-28.976-90.746-75.601-75.592z"
        fill="#fa71a3"
      />
      <Path
        d="M397.978 199.44c40.41-13.146 78.677 25.091 65.541 65.52l-31.081 95.657c-13.142 40.448-66.636 48.881-91.58 14.426l-13.551-18.713 38.57-167.279c0 .001 32.079 10.393 32.101 10.389z"
        fill="#f9a3bf"
      />
      <Path
        d="M375.971 206.558l-.161.042-102.999-33.467-24.317 74.839 78.683 108.305.105.129c21.656 29.59 67.755 22.236 79.099-12.678l26.13-80.421c11.345-34.912-21.627-67.959-56.54-56.749z"
        fill="#ffeab0"
      />
      <Path
        d="M248.493 69.372L229.495 95.52l168.393 103.877 20.466-6.613c46.627-15.146 56.389-76.779 16.731-105.592l-91.338-66.361c-39.658-28.814-95.259-.484-95.254 48.541z"
        fill="#f9a3bf"
      />
      <Path
        d="M248.53 90.808c-.015-42.495 48.175-67.073 82.567-42.086l81.371 59.119c34.408 24.999 25.896 78.48-14.58 91.556l-21.985 7.105-147.173-88.374c.001 0 19.796-27.298 19.8-27.32z"
        fill="#f9d4db"
      />
      <Path
        d="M248.499 113.937l-.009.166-63.657 87.617 63.661 46.253 127.319-41.364.155-.06c34.834-11.452 42.085-57.568 12.386-79.145L319.943 77.7c-29.699-21.577-71.316-.431-71.444 36.237z"
        fill="#fff7df"
      />
      <Path
        d="M78.634 192.784l18.997 26.147L248.461 90.88l.035-21.508c.004-49.025-55.596-77.355-95.254-48.541l-91.338 66.36c-39.658 28.814-29.897 90.447 16.73 105.593z"
        fill="#fa71a3"
      />
      <Path
        d="M99.033 199.372c-40.42-13.117-48.903-66.544-14.512-91.531l81.371-59.119c34.407-24.999 82.641-.377 82.569 42.158l-.037 23.104-129.527 112.66c0 .001-19.844-27.262-19.864-27.272z"
        fill="#f9a3bf"
      />
      <Path
        d="M121.02 206.549l.155.06 63.657 87.617 63.661-46.253.004-133.869-.009-.166c-.127-36.668-41.745-57.814-71.444-36.237l-68.411 49.703c-29.697 21.577-22.447 67.692 12.387 79.145z"
        fill="#ffeab0"
      />
      <Path
        d="M143.517 392.465l30.738-9.987L99.08 199.46l-20.444-6.679c-46.625-15.154-90.749 28.971-75.601 75.592l34.888 107.374c15.147 46.621 76.781 56.383 105.594 16.718z"
        fill="#fa4087"
      />
      <Path
        d="M156.086 375.101c-24.965 34.388-78.399 25.947-91.536-14.483l-31.08-95.657c-13.142-40.448 25.179-78.713 65.61-65.501l21.962 7.175 67.12 158.002c0-.001-32.06 10.448-32.076 10.464z"
        fill="#fa71a3"
      />
      <Path
        d="M169.706 356.407l.105-.129 102.999-33.467-24.317-74.839L121.177 206.6l-.161-.042c-34.913-11.21-67.885 21.836-56.541 56.75l26.13 80.421c11.346 34.914 57.445 42.268 79.101 12.678z"
        fill="#f9d4db"
      />
      <Path
        d="M353.474 392.463l-15-17.32-182.29-.06-12.67 17.38c-28.82 39.66-.49 95.26 48.53 95.26h112.9c49.02 0 77.35-55.6 48.53-95.26z"
        fill="#f9a3bf"
      />
      <Path
        d="M340.844 375.143c24.99 34.37.45 82.58-42.06 82.58h-100.58c-42.53 0-67.08-48.27-42.02-82.64l13.61-18.67 156.01-.01s15.03 18.72 15.04 18.74z"
        fill="#f9d4db"
      />
      <Path
        d="M327.274 356.413l-.09-.14-78.69-108.3-78.69 108.3-.09.14c-21.45 29.74-.21 71.31 36.5 71.31h84.56c36.71 0 57.95-41.57 36.5-71.31z"
        fill="#fff7df"
      />
      <Path
        d="M159.866 276.76c-6.648 10.408-6.66 24.245 1.038 34.841s20.862 14.86 32.815 11.754c.739 12.328 8.863 23.53 21.319 27.577s25.612-.24 33.457-9.779c7.844 9.539 21.001 13.827 33.457 9.779s20.579-15.249 21.319-27.577c11.953 3.106 25.117-1.158 32.815-11.754s7.686-24.433 1.038-34.841c11.496-4.513 19.639-15.7 19.639-28.797s-8.143-24.284-19.639-28.797c6.648-10.408 6.66-24.245-1.038-34.841s-20.862-14.86-32.815-11.754c-.739-12.328-8.863-23.53-21.319-27.577s-25.612.24-33.457 9.779c-7.844-9.539-21.001-13.827-33.457-9.779-12.456 4.047-20.579 15.249-21.319 27.577-11.953-3.106-25.117 1.158-32.815 11.754s-7.686 24.433-1.038 34.841c-11.496 4.513-19.639 15.7-19.639 28.797s8.143 24.285 19.639 28.797z"
        fill="#fa4087"
      />
      <Circle cx={248.494} cy={247.963} fill="#be437c" r={60} />
      <Circle cx={248.494} cy={247.963} fill="#fa71a3" r={30} />
      <Circle cx={248.494} cy={247.963} fill="#ffeab0" r={7.5} />
    </Svg>
  )
}

export default FlowerEleven
