import React from "react"
import { Svg, Path, Ellipse, G } from "react-native-svg"

function Hippopotamus({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Hippopotamus"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M132.287 480.811C138.108 498.302 191.269 512 256 512s117.892-13.698 123.713-31.189c0 0-68.111-20.121-124.713-20.144-55.935-.023-122.713 20.144-122.713 20.144z"
        fill="#5b5b68"
      />
      <Path
        d="M132.287 480.811c.181.545.409 1.087.681 1.624l.05.092c.121.235.249.469.387.703.044.074.094.146.139.22.117.19.235.38.364.569.065.095.137.189.204.284.118.167.235.333.363.499.083.107.173.214.259.321.123.152.243.305.373.457.098.114.203.227.305.341.128.143.254.286.389.429.113.119.234.238.351.357.134.135.266.271.406.406.127.123.262.245.395.367.14.129.277.259.422.388.14.125.288.248.433.372.148.126.294.253.447.378.152.125.311.248.468.372.155.123.309.246.47.368.167.127.341.253.513.38.16.118.319.236.484.353.18.128.366.255.551.382.167.115.333.229.505.344.193.128.392.256.59.383.173.111.345.223.522.333.206.129.418.257.63.385.178.108.356.216.538.323a59.27 59.27 0 001.057.604c18.936 4.417 39.068 4.559 58.513.271L256 481.447l52.905 11.667c19.445 4.288 39.577 4.146 58.513-.271a50.08 50.08 0 001.054-.603c.185-.109.366-.219.547-.328.209-.126.419-.253.622-.38.18-.112.354-.225.529-.338.195-.125.392-.251.582-.377.175-.116.343-.233.513-.35.182-.125.365-.25.542-.375.168-.119.329-.239.492-.359.17-.125.341-.249.505-.374.162-.124.318-.248.475-.372.155-.123.312-.245.463-.368.155-.127.303-.255.453-.383.143-.122.289-.244.428-.367.146-.13.285-.26.426-.391.131-.122.266-.243.392-.365.141-.136.273-.272.408-.409.117-.118.236-.236.349-.354.136-.144.263-.288.392-.432.101-.113.205-.225.302-.338.13-.152.251-.305.374-.458.086-.106.176-.213.258-.32.128-.166.245-.333.364-.501.067-.094.139-.187.203-.281.13-.191.249-.383.368-.576.044-.071.093-.142.135-.213.139-.234.266-.469.388-.704l.05-.091c.272-.538.5-1.079.681-1.624 0 0-68.111-20.122-124.713-20.145-55.935-.023-122.713 20.144-122.713 20.144z"
        fill="#4e4e5e"
      />
      <G fill="#7f7e87">
        <Path d="M384.75 86.253c15.337-12.554 31.615-28.45 29.038-37.651-4.727-16.873-27.553-57.222-49.562-46.952-14.303 6.675-27.813 30.08-35.52 45.652 19.569 9.463 39.238 22.224 56.044 38.951zM127.25 86.253c16.806-16.727 36.475-29.489 56.044-38.951-7.707-15.572-21.217-38.977-35.52-45.652-22.009-10.27-44.836 30.079-49.562 46.953-2.577 9.201 13.701 25.096 29.038 37.65z" />
      </G>
      <Path
        d="M389.879 299.702c21.708-24.174 41.364-53.011 41.364-99.757 0-82.586-53.556-129.755-105.375-153.994C299.79 33.752 274.152 56.476 256 54.3c-18.151 2.176-43.79-20.548-69.869-8.35-51.819 24.24-105.375 71.408-105.375 153.995 0 46.746 19.656 75.584 41.364 99.757.408-.462 66.922 30.549 133.546 30.549 66.847-.002 133.804-31.013 134.213-30.549z"
        fill="#8a8990"
      />
      <Path
        d="M399.878 199.945c0 36.944-12.282 62.697-28.146 83.808 5.788 3.72 11.083 8.15 15.71 13.229.806.884 1.62 1.795 2.437 2.72 21.708-24.173 41.364-53.011 41.364-99.757 0-82.586-53.556-129.755-105.375-153.994-11.461-5.361-22.837-3.975-33.53-.924.721.286 1.443.586 2.165.924 51.82 24.239 105.375 71.407 105.375 153.994z"
        fill="#828287"
      />
      <G fill="#363649">
        <Path d="M161.923 142.63c-15.851 0-28.746 12.895-28.746 28.745 0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5c0-7.579 6.167-13.745 13.746-13.745s13.746 6.166 13.746 13.745c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5c-.001-15.851-12.896-28.745-28.746-28.745zM321.332 171.375c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5c0-7.579 6.166-13.745 13.746-13.745s13.746 6.166 13.746 13.745c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5c0-15.851-12.896-28.745-28.746-28.745s-28.746 12.894-28.746 28.745z" />
      </G>
      <Path
        d="M186.69 279.311c18.442 0 36.331 6.782 50.705 17.388L256 310.427l18.605-13.728c14.373-10.605 32.262-17.388 50.704-17.388a88 88 0 0112.262.864c-11.805-11.035-38.618-50.558-65.563-119.035-5.716-14.527-26.301-14.527-32.017 0-26.945 68.477-53.758 108.001-65.563 119.035a88.011 88.011 0 0112.262-.864z"
        fill="#7c7b87"
      />
      <Path
        d="M387.442 296.981c-15.396-16.898-38.133-26.676-62.133-26.676-18.442 0-36.331 5.782-50.704 16.387L256 300.42l-18.605-13.728c-14.373-10.606-32.262-16.387-50.704-16.387-24 0-46.736 9.778-62.133 26.676-23.181 25.442-51.816 68.028-51.82 123.164-.001 18.148 12.603 33.014 28.358 44.507 28.614 20.874 66.46 27.8 101.999 19.962L256 472.947l52.905 11.668c35.539 7.838 73.385.912 101.999-19.962 15.755-11.493 28.359-26.359 28.358-44.507-.003-55.137-28.638-97.723-51.82-123.165z"
        fill="#71707f"
      />
      <Path
        d="M387.442 296.981c-15.396-16.898-38.133-26.676-62.133-26.676-5.207 0-10.366.477-15.419 1.377 18.088 3.244 34.593 12.099 46.619 25.298 23.181 25.442 51.816 68.028 51.82 123.164.001 18.148-12.603 33.014-28.358 44.508-16.848 12.291-36.898 19.74-57.792 22.193 31.488 3.678 63.651-3.902 88.724-22.193 15.755-11.493 28.359-26.36 28.358-44.508-.002-55.135-28.637-97.721-51.819-123.163z"
        fill="#5f5f6d"
      />
      <G fill="#363649">
        <Ellipse
          transform="rotate(-162.478 190.917 332.445)"
          cx={190.918}
          cy={332.446}
          rx={27.637}
          ry={20.462}
        />
        <Ellipse
          transform="rotate(162.478 321.19 332.435)"
          cx={321.192}
          cy={332.436}
          rx={27.637}
          ry={20.462}
        />
      </G>
      <G fill="#a2a2aa">
        <Path d="M130.278 66.59c-4.258-4.497-9.627-10.638-12.474-14.078a4.975 4.975 0 01-.752-5.128c4.481-10.472 12.263-21.158 17.599-25.885a4.998 4.998 0 016.881.228c4.611 4.659 12.218 14.274 18.089 23.139a4.976 4.976 0 01-1.335 6.846c-5.234 3.598-14.563 10.108-21.343 15.377a4.97 4.97 0 01-6.665-.499zM381.722 66.59c4.258-4.497 9.627-10.638 12.474-14.078a4.975 4.975 0 00.752-5.128c-4.481-10.472-12.263-21.158-17.599-25.885a4.998 4.998 0 00-6.881.228c-4.611 4.659-12.218 14.274-18.089 23.139a4.976 4.976 0 001.335 6.846c5.234 3.598 14.563 10.108 21.343 15.377a4.97 4.97 0 006.665-.499z" />
      </G>
    </Svg>
  )
}

export default Hippopotamus
