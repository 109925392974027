import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function Frog({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Frog"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M0 228.999C0 299.84 140.387 435.45 256 435.45s256-135.61 256-206.451H0z"
        fill="#dcfd92"
      />
      <Path
        d="M0 228.999c0 58.282 95.024 160.337 193.096 194.715-23.94-32.714-61.291-88.333-77.483-136.909-6.088-18.263-1.668-38.022 8.775-57.806H0z"
        fill="#bee86e"
      />
      <Path
        d="M0 228.998c0 37.436 173.419 140.387 256 140.387s256-102.951 256-140.387H0z"
        fill="#4b3f4e"
      />
      <Path
        d="M256 286.804c127.312 0 256-41.29 256-57.806s-28.903-55.054-28.903-55.054c-19.957-28.903-7.57-61.935-31.155-81.427-23.12-19.107-66.091-21.871-88.587-3.907C326.482 118.054 295.914 129.9 256 129.9s-70.482-11.846-107.355-41.29c-22.496-17.964-65.467-15.2-88.587 3.907-23.585 19.491-11.198 52.524-31.155 81.427 0 0-28.903 38.537-28.903 55.054s128.688 57.806 256 57.806z"
        fill="#bee86e"
      />
      <Path
        d="M148.645 88.611c-22.496-17.964-65.467-15.2-88.587 3.907-23.585 19.491-11.198 52.524-31.155 81.427 0 0-28.903 38.537-28.903 55.053 0 10.012 47.327 29.118 112.933 42.621-1.911-49.965 60.464-107.517 106.623-145.823-23.957-5.608-46.022-17.311-70.911-37.185z"
        fill="#afd755"
      />
      <Circle cx={82.58} cy={154.68} r={66.06} fill="#ffdc64" />
      <Path
        d="M107.355 195.966c-36.486 0-66.065-29.578-66.065-66.065 0-11.878 3.177-22.995 8.663-32.627C30 108.643 16.516 130.067 16.516 154.676c0 36.486 29.579 66.065 66.065 66.065 24.609 0 46.032-13.483 57.401-33.438-9.632 5.486-20.75 8.663-32.627 8.663z"
        fill="#ffc850"
      />
      <Circle cx={429.42} cy={154.68} r={66.06} fill="#ffdc64" />
      <Path
        d="M454.194 195.966c-36.486 0-66.065-29.578-66.065-66.065 0-11.878 3.177-22.995 8.663-32.627-19.954 11.368-33.438 32.792-33.438 57.401 0 36.486 29.579 66.065 66.065 66.065 24.609 0 46.032-13.483 57.401-33.438-9.631 5.487-20.749 8.664-32.626 8.664z"
        fill="#ffc850"
      />
      <Path
        d="M66.065 187.708c-13.626 0-24.774-11.148-24.774-24.774V138.16c0-13.626 11.148-24.774 24.774-24.774 13.626 0 24.774 11.148 24.774 24.774v24.774c0 13.626-11.149 24.774-24.774 24.774z"
        fill="#4b3f4e"
      />
      <G fill="#7daa55">
        <Path d="M222.968 237.256a8.231 8.231 0 01-5.839-2.419 8.254 8.254 0 010-11.677l8.258-8.258a8.254 8.254 0 0111.677 0 8.254 8.254 0 010 11.677l-8.258 8.258a8.227 8.227 0 01-5.838 2.419zM289.032 237.256a8.231 8.231 0 01-5.839-2.419l-8.258-8.258c-3.226-3.226-3.226-8.452 0-11.677s8.452-3.226 11.677 0l8.258 8.258a8.254 8.254 0 010 11.677 8.227 8.227 0 01-5.838 2.419z" />
      </G>
      <Path
        d="M66.799 113.46c-.445 1.277-.735 2.627-.735 4.054v33.032c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-12.387c.001-13.374-10.757-24.298-24.039-24.699z"
        fill="#5d5360"
      />
      <Circle cx={66.06} cy={138.16} r={8.258} fill="#fff" />
      <Path
        d="M445.936 187.708c-13.626 0-24.774-11.148-24.774-24.774V138.16c0-13.626 11.148-24.774 24.774-24.774 13.626 0 24.774 11.148 24.774 24.774v24.774c0 13.626-11.149 24.774-24.774 24.774z"
        fill="#4b3f4e"
      />
      <Path
        d="M446.67 113.46c-.445 1.277-.735 2.627-.735 4.054v33.032c0 6.841 5.546 12.387 12.387 12.387s12.387-5.546 12.387-12.387v-12.387c.001-13.374-10.757-24.298-24.039-24.699z"
        fill="#5d5360"
      />
      <Circle cx={445.94} cy={138.16} r={8.258} fill="#fff" />
      <Path
        d="M352.154 344.284c-22.712-14.908-57.332-24.447-96.154-24.447s-73.443 9.539-96.154 24.447c35.548 15.01 70.302 25.101 96.154 25.101s60.606-10.091 96.154-25.101z"
        fill="#e6646e"
      />
      <Path
        d="M225.223 365.71c11.136 2.34 21.543 3.675 30.777 3.675 25.852 0 60.606-10.092 96.154-25.101-16.154-10.604-38.4-18.409-63.832-22.08-24.087 5.622-45.816 21.135-63.099 43.506z"
        fill="#ff8087"
      />
    </Svg>
  )
}

export default Frog
