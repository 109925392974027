import { Dimensions, StyleSheet } from "react-native";  

const windowWidth = Dimensions.get('window').width;

export default StyleSheet.create({
    container:{
        maxWidth: 1000,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo:{
        marginTop: 50,
        alignItems: 'center',
        width: '100%'
    },
    info_content:{
        maxWidth: 700,
        marginTop: 20,
        marginTop: 20,
        marginLeft: 5,
        marginRight: 5,
        alignItems: 'center',
    },
    text:{
        fontFamily: "Roboto",
        padding: 10,
        textAlign: 'center',
        color: "#337373",
        fontSize: 20
    },
    image:{
        borderRadius: 20,
        maxWidth: 500,
        maxHeight: 500,
        resizeMode: 'contain',
        width: (windowWidth*70)/100,
        height: (windowWidth*70)/100
    },
    shadow:{
        borderRadius: 15,
        maxWidth: 500,
        maxHeight: 500,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        elevation: 5,
        shadowOpacity: 0.25,
        shadowRadius: 3,
        backgroundColor : "#0000"
    },
    strong: {
        fontFamily: 'RobotoBold'
    },
    storeContainer:{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    storeLogo:{
        height: 35,
        width: 118,
    }
})