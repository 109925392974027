import * as React from "react"
import { Svg, Path, Ellipse, G, Circle } from "react-native-svg"

function Wolf({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Wolf"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M456.409 4.169c11.115-4.246 23.06 4.141 22.543 16.028-4.168 95.801-33.016 174.947-33.016 174.947l-132.129-82.581C363.468 52.749 427.001 15.404 456.409 4.169z"
        fill="#6f6571"
      />
      <Path
        d="M451.187 33.241c6.791-3.806 13.707 4.346 13.046 15.574-5.33 90.484-33.265 152.522-33.265 152.522l-68.503-68.302c31.55-55.612 70.754-89.724 88.722-99.794z"
        fill="#c0bcc1"
      />
      <Path
        d="M55.591 4.169C44.476-.077 32.531 8.31 33.048 20.197c4.168 95.801 33.016 174.947 33.016 174.947l132.129-82.581C148.532 52.749 84.998 15.404 55.591 4.169z"
        fill="#6f6571"
      />
      <Path
        d="M60.813 33.241c-6.791-3.806-13.707 4.346-13.046 15.574 5.33 90.484 33.265 152.522 33.265 152.522l68.503-68.302c-31.55-55.612-70.754-89.724-88.722-99.794z"
        fill="#c0bcc1"
      />
      <Ellipse cx={256} cy={475.92} rx={49.548} ry={33.03} fill="#5d5360" />
      <Path
        d="M512 343.789C480.526 184.572 376.849 96.047 256 96.047S31.474 184.572 0 343.789c0 0 48.058 13.783 53.81 16.516l-7.881 32.897a5.994 5.994 0 004.025 7.118c18.951 5.972 84.246 27.329 128.014 51.354h156.063c43.768-24.025 109.063-45.382 128.014-51.354a5.995 5.995 0 004.025-7.118l-7.881-32.897c5.753-2.733 53.811-16.516 53.811-16.516z"
        fill="#867e88"
      />
      <Path
        d="M256 319.014c-27.094 0-51.5 16.345-61.855 41.382-10.345 25.011-20.725 49.89-20.725 74.231 0 23.889 30.595 59.064 77.323 39.916a13.778 13.778 0 0110.515 0c46.728 19.149 77.323-16.026 77.323-39.916 0-24.342-10.38-49.22-20.725-74.231-10.356-25.037-34.762-41.382-61.856-41.382z"
        fill="#6f6571"
      />
      <G fill="#c0bcc1">
        <Path d="M45.93 393.201a5.994 5.994 0 004.025 7.118c18.951 5.972 84.246 27.329 128.014 51.354v-.001c-2.921-5.759-4.549-11.681-4.549-17.045 0-48.483 41.29-99.097 41.29-148.645 0-35.097-25.291-73.29-74.323-66.065C99.659 225.92 47.484 255.014 0 343.789c0 0 48.058 13.783 53.81 16.516l-7.88 32.896zM466.07 393.201a5.994 5.994 0 01-4.025 7.118c-18.951 5.972-84.246 27.329-128.014 51.354v-.001c2.921-5.759 4.549-11.681 4.549-17.045 0-48.483-41.29-99.097-41.29-148.645 0-35.097 25.29-73.29 74.323-66.065 40.728 6.002 92.903 35.097 140.387 123.871 0 0-48.058 13.783-53.81 16.516l7.88 32.897z" />
      </G>
      <Path
        d="M152.774 327.272c-15.897 0-28.903-13.006-28.903-28.903v-16.516c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c0 15.897-13.006 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M152.774 252.95c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516v-12.387c0-15.897-13.006-28.903-28.903-28.903z"
        fill="#5d5360"
      />
      <Circle cx={152.77} cy={273.6} r={12.387} fill="#fff" />
      <Path
        d="M359.226 327.272c-15.897 0-28.903-13.006-28.903-28.903v-16.516c0-15.897 13.006-28.903 28.903-28.903 15.897 0 28.903 13.006 28.903 28.903v16.516c0 15.897-13.006 28.903-28.903 28.903z"
        fill="#4b3f4e"
      />
      <Path
        d="M359.226 252.95c-1.413 0-2.769.221-4.129.419v40.871c0 9.121 7.395 16.516 16.516 16.516 9.122 0 16.516-7.395 16.516-16.516v-12.387c0-15.897-13.007-28.903-28.903-28.903z"
        fill="#5d5360"
      />
      <Circle cx={359.23} cy={273.6} r={12.387} fill="#fff" />
      <Path
        d="M297.29 420.726c0-15.127-18.7-27.389-41.768-27.389s-41.768 12.262-41.768 27.389c0 10.251 14.598 18.307 27.676 24.163a10.675 10.675 0 016.311 9.766v20.928c1.007-.368 1.978-.621 3.001-1.04a13.778 13.778 0 0110.515 0c1.023.419 1.993.672 3 1.04v-21.022c0-4.189 2.421-7.982 6.229-9.727 12.767-5.852 26.804-13.89 26.804-24.108z"
        fill="#4b3f4e"
      />
      <Path
        d="M256 96.047c-120.849 0-224.526 88.525-256 247.742 0 0 48.058 13.783 53.81 16.516l-7.881 32.897a5.994 5.994 0 004.025 7.118c9.015 2.842 28.515 9.165 51.191 17.597C62.128 173.296 225.567 96.047 256 96.047z"
        fill="#6f6571"
      />
      <Path
        d="M101.146 417.917c-13.008-81.554-3.599-144.526 15.993-192.192C80.844 238.492 38.856 271.145 0 343.789c0 0 48.058 13.783 53.81 16.516l-7.881 32.897a5.994 5.994 0 004.025 7.118c9.015 2.842 28.515 9.166 51.192 17.597z"
        fill="#aea8af"
      />
    </Svg>
  )
}

export default Wolf