import React from "react"
import { Svg, Path, Circle } from "react-native-svg"


function Bear({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Bear"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Circle cx={429.419} cy={82.581} fill="#c38c66" r={82.581} />
      <Circle cx={429.419} cy={82.581} fill="#f4dbb3" r={49.548} />
      <Circle cx={82.581} cy={82.581} fill="#b57f5f" r={82.581} />
      <Circle cx={82.581} cy={82.581} fill="#eac89f" r={49.548} />
      <Path
        d="M487.226 280.774c0 58.598-21.797 112.104-57.726 152.854C387.129 481.685 325.105 506.5 256 506.5c-127.702 0-221-98.023-221-225.726 0-69.105 20.089-131.129 68.145-173.5C143.895 71.346 197.402 49.548 256 49.548c127.702 0 231.226 103.524 231.226 231.226z"
        fill="#c38c66"
      />
      <Path
        d="M363.355 355.097c0 25.441-8.849 48.814-23.638 67.211C320.041 446.785 289.85 456.75 256 456.75c-59.29 0-99.5-42.363-99.5-101.653 0-33.852 7.814-64.045 32.294-83.721 18.396-14.786 41.768-23.633 67.206-23.633 59.29-.001 107.355 48.063 107.355 107.354z"
        fill="#f4dbb3"
      />
      <Path
        d="M305.548 355.097a8.253 8.253 0 00-8.258 8.258c0 9.105-7.411 16.516-16.516 16.516s-16.516-7.411-16.516-16.516v-24.774c0-4.565-3.694-8.258-8.258-8.258s-8.258 3.694-8.258 8.258v24.774c0 9.105-7.411 16.516-16.516 16.516s-16.516-7.411-16.516-16.516c0-4.565-3.694-8.258-8.258-8.258s-8.258 3.694-8.258 8.258c0 18.218 14.815 33.032 33.032 33.032 9.914 0 18.715-4.478 24.774-11.413 6.059 6.935 14.86 11.413 24.774 11.413 18.218 0 33.032-14.815 33.032-33.032a8.253 8.253 0 00-8.258-8.258z"
        fill="#6e4848"
      />
      <Circle cx={152.774} cy={227.097} fill="#4b3f4e" r={20.645} />
      <Circle cx={359.226} cy={227.097} fill="#4b3f4e" r={20.645} />
      <Path
        d="M276.645 491.355c-127.703 0-231.226-103.524-231.226-231.226 0-58.597 21.799-112.105 57.726-152.855-48.058 42.371-78.371 104.394-78.371 173.5C24.774 408.476 128.297 512 256 512c69.106 0 131.129-30.314 173.5-78.371-40.75 35.927-94.258 57.726-152.855 57.726z"
        fill="#b57f5f"
      />
      <Path
        d="M272.516 445.935c-59.29 0-107.355-48.065-107.355-107.355 0-25.436 8.847-48.813 23.631-67.208-24.483 19.676-40.147 49.87-40.147 83.724 0 59.29 48.065 107.355 107.355 107.355 33.854 0 64.041-15.66 83.717-40.144-18.395 14.784-41.765 23.628-67.201 23.628z"
        fill="#eac89f"
      />
      <Path
        d="M220.943 320.039l17.539 17.539c9.675 9.675 25.361 9.675 35.036 0l17.539-17.539c5.348-5.348 1.56-14.491-6.002-14.491h-58.11c-7.563 0-11.35 9.144-6.002 14.491z"
        fill="#4b3f4e"
      />
    </Svg>
  )
}

export default Bear
