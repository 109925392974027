import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, Text, View, Dimensions, Image, ActivityIndicator, TouchableOpacity, Platform, Linking } from 'react-native';
import { AuthContext } from '../../modules/AuthProvider';

import { getLocalStorage } from '../../../tools/tools';

import BaseStyle from '../../modules/BaseStyle';
import { HeaderNoSignIn } from "../../modules/HeaderNoSignIn";
import LogoLong from '../../../../../assets/img/LogoLong';
import { H1 } from '../../modules/Title';
import Shadow from '../../modules/Shadow';

import styles from "./LandingPage.style";


const LandingPage = ({navigation}) => {
  
  const { login } = useContext(AuthContext)
  const [display,setDisplay] = useState(false)

  // redirection si on est log
  useEffect(()=>{
      (async () => {
          const user_param = await getLocalStorage()
          if (user_param) {
            login()
          } else {
            setDisplay(true)
          }
      })()
  },[])
  
  const windowWidth = Dimensions.get('window').width;

  if(display) {
    return (
      <BaseStyle>
        <HeaderNoSignIn navigation={navigation} />

          <View>
            <ScrollView contentContainerStyle={{alignItems: 'center', paddingBottom: 30}}>
              <View style={styles.logo} to="/">
                <LogoLong />
              </View>

              <H1>N'oubliez plus les plus belles phrases de vos enfants.</H1>

              {Platform.OS === "web" && (
                <View style={styles.storeContainer}>
                  <TouchableOpacity onPress={()=>{Linking.openURL("https://apps.apple.com/app/id1542104753")}} style={{marginRight: 10}}>
                    <Image style={styles.storeLogo} source={require('../../../../../assets/img/landingPage/appstore.png')} />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={()=>{Linking.openURL("https://play.google.com/store/apps/details?id=com.kidywoo.app")}}>
                    <Image style={styles.storeLogo} source={require('../../../../../assets/img/landingPage/googleplay.png')} />
                  </TouchableOpacity>
                </View>
              )}

              <View style={styles.info_content}>
                <Text style={styles.text}>KidyWoo est une application indispensable créée par des parents pour des parents.</Text>
                <Shadow>
                    <Image 
                      style={styles.image}
                      source={require("../../../../../assets/img/landingPage/exemple1.png")}
                    />
                </Shadow>
              </View>
    
              <View style={styles.info_content}>
                <Text style={styles.text}>Vous avez comme nous l'envie de <Text style={styles.strong}>garder en mémoire les plus beaux mots de vos enfants</Text>, les premiers, les plus mignons, les plus tendres... 
                  Kidywoo vous offre la possibilité de le faire gratuitement, d'une jolie manière et sans rien d'autre sous la main que votre smartphone.</Text>
                <Shadow>
                    <Image 
                      style={styles.image}
                      source={require("../../../../../assets/img/landingPage/exemple2.png")}
                    />
                </Shadow>
              </View>
    
              <View style={styles.info_content}>
                <Text style={styles.text}>Kidywoo vous permet ensuite de <Text style={styles.strong}>télécharger à volonté et de partager ces pépites</Text> à vos proches ou sur les reseaux sociaux, pour des moments remplis d'émotions.</Text>
                <Shadow>
                    <Image 
                      style={styles.image}
                      source={require("../../../../../assets/img/landingPage/exemple3.png")}
                    />
                </Shadow>
              </View>
    
              <View style={styles.info_content}>
                <Text style={styles.text}>Pas de téléphone sous la main ? Pas de panique, votre compte Kidywoo est <Text style={styles.strong}>accessible sur toutes les plateformes</Text> via le site internet tout simplement{'\u00A0'}!</Text>
                    <Image
                      style={[styles.image, {height: ((windowWidth*70)/100)*0.514}]}
                      source={require("../../../../../assets/img/landingPage/responsive.png")}
                    />
              </View>
    
              <View style={styles.info_content}>
                <Text style={styles.text}>Nos enfants grandissent trop vite c'est bien connu, souvenez-vous pour toujours de leurs premiers pas vers notre langue.</Text>
                <Shadow>
                    <Image
                      style={styles.image}
                      source={require("../../../../../assets/img/landingPage/exemple4.png")}
                    />
                </Shadow>
              </View>
    
              <H1>Vous aviez déjà leurs photos, maintenant vous aurez aussi leurs mots{'\u00A0'}!</H1>

              {Platform.OS === "web" && (
                <View style={styles.storeContainer}>
                  <TouchableOpacity style={{marginRight: 10}}>
                    <Image style={styles.storeLogo} source={require('../../../../../assets/img/landingPage/appstore.png')} />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={()=>{Linking.openURL("https://play.google.com/store/apps/details?id=com.kidywoo.app")}}>
                    <Image style={styles.storeLogo} source={require('../../../../../assets/img/landingPage/googleplay.png')} />
                  </TouchableOpacity>
                </View>
              )}
            </ScrollView>
          </View>
      </BaseStyle>
    )
  } else {
    return (
      <BaseStyle>
        <LogoLong />
        <ActivityIndicator size="large" color="#57B5B5"/>
      </BaseStyle>
    )
  }
}

export default LandingPage;