import React from "react"
import { Svg, Path, Ellipse, G, Circle } from "react-native-svg"

function Walrus({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Walrus"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M60.715 200.2c8.131-15.488 13.636-32.582 17.197-50.238C94.04 69.999 167.637 9.599 256 9.599c88.364 0 161.96 60.4 178.089 140.363 3.561 17.657 9.066 34.75 17.197 50.238 14.762 28.121 23.073 59.871 23.073 93.477 0 115.275-97.763 208.724-218.359 208.724S37.641 408.952 37.641 293.677c0-33.606 8.311-65.356 23.074-93.477z"
        fill="#665e66"
      />
      <Path
        d="M255.984 502.4c-120.59-.01-218.343-93.458-218.343-208.722 0-33.609 8.31-65.356 23.077-93.478 8.129-15.488 13.636-32.578 17.19-50.238C93.966 70.339 167.001 10.119 254.863 9.609c-47.645.931-87.201 61.011-95.921 140.353-1.942 17.66-4.946 34.75-9.371 50.238-8.049 28.123-12.575 59.869-12.575 93.478 0 115.263 53.272 208.712 118.988 208.722z"
        fill="#454045"
      />
      <G fill="#f9f6f9">
        <Path d="M195.815 371.335c-1.639 39.629 19.792 74.125 11.264 85.917-4.311 5.205-19.471 3.025-34.81-11.864-15.118-14.369-30.414-41.447-29.893-74.053-.521-32.605 14.775-59.684 29.893-74.053 15.339-14.889 30.499-17.068 34.81-11.864 8.528 11.792-12.903 46.288-11.264 85.917zM316.185 371.335c1.639 39.629-19.792 74.125-11.264 85.917 4.311 5.205 19.471 3.025 34.81-11.864 15.118-14.369 30.414-41.447 29.893-74.053.521-32.605-14.775-59.684-29.893-74.053-15.339-14.889-30.499-17.068-34.81-11.864-8.528 11.792 12.903 46.288 11.264 85.917z" />
      </G>
      <G fill="#dfdde2">
        <Path d="M194.411 445.384c4.655 4.515 9.281 7.869 13.606 10.192a8.278 8.278 0 01-.941 1.672c-4.305 5.196-19.473 3.024-34.81-11.864-15.117-14.377-30.405-41.448-29.885-74.056-.521-32.608 14.767-59.689 29.885-74.056 15.338-14.887 30.505-17.07 34.81-11.864.37.511.691 1.071.941 1.672-4.325 2.323-8.95 5.677-13.606 10.192-15.118 14.367-30.415 41.448-29.895 74.056-.52 32.608 14.778 59.679 29.895 74.056zM326.124 455.576c-9.931 5.336-18.201 5.296-21.205 1.672-8.53-11.794 12.905-46.294 11.263-85.92 1.642-39.626-19.793-74.126-11.263-85.92 3.003-3.624 11.273-3.664 21.205 1.672-5.767 13.145 13.766 46.354 12.204 84.248 1.562 37.894-17.971 71.102-12.204 84.248z" />
      </G>
      <Circle cx={256} cy={341.764} fill="#454045" r={43.146} />
      <Path
        d="M303.035 218.721a99.4 99.4 0 0113.366-.891c47.045 0 85.189 32.538 85.189 72.684s-38.144 72.684-85.189 72.684c-23.607 0-44.972-8.2-60.4-21.435-15.428 13.235-36.793 21.435-60.41 21.435-47.045 0-85.179-32.538-85.179-72.684s38.134-72.684 85.179-72.684c4.545 0 9.01.3 13.365.891"
        fill="#e4936b"
      />
      <Path
        d="M182.144 290.514c0 34.35 14.157 63.133 33.199 70.722a99.638 99.638 0 01-19.753 1.962c-47.045 0-85.179-32.538-85.179-72.684s38.134-72.684 85.179-72.684c4.545 0 9.01.3 13.365.891h9.641c-20.653 5.457-36.452 35.511-36.452 71.793z"
        fill="#dd6938"
      />
      <G fill="#282528">
        <Path d="M137.221 144.146c-12.544 0-22.749 15.252-22.749 34s10.205 34 22.749 34 22.749-15.252 22.749-34-10.205-34-22.749-34zM372.783 144.146c-12.544 0-22.749 15.252-22.749 34s10.205 34 22.749 34c12.543 0 22.749-15.252 22.749-34s-10.206-34-22.749-34z" />
      </G>
      <Ellipse cx={256} cy={232.396} fill="#454045" rx={51.302} ry={34.239} />
      <G fill="#f9f6f9">
        <Path d="M504.5 274.137a7.49 7.49 0 01-2.285-.358c-76.44-24.422-159.02-.209-159.845.039a7.503 7.503 0 01-9.339-5.026 7.499 7.499 0 015.02-9.339c3.545-1.067 87.753-25.833 168.73.038a7.5 7.5 0 01-2.281 14.646zM455.021 306.119a7.476 7.476 0 01-3.002-.631c-39.135-17.122-87.677-7.717-88.162-7.619a7.5 7.5 0 01-2.938-14.71c2.172-.433 53.7-10.406 97.113 8.587a7.5 7.5 0 01-3.011 14.373zM498.121 390.492a7.493 7.493 0 01-6.275-3.383c-38.852-59.137-150.787-63.985-151.913-64.027a7.5 7.5 0 01-7.215-7.774c.154-4.139 3.647-7.386 7.774-7.215 1.223.045 30.34 1.223 65.034 10.296 47.366 12.387 80.625 32.737 98.855 60.484a7.5 7.5 0 01-2.15 10.386 7.458 7.458 0 01-4.11 1.233zM7.5 274.137a7.5 7.5 0 01-2.281-14.646c80.977-25.87 165.185-1.104 168.73-.038a7.5 7.5 0 01-4.319 14.364c-.825-.248-83.406-24.46-159.845-.039a7.492 7.492 0 01-2.285.359zM56.979 306.119a7.501 7.501 0 01-6.875-4.496 7.5 7.5 0 013.865-9.877c43.412-18.993 94.94-9.02 97.113-8.587a7.5 7.5 0 01-2.935 14.711c-.662-.13-49.223-9.418-88.165 7.619a7.48 7.48 0 01-3.003.63zM13.879 390.492a7.5 7.5 0 01-6.261-11.619c18.23-27.748 51.489-48.098 98.855-60.484 34.694-9.073 63.812-10.25 65.034-10.296 4.155-.174 7.62 3.076 7.774 7.215a7.5 7.5 0 01-7.212 7.774c-1.126.043-113.12 4.977-151.916 64.027a7.489 7.489 0 01-6.274 3.383z" />
      </G>
    </Svg>
  )
}

export default Walrus