import React from "react"
import { Svg, Path, G, Circle } from "react-native-svg"

function FoxTwo({size}) {
  return (
    <Svg 
      height={size ? size.width+'px' : '85px'}
      width={size ? size.height+'px' : '85px'}
      alt="Fox"
      x={0}
      y={0}
      viewBox="0 0 512 512">
      <Path
        d="M164.928 108.455s-31.734-65.446-114.969-89.93c0 0-94.67 143.647 23.817 214.64"
        fill="#dd6938"
      />
      <Path
        d="M73.776 233.169l6.799-9.292 34.223-46.822-1.092-4.461c0-30.256-15.906-68.713-31.822-99.639-15.958-30.998-31.926-54.424-31.926-54.424s-94.663 143.648 23.818 214.638z"
        fill="#f9f6f9"
      />
      <Path
        d="M64.288 202.367c5.687 11.126 11.126 17.997 16.287 21.51l34.223-46.822-1.092-4.461c0-30.256-15.906-68.713-31.822-99.639-20.759 22.489-48.986 68.023-17.596 129.412z"
        fill="#dfdde2"
      />
      <G fill="#dd6938">
        <Path d="M73.242 138.42a7.71 7.71 0 01-5.906-2.74l-17.909-21.198a7.727 7.727 0 0111.805-9.973l17.909 21.198a7.726 7.726 0 01-.917 10.888 7.69 7.69 0 01-4.982 1.825zM58.624 155.119a7.78 7.78 0 01-1.835-.221l-14.326-3.49a7.727 7.727 0 013.658-15.014l14.326 3.49a7.727 7.727 0 01-1.823 15.235zM349.126 108.455s31.734-65.446 114.969-89.93c0 0 94.67 143.647-23.817 214.64" />
      </G>
      <Path
        d="M440.279 233.169l-6.799-9.292-34.223-46.822 1.092-4.461c0-30.256 15.906-68.713 31.822-99.639 15.958-30.998 31.925-54.425 31.925-54.425s94.664 143.649-23.817 214.639z"
        fill="#f9f6f9"
      />
      <Path
        d="M449.767 202.367c-5.687 11.126-11.126 17.997-16.287 21.51l-34.223-46.822 1.092-4.461c0-30.256 15.906-68.713 31.822-99.639 20.758 22.489 48.985 68.023 17.596 129.412z"
        fill="#dfdde2"
      />
      <Path
        d="M440.813 138.42a7.727 7.727 0 01-5.899-12.712l17.908-21.198a7.725 7.725 0 0110.888-.916 7.727 7.727 0 01.917 10.888l-17.908 21.198a7.71 7.71 0 01-5.906 2.74zM455.431 155.119a7.727 7.727 0 01-1.823-15.235l14.326-3.49a7.724 7.724 0 019.336 5.678 7.727 7.727 0 01-5.678 9.336l-14.326 3.49a7.793 7.793 0 01-1.835.221z"
        fill="#dd6938"
      />
      <Path
        d="M461.737 323.413c.773.803 50.263 53.054 50.263 170.062 0 0-38.91-74.802-144.339-83.517l-104.78-.082h-11.692l-104.842-.082c-25.796 1.916-47.666 7.778-65.993 15.566C22.685 449.868 0 493.475 0 493.475c0-117.008 49.49-169.259 50.263-170.062L0 350.126c16.205-174.595 117.533-235.644 187.74-256.99 30.782-9.354 55.578-11.074 64.603-11.384.113 0 .216-.01.319-.01.36-.021.7-.031.999-.031h.01c.392-.01.742-.021 1.03-.021.433-.01.865-.01 1.298-.01 0 0 30.153-.381 69.867 11.95C396.001 115.409 495.919 176.859 512 350.126z"
        fill="#e4936b"
      />
      <Path
        d="M98.166 212.669l50.221-19.697S63.129 291.941 70.33 381.526c1.401 17.462 5.058 31.905 10.024 43.834C22.685 449.868 0 493.475 0 493.475c0-117.008 49.49-169.259 50.263-170.062L0 350.126C23.303 98.988 222.746 82.773 252.343 81.753c-69.98 2.771-143.133 84.063-154.177 130.916zM253.661 81.712l-.999.031c.361-.021.701-.031.999-.031z"
        fill="#dd6938"
      />
      <Path
        d="M511.99 493.455c-.752-1.432-39.93-74.863-144.328-83.496l-221.314-.165C40.445 417.643.773 492.002.01 493.455c.453-2.143 30.627-139.064 180.045-158.174l154.249.299c147.378 20.253 177.232 155.753 177.686 157.875z"
        fill="#dfdde2"
      />
      <Circle cx={257.492} cy={391.192} fill="#da4a54" r={68.366} />
      <Path
        d="M370.175 394.125a51.06 51.06 0 01-2.514 15.834 49.526 49.526 0 01-1.628 4.358 51.292 51.292 0 01-18.925 22.633 51.254 51.254 0 01-28.258 8.478c-24.745 0-46.101-14.536-55.97-35.551a61.037 61.037 0 01-5.388-18.688h-.927a61.303 61.303 0 01-5.378 18.688c-9.88 21.016-31.235 35.551-55.98 35.551a51.262 51.262 0 01-28.258-8.478 51.298 51.298 0 01-18.925-22.633 49.825 49.825 0 01-1.679-4.522 51.215 51.215 0 01-2.462-15.669c0-12.815 4.79-25.446 13.907-35.15l46.441-49.449c41.681-48.686 25.208-170.917-16.493-216.39 30.782-9.354 55.578-11.074 64.603-11.384.113 0 .216-.01.319-.01.36-.021.7-.031.999-.031h.01c.392-.01.742-.021 1.03-.021.433-.01.865-.01 1.298-.01 0 0 30.153-.381 69.867 11.95-41.372 45.853-57.567 167.384-16.04 215.895l46.441 49.449c9.12 9.704 13.91 22.334 13.91 35.15z"
        fill="#f9f6f9"
      />
      <Path
        d="M217.677 85.812c23.468 48.017 32.276 176.048 8.2 223.714l-27.403 49.449c-5.378 9.704-8.211 22.335-8.211 35.15 0 5.254.484 10.539 1.452 15.669a81.25 81.25 0 00.989 4.522c2.39 9.436 6.315 17.215 11.167 22.633 2.411 2.699 5.048 4.811 7.85 6.253a61.936 61.936 0 01-16.514 2.225 51.26 51.26 0 01-28.258-8.478 51.298 51.298 0 01-18.925-22.633 49.825 49.825 0 01-1.679-4.522 51.215 51.215 0 01-2.462-15.669c0-12.815 4.79-25.446 13.907-35.15l46.441-49.449c41.681-48.686 25.208-170.917-16.493-216.39a279.167 279.167 0 0129.939-7.324z"
        fill="#dfdde2"
      />
      <G fill="#c6c1c8">
        <Path d="M329.267 383.132c-7.065 0-10.422-9.198-4.905-13.695 5.482-4.47 13.901.502 12.477 7.483-.728 3.566-3.921 6.212-7.572 6.212zM337.734 416.108c-3.171 0-6.062-1.996-7.208-4.946-1.233-3.177-.172-6.88 2.541-8.939 5.453-4.14 13.49.712 12.272 7.484-.656 3.649-3.883 6.401-7.605 6.401zM308.282 409.33c-3.372 0-6.398-2.265-7.389-5.479-.954-3.093.222-6.606 2.853-8.493 5.707-4.095 13.768 1.235 12.033 8.129-.856 3.398-3.994 5.843-7.497 5.843z" />
        <G>
          <Path d="M181.703 383.132c-3.018 0-5.839-1.836-7.054-4.597-1.379-3.134-.51-6.925 2.156-9.098 5.479-4.466 13.798.479 12.488 7.402-.683 3.603-3.918 6.293-7.59 6.293zM173.245 416.108c-3.236 0-6.183-2.085-7.276-5.123-1.108-3.077-.091-6.651 2.488-8.668 5.518-4.314 13.731.657 12.359 7.579-.705 3.561-3.948 6.212-7.571 6.212zM202.688 409.33c-7.025 0-10.379-9.077-4.998-13.621 2.495-2.107 6.137-2.408 8.943-.74 2.759 1.639 4.239 4.917 3.643 8.069-.68 3.6-3.919 6.292-7.588 6.292z" />
        </G>
      </G>
      <Path
        d="M309.817 340.512c0-19.458-23.635-35.231-52.79-35.231s-52.79 15.773-52.79 35.231 23.635 50.684 52.79 50.684 52.79-31.227 52.79-50.684z"
        fill="#454045"
      />
      <G fill="#282528">
        <Path d="M155.409 237.137c-12.908 0-23.409 15.694-23.409 34.985s10.501 34.985 23.409 34.985c12.907 0 23.408-15.694 23.408-34.985s-10.501-34.985-23.408-34.985zM356.592 237.137c-12.908 0-23.409 15.694-23.409 34.985s10.501 34.985 23.409 34.985c12.907 0 23.408-15.694 23.408-34.985s-10.501-34.985-23.408-34.985z" />
      </G>
    </Svg>
  )
}

export default FoxTwo
