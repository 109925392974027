import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import LandingPage from '../pages/LandingPage/LandingPage';
import Login from '../pages/Login/Login';
import SignUp from '../pages/SignUp/SignUp';
import Welcome from '../pages/Welcome/Welcome';
import Legal from '../pages/Legal/Legal';
import Forget from '../pages/Forget/Forget';
import ChangePassword from '../pages/ChangePassword/ChangePassword';

export const NotLoggedNavigator = () => {
    
    const Stack = createStackNavigator()

    return (
        <Stack.Navigator
            initialRouteName='LandingPage'
            screenOptions={{
                header: ()=>null // retire le nom de la page en haut
            }} 
        >
            <Stack.Screen options={{title: "Bienvenue sur KidyWoo"}} name='LandingPage' component={LandingPage} />
            <Stack.Screen options={{title: "KidyWoo | Connexion"}} name='Login' component={Login} />
            <Stack.Screen options={{title: "KidyWoo | Inscription"}} name='SignUp' component={SignUp} />
            <Stack.Screen options={{title: "KidyWoo | Mot de passe oublié"}} name='Forget' component={Forget} />
            <Stack.Screen options={{title: "KidyWoo | Modification du mot passe"}} name='ChangePassword' component={ChangePassword} />
            <Stack.Screen options={{title: "KidyWoo | Bienvenue"}} name='Welcome' component={Welcome} />
            <Stack.Screen options={{title: "KidyWoo | Mentions légales"}} name='Legal' component={Legal} />
            
        </Stack.Navigator>
    );
};

