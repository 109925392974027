import { StyleSheet } from "react-native";  

export default StyleSheet.create({
    container:{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    kidList: {
        width: '90%',
        maxWidth: 768,
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 15,
        backgroundColor: "#FCF1DF",
    },
    kidLine: {
        width: '70%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#fff"
    },
    kidCard:{
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    basicLink:{
        color: "#e1a0a2",
    },
    part: {
        width: '90%',
        maxWidth: 768,
        margin: 10,
        justifyContent: 'center',
        backgroundColor: "#FCF1DF",
        borderRadius: 15,
        padding: 10
    },
    text: {
        color: "#57B5B5",
        textAlign: 'center',
        fontSize: 18
    },
    btnContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
})