import React, { useContext, useEffect, useState } from 'react';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import Constants from "expo-constants";
import { AuthContext } from './AuthProvider';
import { DynamicAvatarComponentMenu } from './DynamicAvatarComponent';
import ModalNewContent from './ModalNewContent';
import { getLocalStorage } from '../../tools/tools';

import { View, Text, Platform, Dimensions, StyleSheet, AppState, Image, Linking, TouchableOpacity } from 'react-native';

import Btn from './Btn';
import LogoLong from '../../../../assets/img/LogoLong';

import Home from '../pages/Home/Home';
import Kid from '../pages/Kid/Kid';
import AddKid from '../pages/AddKid/AddKid';
import Account from '../pages/Account/Account';
import Legal from '../pages/Legal/Legal';


// NAVIGATOR
export const LoggedNavigator = () => {

    const Drawer = createDrawerNavigator()

    return (
        <Drawer.Navigator
            initialRouteName='Home'
            drawerContent={props => <DrawerCustom {...props} />}
            drawerPosition={Platform.OS ==='web' ? "left" : 'right'}
            drawerType={(Platform.OS === 'web' && Dimensions.get('window').width > 700) ? 'permanent' : 'front'}
            backBehavior='order'
            drawerStyle={styles.drawer}
            screenOptions={{
                header: ()=>null // retire le nom de la page en haut
            }}
        >
            <Drawer.Screen options={{title: "KidyWoo | Home"}} name='Home' component={Home} />
            <Drawer.Screen options={{title: "KidyWoo | Ajouter un enfant"}} name='AddKid' component={AddKid} />
            <Drawer.Screen options={{title: "KidyWoo | Mon Compte"}} name='Account' component={Account} />
            <Drawer.Screen options={{title: "KidyWoo | Mentions légales"}} name='Legal' component={Legal} />
            <Drawer.Screen name='Kid' component={Kid} />
        </Drawer.Navigator>
    );
};


// DESIGN MENU
export const DrawerCustom = (props) => {

    const {user, logout, updateUser} = useContext(AuthContext)
    const [modalNewContent, setModalNewContent] = useState(false)

    // refresh les données à la réouverture de l'app
    useEffect(() => {
        if (user) {
            AppState.addEventListener('change', () => {
                if (AppState.currentState === 'active') {
                    getLocalStorage()
                        .then(localUser => {
                            refreshData(JSON.parse(localUser));
                        })
                }
            });
        }
    }, []);

    const refreshData = async (localUser) => {
        // on retire la propriété kids car pas besoin et va être remplacé (ça générerait des problèmes quand on delete)
        const dataToSend = {...localUser}
        delete dataToSend['kids']

        const response = await fetch('https://api.kidywoo.com/POST_request/refreshData.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-Width': 'xmlhttprequest'
            },
            body: JSON.stringify(dataToSend)
        })
        if (response.ok) {            
            response.json()
            .then(response => {
                if (response.success) {
                    // s'il y a une différence entre Local et BDD
                    if (JSON.stringify(response.user_param) !== JSON.stringify(localUser)) {
                        updateUser(response.user_param)
                        setModalNewContent(true)
                    }
                } else {
                    console.error(response.message);
                }
            })
            .catch(error => console.error(error))
        }
    }

    const getTotalPepites = () => {
        let tx = 0
        user.kids.forEach(kid => {
            tx += kid.data.length
        });
        return tx
    }

    const handleModalNewContent = () => {
        setModalNewContent(false)
        props.navigation.navigate('Home')
    }

    return(
        <View style={{flex:1}}>

            {modalNewContent && <ModalNewContent action={()=>{handleModalNewContent()}} />}
            
            {/* tricks pour changer couleur statusbar sur ios; a changer */}
            {Platform.OS === "ios" && <View style={{paddingTop: Constants.statusBarHeight,backgroundColor: "#2b5a5a"}}></View>}
            
            <View style={styles.drawerTop}>
                <LogoLong size='small' />
                <Text style={styles.userName}>{user.login}</Text>
                
                {user.kids &&
                    <><View style={styles.userInfo}>
                        <View style={styles.userInfo}>
                            <Text style={{fontWeight: 'bold',color:'#474747'}}>{user.kids.length} </Text><Text style={{color:'#606060'}}>{user.kids.length > 1 ? 'enfants':'enfant'}  - </Text>
                        </View>
                        <View style={styles.userInfo}>
                            <Text style={{fontWeight: 'bold',color:'#474747'}}>{getTotalPepites()} </Text><Text style={{color:'#606060'}}>{getTotalPepites() > 1 ? 'pépites' : 'pépite'}</Text>
                        </View>
                    </View>
                    <View style={styles.avatarList}>
                        {user.kids.map((kid, i) =>
                            <DrawerItem
                                activeBackgroundColor='transparent'
                                labelStyle={styles.avatarDrawer}
                                style={styles.avatarDrawerLabel}
                                key={i}
                                icon={()=><View style={styles.avatar}><DynamicAvatarComponentMenu size={{width: 20, height: 20}} tag={kid.avatar} /></View>} 
                                label=''
                                onPress={()=>{props.navigation.navigate('Kid', {name: kid.name})}}
                            />)}
                    </View></>
                }
            </View>
            
            <DrawerContentScrollView contentContainerStyle={{alignItems: 'center'}} {...props}>
                <Btn text='ACCUEIL' color='green' action={()=>{props.navigation.navigate('Home')}} />
                <Btn text='AJOUTER UN ENFANT' color='green' action={()=>{props.navigation.navigate('AddKid')}} />
                <Btn text='MON COMPTE' color='green' action={()=>{props.navigation.navigate('Account')}} />
            </DrawerContentScrollView>

            <View style={styles.drawerBottom}>
                {Platform.OS === "web" && (
                <View style={styles.storeContainer}>
                    <TouchableOpacity onPress={()=>{Linking.openURL("https://apps.apple.com/app/id1542104753")}} style={{marginRight: 10}}>
                        <Image style={styles.storeLogo} source={require('../../../../assets/img/landingPage/appstore.png')} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>{Linking.openURL("https://play.google.com/store/apps/details?id=com.kidywoo.app")}}>
                        <Image style={styles.storeLogo} source={require('../../../../assets/img/landingPage/googleplay.png')} />
                    </TouchableOpacity>
                </View>
                )}
                <Btn text='DÉCONNEXION' color='red' size='small' action={()=>{logout()}} />
            </View>


        </View>
    )
}

const styles = StyleSheet.create({
    drawer: {
        backgroundColor: '#fae8c9',
        width: "80%",
        maxWidth: 300
    },
    drawerTop:{
        alignItems: 'center',
        borderBottomColor: "#fff",
        borderBottomWidth: 1,
    },
    userInfo: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 2
    },
    userName:{
        textAlign: 'center',
        fontFamily: 'RobotoBold',
        color: '#57B5B5',
        fontSize: 20
    },
    avatarList: {
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 5,
        flexWrap: 'wrap'
    },
    avatarDrawer:{
        padding: 0,
        margin: 0
    },
    avatarDrawerLabel:{
        width: 40,
        height: 40,
        borderRadius: 60,
        padding: 0,
        backgroundColor: '#57B5B5'
    },
    avatar: {
        position: 'absolute',
        left: 10,
        top: 10,
        width: 40,
        height: 40,
    },
    drawerBottom:{
        alignItems: 'center',
        borderTopColor: "#fff",
        borderTopWidth: 1,
    },
    burger:{
        zIndex: 1000,
        position: 'absolute',
        top: 10,
        right: 10, 
        borderRadius: 50
    },
    storeContainer:{
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    storeLogo:{
        height: 35,
        width: 118,
    }
});
