import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthProvider';

import { NavigationContainer } from "@react-navigation/native";
import { LoggedNavigator } from './LoggedNavigator';
import { NotLoggedNavigator } from './NotLoggedNavigator';
import { useFonts } from 'expo-font';
import { getLocalStorage } from '../../tools/tools';

import BaseStyle from './BaseStyle';
import { ActivityIndicator } from 'react-native';
import LogoLong from '../../../../assets/img/LogoLong';

export const Routes = () => {
    const {user, login } = useContext(AuthContext)

    const [loading, setLoading] = useState(true) 

    useEffect(()=>{
        // check si l'user est log ou pas
        (async () => {
            getLocalStorage()
                .then(user_param => {
                    if(user_param) {
                        login()
                    }
                })
        })
        setLoading(false)
    }, [])

    const linking = {
      prefixes: ['https://kidywoo.com', 'kidywoo://'],
      config: {
        screens: {
            LandingPage: '',
            Login: 'Connexion',
            SignUp: 'Inscription',
            Welcome: 'Welcome',
            Home: 'Home',
            AddKid: 'AddKid',
            Account: 'Account',
            Legal: 'Legal',
            Forget: 'Forget',
            ChangePassword: 'ChangePassword',
            Kid: {
                path: 'Kid/:name',
                stringify: {
                    name: (name) => name.replace(/[\\%`^|{}<>\[\]!$&\(\)+,;=.?:#' ]/, '').replace(/@/, 'a'),
                },
            },
        }
      }
    };

    const [fontsLoaded] = useFonts ({
        LittleDays: require('../../../../assets/fonts/Little-Days.ttf'),
        ParisienNight: require('../../../../assets/fonts/Parisien_Night.otf'),
        Roboto: require('../../../../assets/fonts/Roboto-Regular.ttf'),
        RobotoBold: require('../../../../assets/fonts/Roboto-Bold.ttf')
    });


    if (!fontsLoaded || loading) {
        return (
            <BaseStyle>
                <LogoLong />
                <ActivityIndicator size="large" color="#57B5B5"/>        
            </BaseStyle>
        )
    }

    return (
        <NavigationContainer linking={linking}>
            {user ? <LoggedNavigator /> :  <NotLoggedNavigator />}
        </NavigationContainer>
    )
};